<template>
  <v-dialog
    v-model="popup"
    :max-width="$vuetify.breakpoint.mdAndDown ? 332 : 720"
    :max-height="$vuetify.breakpoint.mdAndDown ? 482 : 510"
    color="transparent"
    content-class="flat"
    disabled
    lazy
  >
    <v-card
      color="transparent"
      dark
    >
      <v-img
        :src="require(`@/assets/images/popup/bg-pop-up-clarotvpre-${$vuetify.breakpoint.name}.png?lazy`)"
        contain
      >
        <v-toolbar
          color="transparent"
          flat
          absolute
        >
          <v-spacer />

          <i
            v-cursor.pointer
            v-position.relative
            v-top="8"
            v-right="8"
            class="mdn-Icon-fechar mdn-Icon--sm right white--text pa-2"
            @click="popup = false"
          />
        </v-toolbar>

        <v-layout
          align-end
          fill-height
        >
          <v-flex xs12>
            <v-layout
              wrap
              justify-center
              fill-height
              py-4
            >
              <v-flex
                xs11
                text-xs-center
              >
                <strong
                  v-font-size="$vuetify.breakpoint.mdAndDown ? 20 : 24"
                  class="text-uppercase"
                  style="color: #FBC517"
                  v-text="'Que bom ter você de volta!'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs11
                md8
                lg6
                text-xs-center
              >
                <span
                  v-font-size="18"
                  v-text="'Aproveite nossa super oferta CLARO TV PRÉ! A TV sem fidelidade que cabe no seu bolso e você recarrega do seu jeito!'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                shrink
              >
                <v-btn
                  color="#FBC517"
                  round
                  block
                  large
                  depressed
                  light
                  class="font-weight-bold px-5"
                  @click="goToAnchorDelay('.gotoCard', 160)"
                  v-text="'Saiba mais'"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: function () {
      return {
        popup: false
      }
    },

    beforeMount () {
      try {
        const url = require('@/data/utm')
        if (/popup-welcome/.test(url.find(o => o.origem === this.$route.meta.utm_source).function)) this.popup = 'popup-welcome'
      } catch { /* no-empty-catch */ }
    },

    methods: {
      goToAnchorDelay (anchor, time) {
        setTimeout(() => {
          this.$vuetify.goTo(anchor)
          this.popup = false
        }, time)
      }
    }
  }
</script>
