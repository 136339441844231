<template>
  <v-dialog
    v-model="dialogs.form"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="white"
    persistent
  >
    <v-toolbar
      color="red"
      fixed
      flat
    >
      <v-toolbar-title>
        <v-img
          :src="require(`@/assets/images/logos/logo-claro-branco.png`)"
          :width="80"
          :hidden="30"
          contain
        />
      </v-toolbar-title>

      <v-spacer />

      <v-toolbar-items>
        <v-btn
          dark
          large
          flat
          icon
          @click="CloseOferta()"
        >
          <i
            :class="`mdn-Icon-fechar mdn-Icon--md`"
          />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-spacer
      :class="`height-${$vuetify.breakpoint.mdAndDown ? 72 : 64}`"
    />

    <form-card
      v-if="dialogs.form"
    />
  </v-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import FormCard from '@/components/forms/FormCard'

  export default {
    components: {
      FormCard
    },

    computed: {
      ...mapState('Modules', [ 'dialogs' ])
    },

    methods: {
      ...mapActions('Modules', [ 'CloseOferta' ])
    }
  }
</script>
