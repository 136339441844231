<template>
  <v-layout
    wrap
    justify-center
  >
    <v-flex
      xs11
      md8
      lg6
    >
      <v-card
        :class="`${$vuetify.breakpoint.mdAndDown && 'pb-5 mb-5'} px-3 mx-auto`"
        flat
      >
        <v-spacer
          :class="`height-32`"
        />

        <v-card-title
          class="pa-0"
          primary-title
        >
          <v-layout
            class="align-self-center align-center text-xs-center mx-auto"
            :column="$vuetify.breakpoint.mdAndDown"
            :pb-4="$vuetify.breakpoint.mdAndDown"
          >
            <v-flex
              :class="`align-self-${$vuetify.breakpoint.mdAndDown ? 'center' : 'start'}`"
              xs2
            >
              <v-icon
                v-if="/aprovado/.test(payload.pedido.pagamento.status)"
                class="font-size-80"
                color="success"
              >
                check_circle_outline
              </v-icon>

              <v-icon
                v-else-if="/pendente|negado/.test(payload.pedido.pagamento.status)"
                class="font-size-80"
                color="grey lighten-4"
              >
                info_outline
              </v-icon>

              <v-icon
                v-else
                class="font-size-80"
                color="error lighten-4"
              >
                error_outline
              </v-icon>
            </v-flex>

            <v-flex
              xs10
            >
              <v-flex
                v-if="/aprovado/.test(payload.pedido.pagamento.status)"
                class="text-xs-center"
                xs10
                :mx-auto="$vuetify.breakpoint.mdAndDown"
                data-test-id="txtResumoPagamento"
              >
                <h3
                  class="font-weight-bold text-uppercase success--text"
                >
                  Pagamento realizado com sucesso
                </h3>
              </v-flex>

              <v-flex
                v-else-if="/pendente|negado/.test(payload.pedido.pagamento.status)"
                class="text-xs-center"
                xs10
                :mx-auto="$vuetify.breakpoint.mdAndDown"
              >
                <div
                  class="font-weight-bold text-uppercase warning--text"
                >
                  <h3
                    v-html="message.resume.message"
                  />
                </div>
              </v-flex>

              <v-flex
                v-else
                class="text-xs-center"
                xs10
                :mx-auto="$vuetify.breakpoint.mdAndDown"
              >
                <div
                  class="font-weight-bold text-uppercase error--text"
                >
                  <h3
                    v-text="'Pagamento não recebido, por favor, aguarde por mais informações'"
                  />
                </div>
              </v-flex>

              <v-layout
                v-if="payload.oportunidade"
              >
                <v-flex
                  xs10
                  :xs12="$vuetify.breakpoint.mdAndDown"
                  :mx-auto="$vuetify.breakpoint.mdAndDown"
                >
                  <v-btn
                    v-if="payload.oportunidade.id !== null"
                    light
                    depressed
                    large
                    disabled
                    block
                    outline
                    class="border-radius-4"
                    color="grey lighten-4"
                    tabindex="-9"
                  >
                    <h3
                      class="text-uppercase grey-regular--text"
                    >
                      Número do pedido <span
                        class="grey-dark--text"
                        v-html="payload.oportunidade.id"
                      />
                    </h3>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-title>

        <div
          :class="`${$vuetify.breakpoint.lgAndUp && 'max-width-640 min-height-358'} pb-${$vuetify.breakpoint.mdAndDown ? 3 : 0}`"
        >
          <v-layout
            :column="$vuetify.breakpoint.mdAndDown"
            :wrap="$vuetify.breakpoint.lgAndUp"
            py-4
          >
            <v-flex
              v-if="/pendente|negado/.test(payload.pedido.pagamento.status)"
              class="text-xs-center mx-auto"
              pt-2
            >
              <h4
                class="font-weight-regular"
              >
                <strong>Fique ligado!</strong> As informações sobre o processamento do pagamento e seu pedido serão enviados por <strong>SMS</strong> para <strong
                  v-html="MaskTelefone(payload.consumidor.telefone.contato)"
                /> e <strong>E-mail</strong> para <strong
                  v-html="payload.consumidor.email"
                />
              </h4>
            </v-flex>

            <v-flex
              v-else
              class="text-xs-center mx-auto"
              pt-2
              xs10
            >
              <h4
                class="font-weight-regular"
              >
                <strong>Fique ligado!</strong> As informações sobre seu pedido serão enviados por <strong>SMS</strong> para <strong
                  v-html="MaskTelefone(payload.consumidor.telefone.contato)"
                /> e <strong>E-mail</strong> para <strong
                  v-html="payload.consumidor.email"
                />
              </h4>
            </v-flex>
          </v-layout>

          <v-layout
            :column="$vuetify.breakpoint.mdAndDown"
            :wrap="$vuetify.breakpoint.lgAndUp"
            pt-4
          >
            <v-flex
              xs6
            >
              <v-text-field
                :value="String(payload.consumidor.nome).replace(/(^|\s)\S/g, function (v) { return v.toUpperCase() })"
                label="Nome"
                readonly
                color="grey lighten-4"
                :class="`${$vuetify.breakpoint.mdAndUp && 'pr-2'} height-68`"
                data-test-id="txtNomeResumo"
              />
            </v-flex>

            <v-flex
              xs6
            >
              <v-text-field
                :value="payload.consumidor.cpf"
                :mask="validateFormData.mask.cpf"
                label="CPF"
                readonly
                color="grey lighten-4"
                :class="`${$vuetify.breakpoint.mdAndUp && 'pl-2'} height-68`"
                data-test-id="txtCpfResumo"
              />
            </v-flex>

            <v-flex
              xs6
            >
              <v-text-field
                :value="String(payload.pedido.produto[0].descricao).toUpperCase()"
                label="Pedido"
                readonly
                color="grey lighten-4"
                :class="`${$vuetify.breakpoint.mdAndUp && 'pr-2'} height-68`"
                data-test-id="txtPedidoResumo"
              />
            </v-flex>

            <v-flex
              v-if="/cartaodecredito/.test(payload.pedido.pagamento.formaPagamento)"
              xs6
            >
              <v-text-field
                :value="`${currency({ prefix: 'BRL', valor: payload.pedido.valorTotal })} no cartão de crédito`"
                label="Pagamento"
                readonly
                color="grey lighten-4"
                :class="`${$vuetify.breakpoint.mdAndUp && 'pl-2'} height-68`"
              />
            </v-flex>

            <v-flex
              v-else
              xs6
            >
              <v-text-field
                :value="currency({ prefix: 'BRL', valor: payload.pedido.valorTotal })"
                label="Valor total"
                readonly
                color="grey lighten-4"
                :class="`${$vuetify.breakpoint.mdAndUp && 'pl-2'} height-68`"
              />
            </v-flex>

            <v-flex
              v-if="/boleto/.test(payload.pedido.pagamento.formaPagamento)"
              xs12
            >
              <v-text-field
                id="paymentBoletoBancarioLinhaDigitavel"
                v-height="68"
                :value="payload.pedido.pagamento.boletoBancario.linhaDigitavel"
                label="Código de barras do boleto"
                readonly
                color="grey lighten-4"
                append-icon="mdn-Icon-link-externo mdn-Icon--md"
                @click:append="abrirBoletoPagamento()"
                @click="copy('paymentBoletoBancarioLinhaDigitavel')"
              />
            </v-flex>
          </v-layout>
        </div>
      </v-card>
    </v-flex>

    <v-flex
      xs12
      my-4
    />

    <v-flex
      xs12
    >
      <campanha-cross-site />
    </v-flex>

    <v-flex
      xs12
      my-5
    />

    <v-flex
      xs6
      md4
      pb-5
    >
      <v-btn
        class="flat gtm_button font-size-20 font-weight-bold"
        :class="$vuetify.breakpoint.mdAndDown && 'width-200 mx-auto'"
        color="brand-principal"
        dark
        block
        large
        round
        outline
        @click="dataLayer('finalizar')"
      >
        Finalizar
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import CampanhaCrossSite from '@/views/tvpre/faq/CampanhaCrossSite.vue'
  /** @Mixins */
  import mask from '@/plugins/mixins/mask'
  import mixinYapayApiSupport from '@/plugins/mixins/api/support/yapay'
  /** @Mixins */

  export default {
    components: {
      CampanhaCrossSite
    },
    mixins: [
      mask,
      mixinYapayApiSupport
    ],

    props: {
      message: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'vip'),
        validateFormData: {
          mask: {
            cpf: '###.###.###-##'
          }
        },

        voucher: ''
      }
    },

    computed: {
      ...mapState('Modules', [ 'formData', 'cache' ]),
      ...mapState('Payload', [ 'payload' ]),
      ...mapGetters('Modules', [ 'OpenToday', 'Home' ])
    },

    mounted () {
      this.CACHE(
        {
          key: 'disablepayload',
          value: true
        }
      )
    },

    created () {
      this.dataLayer()
      this.sendParentMessage()
      // this.getResumeMessage()
    },

    methods: {
      ...mapMutations('Modules', [ 'CACHE' ]),
      ...mapActions('Modules', [ 'loading' ]),

      getResumeMessage () {
        let propMessage = this.message.resume.message
        if (propMessage) return
        this.GetReturnTransacaoMessage(this.cache.pedido.retorno[this.cache.pedido.retorno.length - 1])
          .then((result) => {
            if (!result) return
            this.message.resume.message = result.message
          }).catch(() => { return false })
      },

      verifyRouterHome () {
        if ((/pagamento/).test(this.$route.path)) {
          location.assign(location.origin)
          return
        }

        this.Home()
      },

      sendParentMessage () {
        this.LOG('irei emitir um alert para o iframe', this.payload.pedido.pagamento.status)
        window.parent.postMessage(this.payload.pedido.pagamento.status, '*')
      },

      dataLayer (param) {
        if ((/finalizar/).test(param)) {
          this.loading({ value: true, time: 1 })
          setTimeout(() => (this.verifyRouterHome()), 960)
        }

        window.dataLayer.push({
          'event': 'purchase',
          'eventCategory': 'claro:tv-pre',
          'eventAction': 'enhanced-ecommerce',
          'eventLabel': 'sucesso-compra',
          'ecommerce': {
            'nonInteraction': '1',
            'ec': 'setAction',
            'purchase': {
              'actionField': {
                'id': String(this.payload.oportunidade ? this.payload.oportunidade.id : '').toLowerCase(),
                'affiliation': 'meu-plano-claro',
                'revenue': String(this.payload.pedido.produto[0].preco).replace(/(,|\.)/, '.')
              },
              'products': [{
                'id': String(this.payload.pedido.produto[0].id).toLowerCase(),
                'name': String(this.payload.pedido.produto[0].descricao).replace(/ /g, '-').toLowerCase(),
                'price': String(this.payload.pedido.produto[0].preco).replace(/(,|\.)/, '.'),
                'brand': 'meu-plano-claro',
                'category': 'tvpre',
                'quantity': '1'
              }]
            }
          }
        })
      },

      abrirBoletoPagamento () {
        window.open(this.payload.pedido.pagamento.boletoBancario.url, '_blank')
      },

      copy (id) {
        document.getElementById(id).select()
        document.execCommand('copy')
      }
    }
  }
</script>
