/** @Database */
import {
  bancos
} from '@/database/pagamento/index'
/** @Database */

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Modules', [ 'payload' ])
  },

  methods: {
    InputRules (rule, text, value) {
      let retorno

      function cpf (value, text) {
        if (/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/i.test(value)) {
          let digitosIguais = 1

          for (let i = 0; i < value.length - 1; i++) {
            if (value.charAt(i) !== value.charAt(i + 1)) {
              digitosIguais = 0
              break
            }
          }

          if (!digitosIguais) {
            let Breack, Soma, Resto

            Soma = 0
            for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i)

            Resto = (Soma * 10) % 11
            if ((Resto === 10) || (Resto === 11)) Resto = 0
            if (Resto !== parseInt(value.substring(9, 10))) Breack = true

            Soma = 0
            for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i)

            Resto = (Soma * 10) % 11
            if ((Resto === 10) || (Resto === 11)) Resto = 0
            if (Resto !== parseInt(value.substring(10, 11))) Breack = true

            return !Breack || (text || 'CPF inválido')
          } else {
            return text || 'CPF inválido'
          }
        } else {
          return text || 'CPF inválido'
        }
      }

      function nascimento (value, text) {
        if (/^(?:(?:31([-/.]?)(?:0[13578]|1[02]))\1|(?:(?:29|30)([-/.]?)(?:0[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29([-/.]?)02\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26]))))$|^(?:0[1-9]|1\d|2[0-8])([-/.]?)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/i.test(value)) {
          let Breack
          let day = Number(value.substring(0, 2))
          let month = Number(value.substring(2, 4))
          let year = Number(value.substring(4, 8))
          let date = new Date()
          let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29
          if (year < date.getFullYear() - 110 || month === 0 || month > 12) Breack = text || 'Data inválida'
          if (year > (date.getFullYear() - 18)) Breack = text || 'Você deve ser maior de 18 anos'
          if (date.getFullYear() - year === 18 && date.getMonth() + 1 < month) Breack = text || 'Você deve ser maior de 18 anos'
          if (date.getFullYear() - year === 18 && date.getMonth() + 1 === month && date.getDate() < day) Breack = text || 'Você deve ser maior de 18 anos'

          return Breack || (day > 0 && day <= monthLength[month - 1]) || (text || 'Data inválida')
        } else {
          return text || 'Data inválida'
        }
      }

      function telefone (value, text) {
        if (/^(0?\(?[0-9]{2}\)?[0-9]{5}-?[0-9]{4})$/i.test(value)) {
          let Breack
          let digitosIguais = 1

          for (let i = 3; i < value.length - 1; i++) {
            if (value.charAt(i) !== value.charAt(i + 1)) {
              digitosIguais = 0
              break
            }
          }

          if (!digitosIguais) {
            if (value.charAt(2) !== '9') Breack = text || 'Digite um número de Celular'

            return !Breack || (text || 'Telefone inválido')
          } else {
            return text || 'Telefone inválido'
          }
        } else {
          return text || 'Telefone inválido'
        }
      }

      if (rule === 'required') retorno = value => !!value || (text || 'Obrigatório')
      if (rule === 'number') retorno = value => /^[0-9]{1,}$/i.test(value) || (text || 'Somente números')
      if (rule === 'telefone') retorno = value => telefone(value, text)
      if (rule === 'sms') retorno = value => /^[0-9]{4}$/i.test(value) || (text || 'Digite 4 números')
      if (rule === 'agencia') retorno = value => /^[0-9]{4}$/i.test(value) || (text || 'Verifique a agência')
      if (rule === 'digito') retorno = value => /^[0-9a-z]{1,2}$/i.test(value) || (text || 'Dígito inválido')
      if (rule === 'cvv') retorno = value => /^[0-9]{3,4}$/i.test(value) || (text || 'Código inválido')

      // if (rule === 'email') return value => /^(([a-zA-Z0-9][-_.]{0,1}){0,63})([^\W_])+@([a-zA-Z0-9]{1,63})(\.[a-zA-Z0-9]{2,63})+$/i.test(value) || (text || 'E-mail inválido')
      // if (rule === 'cpf') return value => cpf(value, text)
      // if (rule === 'nascimento') return value => nascimento(value, text)
      /** @teste para toggle */
      // if (rule === 'email') {
      //   retorno = (() => {
      //     return /^(([a-zA-Z0-9][-_.]{0,1}){0,63})([^\W_])+@([a-zA-Z0-9]{1,63})(\.[a-zA-Z0-9]{2,63})+$/i.test(value) || (text || 'Formato de email inválido')
      //   })(value, text)
      // }

      if (rule === 'email') {
        retorno = value => /^(([a-zA-Z0-9][-_.]{0,1}){0,63})([^\W_])+@([a-zA-Z0-9]{1,63})(\.[a-zA-Z0-9]{2,63})+$/i.test(value) || (text || 'Formato de email inválido')
      }

      if (rule === 'cpf') {
        retorno = (() => {
          return cpf(value, text)
        })(value, text)
      }

      if (rule === 'nascimento') {
        retorno = (() => {
          return nascimento(value, text)
        })(value, text)
      }
      /** @teste para toggle */

      if (rule === 'conta') {
        if (this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco) {
          if (this.ruleBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco)) {
            retorno = value => this.ruleBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco).test(value) || (text || 'Verifique a conta')
          }
        }
      }

      if (retorno) {
        return retorno
      } else {
        return ''
      }
    },

    ruleBancoConta (
      banco
    ) {
      banco = banco.split(' - ')[0]
      banco = bancos.map(o => o.banco.split(' - ')[0] === banco).indexOf(true)
      return bancos[banco].rule
    }
  }
}
