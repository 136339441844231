<template>
  <div>
    <v-layout
      column
      pb-4
      :align-center="$vuetify.breakpoint.lgAndUp"
    >
      <v-layout
        v-if="$vuetify.breakpoint.lgAndUp"
        text-xs-center
      >
        <v-flex
          v-for="(next, i) in tabs.item.title"
          :key="`tab-itens-head-${i}`"
        >
          <valores
            :param="[ tabs, i ]"
          />
        </v-flex>
      </v-layout>
    </v-layout>

    <valores
      v-if="$vuetify.breakpoint.mdAndDown"
      :param="[ tabs ]"
    />

    <div
      :class="`position-relative bottom-20 pl-${$vuetify.breakpoint.lgAndUp ? 5 : 2}`"
      v-html="'*Os valores das recargas podem sofrer alterações'"
    />
  </div>
</template>

<script>
  import Valores from '@/components/contents/tvpre/dialogs/faq/slots/AssistaCanais/tabs/Valores'

  export default {
    components: {
      Valores
    },

    data: function () {
      return {
        tabs: {
          head: [],
          item: {
            title: [ 'Pacote', '15 Dias', '30 Dias' ],
            inicial: [ 'Inicial', '-', 'R$ 18,00' ],
            light: [ 'Light', 'R$ 36,00', 'R$ 58,00' ],
            mix: [ 'Mix', 'R$ 55,00', 'R$ 86,00' ],
            top: [ 'Top', 'R$ 80,00', 'R$ 109,00' ]
          }
        }
      }
    }
  }
</script>
