<template>
  <v-dialog
    v-model="dialogState"
    style="z-index:9999; position:relative"
    content-class="flat px-5 mx-auto max-width-560"
  >
    <v-toolbar
      color="brand-principal"
      dense
    >
      <v-toolbar-title>
        <v-img
          :src="require(`@/assets/images/logos/logo-claro-branco.png`)"
          :width="80"
          :hidden="30"
          contain
        />
      </v-toolbar-title>
      <v-toolbar-items class="white---text">
        <v-spacer />
        <v-btn
          color="white"
          flat
        />
      </v-toolbar-items>
      <v-spacer />
      <v-btn
        color="white"
        flat
        small
        fab
        dark
        @click="close"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            pb-4
            text-xs-left
          >
            <h4>
              <!--<span class="black-dark--text body-1 font-weight-black">Para o estado de <span class="red--text">SP </span>, valor promocional de <span class="red--text">R$ 270,00</span> </span>-->
              <span
                class="black-dark--text body-1 font-weight-black"
                v-html="getProduct('vtxantenareceptorhd','promo').subheading[0]"
              />
            </h4>
            <h4 class="pt-2">
              <span
                class="mt-5 black-dark--text body-1 font-weight-black"
                v-html="getProduct('vtxantenareceptorhd','promo').subheading[1]"
              />
            </h4>
          </v-flex>
          <v-flex
            xs12
            text-xs-left
            data-test-id="ufCardCEP"
          >
            <span class="black-dark--text body-1 font-weight-black">Produto disponível para os seguintes estados: </span>
          </v-flex>
          <v-flex
            xs12
            pt-2
            text-xs-left
          >
            <span class="grey-dark--text caption font-weight-black">{{ getUFArray(getProduct('vtxantenareceptorhd','promo')) }}</span>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            flat
            color="orange"
            data-test-id="bntUFContinuar"
            @click="close"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .v-toolbar__content
    padding-left 10px !important
    padding-right 5px !important
</style>

<script>
  /** @Mixins */
  import product from '@/plugins/mixins/product'
  /** @Mixins */

  export default {
    mixins: [
      product
    ],
    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      dialogState: {
        get () {
          return this.dialog
        },
        set (val) {
          this.close()
        }
      }
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
