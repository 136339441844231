const LOG = (...v) => window.log(...v, 'formcard')

export default {
  methods: {
    async creditCard (numero) {
      if (!numero) return

      function testPathern (param) {
        switch (true) {
          case /^((((636)(368|297)|(4)(38935|51416)|(504175))\d{10})|((4)(011|576)|(5067))\d{12})$/i.test(param):
            return 'elo'
          case /^(606282\d{10}(\d{3})?)|(3841\d{15})$/i.test(param):
            return 'hipercard'
          case /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/i.test(param):
            return 'mastercard'
          case /^4[0-9]{12}(?:[0-9]{3,6})?$/i.test(param):
            return 'visa'
          default:
            return /^[0-9]{16}$/i.test(param) && 'cartao'
        }
      }

      function luhnChecksum (code) {
        var len = code.length
        var parity = len % 2
        var sum = 0
        for (var i = len - 1; i >= 0; i--) {
          var d = parseInt(code.charAt(i))
          if (i % 2 === parity) { d *= 2 }
          if (d > 9) { d -= 9 }
          sum += d
        }
        return sum % 10
      }

      const CARD_BRAND = testPathern(numero)

      return new Promise((resolve, reject) => {
        if (!CARD_BRAND) return reject(Error('Cartão inválido'))

        LOG('efetuando teste de cartão')
        resolve({
          validate: luhnChecksum(numero) === 0,
          brand: CARD_BRAND
        })
      })
    }
  }
}
