/** @Database */
import {
  bancos
} from '@/database/pagamento/index'
/** @Database */

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Modules', [ 'payload' ])
  },

  methods: {
    InputHint (hint) {
      if (hint === 'conta') {
        if (this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco) {
          if (this.hintBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco)) {
            return this.hintBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco)
          }
        }
      }
    },

    hintBancoConta (
      banco
    ) {
      banco = banco.split(' - ')[0]
      banco = bancos.map(o => o.banco.split(' - ')[0] === banco).indexOf(true)
      return bancos[banco].hint
    }
  }
}
