<template>
  <v-card
    :class="`${$vuetify.breakpoint.mdAndDown && 'pb-5 mb-5'} px-3 max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 640} mx-auto`"
    flat
  >
    <v-spacer
      :class="`height-32`"
    />

    <v-card-title
      class="pa-0"
      primary-title
    >
      <v-layout
        class="align-self-center align-center text-xs-center mx-auto"
        :column="$vuetify.breakpoint.mdAndDown"
        :pb-4="$vuetify.breakpoint.mdAndDown"
      >
        <v-flex
          :class="`align-self-${$vuetify.breakpoint.mdAndDown ? 'center' : 'start'}`"
          xs2
        >
          <v-icon
            class="font-size-80"
            color="grey lighten-4"
          >
            info_outline
          </v-icon>
        </v-flex>

        <v-flex
          xs10
        >
          <v-flex
            class="text-xs-center"
            xs10
            :mx-auto="$vuetify.breakpoint.mdAndDown"
          >
            <div
              class="font-weight-bold text-uppercase warning--text"
            >
              <h3
                v-text="`Aguardando pagamento do Boleto Bancário`"
              />
            </div>
          </v-flex>

          <v-layout
            v-if="cache.boleto.oportunidade.id"
          >
            <v-flex
              xs10
              :xs12="$vuetify.breakpoint.mdAndDown"
              :mx-auto="$vuetify.breakpoint.mdAndDown"
            >
              <v-btn
                v-if="cache.boleto.oportunidade.id !== null"
                light
                depressed
                large
                disabled
                block
                outline
                class="border-radius-4"
                color="grey lighten-4"
                tabindex="-9"
              >
                <h3
                  class="text-uppercase grey-regular--text"
                >
                  Número do pedido <span
                    class="grey-dark--text"
                    v-html="cache.boleto.oportunidade.id"
                  />
                </h3>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-layout
            v-else-if="cache.boleto.pedido.id"
          >
            <v-flex
              xs10
              :xs12="$vuetify.breakpoint.mdAndDown"
              :mx-auto="$vuetify.breakpoint.mdAndDown"
            >
              <v-btn
                v-if="cache.boleto.pedido.id !== null"
                light
                depressed
                large
                disabled
                block
                outline
                class="border-radius-4"
                color="grey lighten-4"
                tabindex="-9"
              >
                <h3
                  class="text-uppercase grey-regular--text"
                >
                  Número do pedido <span
                    class="grey-dark--text"
                    v-html="cache.boleto.pedido.id"
                  />
                </h3>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>

    <div
      :class="`${$vuetify.breakpoint.lgAndUp && 'max-width-640 min-height-358'} pb-${$vuetify.breakpoint.mdAndDown ? 3 : 0}`"
    >
      <v-layout
        :column="$vuetify.breakpoint.mdAndDown"
        :wrap="$vuetify.breakpoint.lgAndUp"
        py-4
      >
        <v-flex
          class="text-xs-center mx-auto"
          pt-2
        >
          <h4
            class="font-weight-regular"
          >
            <strong>Fique ligado!</strong> As informações sobre o processamento do pagamento e seu pedido serão enviados por <strong>SMS</strong> para <strong
              v-html="MaskTelefone(payload.consumidor.telefone.contato)"
            /> e <strong>E-mail</strong> para <strong
              v-html="payload.consumidor.email"
            />
          </h4>
        </v-flex>
      </v-layout>

      <v-layout
        :column="$vuetify.breakpoint.mdAndDown"
        :wrap="$vuetify.breakpoint.lgAndUp"
        pt-4
      >
        <v-flex
          xs6
        >
          <v-text-field
            :value="String(payload.consumidor.nome).replace(/(^|\s)\S/g, function (v) { return v.toUpperCase() })"
            label="Nome"
            readonly
            color="grey lighten-4"
            :class="`${$vuetify.breakpoint.mdAndUp && 'pr-2'} height-68`"
          />
        </v-flex>

        <v-flex
          xs6
        >
          <v-text-field
            :value="payload.consumidor.cpf"
            :mask="validateFormData.mask.cpf"
            label="CPF"
            readonly
            color="grey lighten-4"
            :class="`${$vuetify.breakpoint.mdAndUp && 'pl-2'} height-68`"
          />
        </v-flex>

        <v-flex
          xs6
        >
          <v-text-field
            :value="String(payload.pedido.produto[0].descricao).toUpperCase()"
            label="Pedido"
            readonly
            color="grey lighten-4"
            :class="`${$vuetify.breakpoint.mdAndUp && 'pr-2'} height-68`"
          />
        </v-flex>

        <v-flex
          xs6
        >
          <v-text-field
            :value="`1x de ${ currency({ prefix: 'BRL', valor: payload.pedido.valorTotal }) }`"
            label="Pagamento"
            readonly
            color="grey lighten-4"
            :class="`${$vuetify.breakpoint.mdAndUp && 'pl-2'} height-68`"
          />
        </v-flex>
      </v-layout>
    </div>

    <v-card-actions
      :class="`max-width-370 pb-4 mx-auto`"
    >
      <v-layout
        :column="$vuetify.breakpoint.mdAndDown"
      >
        <v-flex
          order-lg2
        >
          <v-btn
            class="flat gtm_button font-size-20 font-weight-bold"
            :class="$vuetify.breakpoint.mdAndDown && 'width-200 mx-auto'"
            color="brand-principal"
            dark
            block
            large
            round
            outline
            @click="dataLayer('finalizar')"
          >
            Finalizar
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  /** @Mixins */
  import mask from '@/plugins/mixins/mask'
  /** @Mixins */

  export default {
    mixins: [
      mask
    ],

    data: function () {
      return {
        validateFormData: {
          mask: {
            cpf: '###.###.###-##'
          }
        },

        voucher: ''
      }
    },

    computed: {
      ...mapState('Modules', [ 'formData', 'cache' ]),
      ...mapState('Payload', [ 'payload' ]),
      ...mapGetters('Modules', [ 'OpenToday', 'Home' ])
    },

    created () {
      this.dataLayer()
      this.sendParentMessage()
    },

    methods: {
      ...mapMutations('Modules', [ 'CACHE' ]),
      ...mapActions('Modules', [ 'loading' ]),

      verifyRouterHome () {
        if ((/pagamento/).test(this.$route.path)) {
          location.assign(location.origin)
          return
        }

        this.Home()
      },

      sendParentMessage () {
        this.LOG('irei emitir um alert para o iframe', this.payload.pedido.pagamento.status)
        window.parent.postMessage(this.payload.pedido.pagamento.status, '*')
      },

      dataLayer (param) {
        if ((/finalizar/).test(param)) {
          this.loading({ value: true, time: 1 })
          setTimeout(() => (this.verifyRouterHome()), 960)
        }

        window.dataLayer.push({
          'event': 'purchase',
          'eventCategory': 'claro:tv-pre',
          'eventAction': 'enhanced-ecommerce',
          'eventLabel': 'sucesso-compra',
          'ecommerce': {
            'nonInteraction': '1',
            'ec': 'setAction',
            'purchase': {
              'actionField': {
                'id': String(this.cache.boleto.oportunidade.id || this.cache.boleto.pedido.id).toLowerCase(),
                'affiliation': 'meu-plano-claro',
                'revenue': String(this.cache.boleto.pedido.produto[0].preco).replace(/(,|\.)/, '.')
              },
              'products': [{
                'id': String(this.cache.boleto.pedido.produto[0].id).toLowerCase(),
                'name': String(this.cache.boleto.pedido.produto[0].descricao).replace(/ /g, '-').toLowerCase(),
                'price': String(this.cache.boleto.pedido.produto[0].preco).replace(/(,|\.)/, '.'),
                'brand': 'meu-plano-claro',
                'category': 'tvpre',
                'quantity': '1'
              }]
            }
          }
        })
      }
    }
  }
</script>
