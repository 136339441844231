class Compare {
  value;
  constructor (value) {
    this.value = value
  }

  between (...values) {
    try {
      values = values.map(value => new Date(value).getTime())
      const MIN = Math.min(...values)
      const MAX = Math.max(...values)
      return this.value >= MIN && this.value <= MAX
    } catch (error) {
      window.log('error on Compare', error)
      return false
    }
  }

  before (...values) {
    try {
      values = values.map(value => new Date(value).getTime())
      const MAX = Math.max(...values)
      return this.value <= MAX
    } catch (error) {
      window.log('error on Compare', error)
      return false
    }
  }

  after (...values) {
    try {
      values = values.map(value => new Date(value).getTime())
      const MAX = Math.max(...values)
      return this.value >= MAX
    } catch (error) {
      window.log('error on Compare', error)
      return false
    }
  }
}

const AGORA = new Compare(new Date().getTime())

export function epochSeloPromoRa () {
  if (!window.env('production') && (location.search && (/debug/.test(location.search) && /epochSeloPromoRa/i.test(location.search)))) {
    return true
  }
  return AGORA.before(
    '2024-01-01T10:00:00.000-03:00'
  )
}
