<template>
  <div>
    <label
      class="font-weight-bold"
    >
      {{ label }}
    </label>

    <v-spacer
      :class="`height-6`"
    />

    <v-text-field
      :id="identification"
      :ref="identification"
      v-model="consumidorEmail"
      :name="identification"
      :rules="[ validateEmailComputed ]"
      :success="GetProp(identification, 'valid')"
      :color="GetProp(identification, 'valid') ? 'success' : asyncValidate.countSearch > 0 && (!asyncValidate.load && !asyncValidate.isValid) ? 'error' : 'brand-principal'"
      :append-icon="GetProp(identification, 'valid') ? 'done' : asyncValidate.countSearch > 0 && (!asyncValidate.load && !asyncValidate.isValid) ? 'close' : ''"
      :error="asyncValidate.countSearch > 0 && !GetProp(identification, 'valid')"
      :error-messages="asyncValidate.msg"
      :loading="asyncValidate.load"
      validate-on-blur
      type="email"
      placeholder="exemplo@email.com"
      required

      :class="`${contentClass}`"
      tabindex="0"
      outline

      data-gtm-event-category="claro:meu-plano-claro"
      data-gtm-event-action="preencheu"
      data-gtm-event-label="consumidor-email"

      @focus="dataLayer('consumidor-email')"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import debounce from 'lodash/debounce'

  /** @Mixins */
  import rule from '@/plugins/mixins/rule'
  import prop from '@/plugins/mixins/prop'
  /** @mixins */

  export default {
    mixins: [
      rule,
      prop
    ],

    props: {
      identification: {
        type: String,
        default: undefined
      },

      label: {
        type: String,
        default: undefined
      },

      contentClass: {
        type: String,
        default: undefined
      }
    },

    data: function () {
      return {
        input: undefined,

        asyncValidate: {
          load: false,
          isValid: false,
          countSearch: 0,
          msg: ''
        },

        consumidor: {
          email: ''
        }
      }
    },

    computed: {
      ...mapState('Payload', [ 'payload' ]),

      consumidorEmail: {
        get () {
          this.revalidateEmail(this.payload.consumidor.email)

          return this.payload.consumidor.email
        },
        set (value) {
          this.consumidor.email = value
        }
      },

      validateEmailComputed () {
        if (this.asyncValidate.msg) {
          return this.asyncValidate.msg
        }

        if (!this.consumidor.email) {
          return 'Obrigatório'
        }

        if (this.asyncValidate.load) {
          return 'Validando...'
        }

        return false
      }
    },

    watch: {
      'consumidor.email' () {
        if (this.consumidor.email) {
          this.asyncValidate.msg = ''

          if (this.InputRules('email')(this.consumidor.email.trim()) === true) {
            this.SaveInput({
              input: true,
              name: 'consumidorEmail',
              value: this.consumidor.email
            })

            this.hookValidateEmail()
          } else {
            this.asyncValidate.msg = this.InputRules('email')(this.consumidor.email.trim())
          }
        } else {
          this.asyncValidate.msg = this.validateEmailComputed
        }
      }
    },

    methods: {
      ...mapActions('Payload', [ 'SaveInput', 'TvpreActionPayloadVerificaEmail' ]),

      hookValidateEmail: debounce(
        function () {
          this.asyncValidate.load = true

          this.validateEmail()
            .then(response => {
              response = response.status
              this.asyncValidate.isValid = response
              this.asyncValidate.countSearch += 1
              this.asyncValidate.msg = response ? '' : 'E-mail inválido'
              response && this.$emit('form', 'email', this.consumidor.email)
            })
            .catch(e => {
              this.asyncValidate.load = false
            })
            .finally(f => {
              this.asyncValidate.load = false
            })
        }, 500, { trailing: true }
      ),

      async validateEmail () {
        try {
          this.TvpreActionPayloadVerificaEmail({
            enviado: true,
            confirmado: false
          })

          return { email: this.consumidor.email, status: true }
        } catch (e) {
          return false
        }
      },

      revalidateEmail () {
        this.consumidor.email = this.payload.consumidor.email
      },

      dataLayer (param) {
        if (param && this.input !== param) {
          this.input = param

          window.dataLayer.push({
            'event': 'event',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'preencheu',
            'eventLabel': param
          })
        }
      }
    }
  }
</script>
