<template>
  <div>
    <v-layout
      text-xs-center
      :class="`${$vuetify.breakpoint.mdAndDown ? 'pb-5' : 'px-5 pb-5'}`"
    >
      <v-flex
        v-for="(next, i) in item"
        :key="i"
        class="hidden-md-and-down"
      >
        <v-img
          :src="next.img"
          height="300"
          class="card-shadow mx-auto max-width-140 grey lighten-5"
        />

        <div
          class="max-width-300 mx-auto pt-5"
          v-html="next.text"
        />
      </v-flex>

      <v-carousel
        hide-delimiters
        class="flat hidden-lg-and-up"
        height="100%"
        :cycle="false"
        prev-icon="fas fa-caret-left red--text px-3"
        next-icon="fas fa-caret-right red--text px-3"
        light
      >
        <v-carousel-item
          v-for="(next, i) in item"
          :key="i"
        >
          <v-img
            :src="next.img"
            height="400"
            class="mx-auto max-width-180 card-shadow"
          />

          <div
            class="max-width-300 mx-auto pt-5"
            v-html="next.text"
          />
        </v-carousel-item>
      </v-carousel>
    </v-layout>

    <div
      :class="`position-relative bottom-20 mr-1 pl-${$vuetify.breakpoint.lgAndUp ? 5 : 2}`"
      v-html="'*O valor da instalação (técnico + antena) deve ser negociado com o Técnico Instalador, podendo variar conforme região, distância e dificuldade da instalação.'"
    />
  </div>
</template>

<script>
  export default {
    props: {
      param: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        item: [
          {
            img: require('@/assets/images/faq/tecnicovirtual/tecnico-virtual-passo-um.jpg'),
            text: `
            Acesse o Técnico Virtual no 
              <a
                href="https://play.google.com/store/apps/details?id=br.com.visiontec.appclarotvprepago"
                target="_blank"
              >
                Android
              </a>
              ou pelo 
               <a
                href="https://appclarotvprepago.visiontec.com.br/Account/Index?ReturnUrl=%2F"
                target="_blank"
              >
                Site
              </a>
              e entre com seu CPF e sua senha, caso não tenha, clique em "Novo por aqui? Cadastre-se!"
            `
          },
          {
            img: require('@/assets/images/faq/tecnicovirtual/tecnico-virtual-passo-dois.jpg'),
            text: `Na página inicial escolha a opção "Encontre um Técnico"`
          },
          {
            img: require('@/assets/images/faq/tecnicovirtual/tecnico-virtual-passo-tres.jpg'),
            text: `Digite o CEP de sua residência ou do local onde será instalada sua Claro tv pré-pago`
          },
          {
            img: require('@/assets/images/faq/tecnicovirtual/tecnico-virtual-passo-quatro.jpg'),
            text: `Aparecerá uma lista de técnicos instaladores da região`
          },
          {
            img: require('@/assets/images/faq/tecnicovirtual/tecnico-virtual-passo-cinco.jpg'),
            text: `
              Clique sobre uma das opções e veja os dados de contato<br/>
              Escolha o técnico instalador, ligue e agende a instalação.
            `
          }
        ]
      }
    }
  }
</script>
