<template>
  <div>
    <v-card
      flat
    >
      <v-flex
        :class="`${$vuetify.breakpoint.lgAndUp && 'grey lighten-4'}`"
        hidden-md-and-down
      >
        <v-card-title
          :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 1024} mx-auto px-0`"
          primary-title
        >
          <div>
            <h2
              v-if="$vuetify.breakpoint.lgAndUp"
              :class="`font-size-24 font-weight-bold text-none grey-dark--text ${$vuetify.breakpoint.lgAndUp && 'text-uppercase'}`"
            >
              {{ formulario.active }}/{{ formulario.steps.length }} {{ formulario.steps.filter(o => o.id === formulario.active)[0].nome }}
            </h2>
          </div>
        </v-card-title>
      </v-flex>

      <v-spacer
        class="height-16"
      />

      <v-layout
        :class="`max-width-${$vuetify.breakpoint.mdAndDown ? '320 column' : '1024'} mx-auto`"
        wrap
      >
        <v-flex
          order-xs2
          order-md1
          xs12
          md6
          :style="`${$vuetify.breakpoint.lgAndUp && 'overflow-y: auto; height: calc(100vh - 160px)'}`"
        >
          <v-layout wrap>
            <v-flex xs12>
              <v-stepper
                v-model="formulario.active"
                :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 375 : 1024} mx-auto fix-stepper flat`"
                vertical
                non-linear
              >
                <v-stepper-step
                  editable
                  step="1"
                  class="py-2 px-3 hidden"
                >
                  <h3
                    class="font-weight-bold text-uppercase"
                  >
                    Dados Pessoais
                  </h3>
                </v-stepper-step>

                <v-stepper-content
                  step="1"
                  class="pa-0 ma-0"
                >
                  <v-spacer
                    class="height-22"
                  />

                  <v-card
                    flat
                    tile
                  >
                    <v-layout
                      column
                      mx-3
                    >
                      <v-form
                        ref="formulario_dados_pessoais"
                        v-model="formulario.validate.step.dadosPessoais"
                      >
                        <v-flex>
                          <label
                            class="font-weight-bold"
                          >
                            Nome completo
                          </label>

                          <v-spacer
                            class="height-6"
                          />

                          <v-text-field
                            id="ipt_gtm_modal_event_consumidor_nome"
                            ref="ipt_gtm_modal_event_consumidor_nome"
                            v-model="consumidor.nome"
                            data-test-id="nomeCliente"
                            name="ipt_gtm_modal_event_consumidor_nome"
                            :rules="[ InputRules('required'), formulario.validate.consumidor.nome ]"
                            :color="GetProp('ipt_gtm_modal_event_consumidor_nome', 'valid') ? 'success' : 'brand-principal'"
                            :success="GetProp('ipt_gtm_modal_event_consumidor_nome', 'valid')"
                            :append-icon="GetProp('ipt_gtm_modal_event_consumidor_nome', 'valid') ? 'done' : undefined"
                            placeholder="Digite seu nome"
                            required

                            class="fix-form-input gtm-element-event height-90"
                            tabindex="0"
                            outline

                            data-gtm-event-category="claro:meu-plano-claro"
                            data-gtm-event-action="preencheu"
                            data-gtm-event-label="consumidor-nome"

                            @focus="dataLayer('consumidor-nome')"
                          />
                        </v-flex>

                        <v-flex>
                          <v-dialog
                            ref="consumidornascimento"
                            v-model="consumidor.nascimentoPicker"
                            :return-value.sync="consumidor.nascimento"
                            width="290"
                            full-width
                            persistent
                            lazy
                          >
                            <template
                              v-slot:activator="{ on }"
                            >
                              <label
                                class="font-weight-bold"
                              >
                                Data de nascimento
                              </label>

                              <v-spacer
                                class="height-6"
                              />

                              <v-text-field
                                id="ipt_gtm_modal_event_consumidor_nascimento"
                                ref="ipt_gtm_modal_event_consumidor_nascimento"
                                v-model="consumidor.nascimento"
                                data-test-id="dataDeNascimento"
                                name="ipt_gtm_modal_event_consumidor_nascimento"
                                :rules="[ InputRules('required'), formulario.validate.consumidor.nascimento ]"
                                :color="GetProp('ipt_gtm_modal_event_consumidor_nascimento', 'valid') ? 'success' : 'brand-principal'"
                                :success="GetProp('ipt_gtm_modal_event_consumidor_nascimento', 'valid')"
                                append-icon="event"
                                :mask="formulario.validate.mask.data"
                                type="tel"
                                placeholder="DD/MM/AAAA"
                                required

                                class="fix-form-input gtm-element-event height-90"
                                tabindex="0"
                                outline

                                data-gtm-event-category="claro:meu-plano-claro"
                                data-gtm-event-action="preencheu"
                                data-gtm-event-label="consumidor-nascimento"

                                v-on="on"

                                @click:append="consumidor.nascimentoPicker = true"
                                @click="consumidor.nascimentoPicker = true"
                                @focus="dataLayer('consumidor-nascimento')"
                              />
                            </template>

                            <v-date-picker
                              ref="consumidornascimentopicker"
                              :max="(new Date().getFullYear() - 18) + '12-31'"
                              locale="pt-br"
                              no-title
                              data-test-id="pickerDataNascimento"
                              @change="consumidornascimentosave"
                            />
                          </v-dialog>
                        </v-flex>

                        <v-flex>
                          <label
                            class="font-weight-bold"
                          >
                            CEP
                          </label>

                          <v-spacer
                            class="height-6"
                          />

                          <v-text-field
                            id="ipt_gtm_modal_event_endereco_cep"
                            ref="ipt_gtm_modal_event_endereco_cep"
                            v-model="consumidor.endereco.cep"
                            data-test-id="cepCliente"
                            name="ipt_gtm_modal_event_endereco_cep"
                            :rules="[ InputRules('required'), formulario.validate.endereco.cep ]"
                            :color="GetProp('ipt_gtm_modal_event_endereco_cep', 'valid') ? 'success' : 'brand-principal'"
                            :success="GetProp('ipt_gtm_modal_event_endereco_cep', 'valid')"
                            append-icon="search"
                            :mask="formulario.validate.mask.cep"
                            type="tel"
                            placeholder="Digite o CEP"
                            required

                            class="fix-form-input gtm-element-event height-90"
                            tabindex="0"
                            outline

                            data-gtm-event-category="claro:meu-plano-claro"
                            data-gtm-event-action="preencheu"
                            data-gtm-event-label="endereco-cep"

                            @click:append="linkCorreios"
                            @focus="dataLayer('endereco-cep')"
                          />

                          <v-dialog
                            v-model="fakeFormValorEntrega"
                            persistent
                            max-width="500"
                          >
                            <v-card>
                              <v-toolbar
                                dense
                                flat
                                dark
                                color="red"
                              >
                                <v-layout align-center>
                                  <v-flex
                                    xs4
                                    md3
                                    lg2
                                  >
                                    <v-img
                                      src="https://mondrian.claro.com.br/brands/vertical/inverse/claro-tv-pre-pago.svg"
                                      height="32"
                                      contain
                                    />
                                  </v-flex>

                                  <v-spacer />

                                  <v-btn
                                    v-if="(!errorServicosDeFrete && !loading.payload) && pedido.postagem.servicoEntrega"
                                    icon
                                    @click="fakeFormValorEntrega = !fakeFormValorEntrega"
                                  >
                                    <v-icon v-text="'close'" />
                                  </v-btn>
                                </v-layout>
                              </v-toolbar>

                              <v-layout
                                v-if="errorServicosDeFrete"
                                justify-center
                                class="pt-3 pb-4"
                                wrap
                              >
                                <v-flex
                                  xs10
                                  text-xs-center
                                >
                                  <span
                                    v-text="'Lamentamos mas não conseguimos obter o valor do frete para sua região junto ao Correios.'"
                                  />
                                  <br>

                                  <span
                                    v-text="'Por favor, tente novamente mais tarde'"
                                  />
                                </v-flex>
                              </v-layout>

                              <v-layout
                                v-else
                                justify-center
                                class="pt-3 pb-4"
                                wrap
                              >
                                <v-flex
                                  v-if="multiplosServicosDeFrete.length >= 2"
                                  xs10
                                >
                                  <v-layout
                                    wrap
                                  >
                                    <v-flex
                                      xs12
                                      text-xs-center
                                    >
                                      <strong
                                        v-text="'Qual serviço de frete você deseja utilizar?'"
                                      />
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      py-2
                                    />

                                    <v-flex
                                      xs12
                                    >
                                      <v-autocomplete
                                        id="ipt_gtm_modal_event_pedido_entrega_servico_entrega"
                                        ref="ipt_gtm_modal_event_pedido_entrega_servico_entrega"
                                        v-model="pedido.postagem.servicoEntrega"
                                        name="ipt_gtm_modal_event_pedido_entrega_servico_entrega"
                                        :items="multiplosServicosDeFrete"
                                        :rules="[ InputRules('required') ]"
                                        :color="!!pedido.postagem.servicoEntrega ? 'success' : 'brand-principal'"
                                        :success="!!pedido.postagem.servicoEntrega"
                                        class="fix-form-input gtm-element-event height-90"
                                        outline
                                      >
                                        <template
                                          v-slot:selection="{ item }"
                                        >
                                          <div
                                            class="text-xs-center width-per-100 cursor-pointer"
                                          >
                                            <span
                                              class="position-relative left-7"
                                              v-text="item"
                                            />
                                          </div>
                                        </template>

                                        <template
                                          v-slot:item="{ item }"
                                        >
                                          <div
                                            class="text-xs-center width-per-100 cursor-pointer"
                                          >
                                            <span
                                              v-text="item"
                                            />
                                          </div>
                                        </template>
                                      </v-autocomplete>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  v-if="(payload.pedido.postagem.valorEntrega && pedido.postagem.servicoEntrega) || successServicoDeFreteGratuito"
                                  xs10
                                  text-xs-center
                                >
                                  <span
                                    v-if="successServicoDeFreteGratuito"
                                    class="d-block"
                                    v-html="`Para o CEP <strong>${payload.consumidor.endereco.cep}</strong>, frete <strong>Gratuito</strong>.`"
                                  />
                                  <span
                                    v-else
                                    class="d-block"
                                    v-html="`Para o CEP <strong>${payload.consumidor.endereco.cep}</strong>, frete de <strong>${currency({ prefix: 'BRL', valor: payload.pedido.postagem.valorEntrega})}</strong> já incluído no carrinho.`"
                                  />
                                  <br>
                                  <span
                                    class="d-block"
                                    v-html="`O prazo de entrega do seu produto é de <strong>${prazoEntregaFrete} ${prazoEntregaFrete > 1 ? 'dias úteis' : 'dia útil'}</strong> após a postagem. </br>Mas, devido à imprevistos de natureza desconhecida, este prazo pode ser estendido.`"
                                  />
                                  <small
                                    v-text="prazoEntregaFreteObservacao"
                                  />
                                </v-flex>

                                <v-flex
                                  v-else-if="loading.payload && pedido.postagem.servicoEntrega"
                                  xs10
                                  text-xs-center
                                >
                                  <span
                                    v-text="'Por favor, Aguarde!'"
                                  />
                                  <v-progress-linear
                                    indeterminate
                                    color="brand-principal"
                                  />
                                </v-flex>
                              </v-layout>

                              <v-card-actions
                                v-if="((payload.pedido.postagem.valorEntrega || errorServicosDeFrete) && pedido.postagem.servicoEntrega) || successServicoDeFreteGratuito"
                                class="pb-4"
                              >
                                <v-btn
                                  round
                                  large
                                  class="mx-auto"
                                  data-test-id="confirmPopUpFrete"
                                  depressed
                                  color="success"
                                  :disabled="(!errorServicosDeFrete && !payload.pedido.postagem.valorEntrega) && !successServicoDeFreteGratuito"
                                  @click="errorServicosDeFrete ? CloseOferta() : fakeFormValorEntrega = !fakeFormValorEntrega"
                                  v-text="errorServicosDeFrete ? 'Tentar novamente mais tarde' : 'OK'"
                                />
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>

                        <v-scroll-y-transition>
                          <v-layout
                            v-show="GetProp('ipt_gtm_modal_event_endereco_cep', 'valid')"
                            column
                          >
                            <v-flex>
                              <label
                                class="font-weight-bold"
                              >
                                Endereço
                              </label>

                              <v-spacer
                                class="height-6"
                              />

                              <v-text-field
                                id="ipt_gtm_modal_event_endereco_logradouro"
                                ref="ipt_gtm_modal_event_endereco_logradouro"
                                v-model="consumidor.endereco.logradouro"
                                name="ipt_gtm_modal_event_endereco_logradouro"
                                :rules="[ InputRules('required'), formulario.validate.endereco.logradouro ]"
                                :color="GetProp('ipt_gtm_modal_endereco_event_logradouro', 'valid') ? 'success' : 'brand-principal'"
                                :success="GetProp('ipt_gtm_modal_event_endereco_logradouro', 'valid')"
                                :append-icon="GetProp('ipt_gtm_modal_event_endereco_logradouro', 'valid') ? 'done' : undefined"
                                :readonly="readonly.consumidor.endereco.logradouro"
                                placeholder="Digite o endereço"
                                required

                                class="fix-form-input gtm-element-event height-90"
                                tabindex="0"
                                outline

                                data-gtm-event-category="claro:meu-plano-claro"
                                data-gtm-event-action="preencheu"
                                data-gtm-event-label="endereco-logradouro"

                                @focus="dataLayer('endereco-logradouro')"
                              />
                            </v-flex>

                            <v-flex>
                              <label
                                class="font-weight-bold"
                              >
                                Cidade
                              </label>

                              <v-spacer
                                class="height-6"
                              />

                              <v-layout
                                row
                              >
                                <v-flex
                                  xs8
                                  mr-2
                                >
                                  <v-text-field
                                    id="ipt_gtm_modal_event_endereco_cidade"
                                    ref="ipt_gtm_modal_event_endereco_cidade"
                                    v-model="consumidor.endereco.cidade"
                                    name="ipt_gtm_modal_event_endereco_cidade"
                                    :rules="[ InputRules('required'), formulario.validate.endereco.cidade ]"
                                    :color="GetProp('ipt_gtm_modal_event_endereco_cidade', 'valid') ? 'success' : 'brand-principal'"
                                    :success="GetProp('ipt_gtm_modal_event_endereco_cidade', 'valid')"
                                    :append-icon="GetProp('ipt_gtm_modal_event_endereco_cidade', 'valid') ? 'done' : undefined"
                                    :readonly="readonly.consumidor.endereco.cidade"
                                    placeholder="Digite a cidade"
                                    required

                                    class="fix-form-input gtm-element-event height-90"
                                    tabindex="0"
                                    outline

                                    data-gtm-event-category="claro:meu-plano-claro"
                                    data-gtm-event-action="preencheu"
                                    data-gtm-event-label="endereco-cidade"

                                    @focus="dataLayer('endereco-cidade')"
                                  />
                                </v-flex>

                                <v-flex
                                  xs4
                                  ml-2
                                >
                                  <v-text-field
                                    id="ipt_gtm_modal_event_endereco_uf"
                                    ref="ipt_gtm_modal_event_endereco_uf"
                                    v-model="consumidor.endereco.uf"
                                    name="ipt_gtm_modal_event_endereco_uf"
                                    :rules="[ InputRules('required'), formulario.validate.endereco.uf ]"
                                    :color="GetProp('ipt_gtm_modal_event_endereco_uf', 'valid') ? 'success' : 'brand-principal'"
                                    :success="GetProp('ipt_gtm_modal_event_endereco_uf', 'valid')"
                                    :append-icon="GetProp('ipt_gtm_modal_event_endereco_uf', 'valid') ? 'done' : undefined"
                                    :readonly="readonly.consumidor.endereco.uf"
                                    :mask="formulario.validate.mask.uf"
                                    placeholder="Digite o estado"
                                    required

                                    class="fix-form-input gtm-element-event height-90"
                                    tabindex="0"
                                    outline

                                    data-gtm-event-category="claro:meu-plano-claro"
                                    data-gtm-event-action="preencheu"
                                    data-gtm-event-label="endereco-uf"

                                    @focus="dataLayer('endereco-uf')"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-layout
                              row
                            >
                              <v-flex
                                xs6
                                mr-2
                              >
                                <label
                                  class="font-weight-bold"
                                >
                                  Bairro
                                </label>

                                <v-spacer
                                  class="height-6"
                                />

                                <v-text-field
                                  id="ipt_gtm_modal_event_endereco_bairro"
                                  ref="ipt_gtm_modal_event_endereco_bairro"
                                  v-model="consumidor.endereco.bairro"
                                  name="ipt_gtm_modal_event_endereco_bairro"
                                  :rules="[ InputRules('required'), formulario.validate.endereco.bairro ]"
                                  :color="GetProp('ipt_gtm_modal_event_endereco_bairro', 'valid') ? 'success' : 'brand-principal'"
                                  :success="GetProp('ipt_gtm_modal_event_endereco_bairro', 'valid')"
                                  :append-icon="GetProp('ipt_gtm_modal_event_endereco_bairro', 'valid') ? 'done' : undefined"
                                  :readonly="readonly.consumidor.endereco.bairro"
                                  placeholder="Digite o bairro"
                                  required

                                  class="fix-form-input gtm-element-event height-90"
                                  tabindex="0"
                                  outline

                                  data-gtm-event-category="claro:meu-plano-claro"
                                  data-gtm-event-action="preencheu"
                                  data-gtm-event-label="endereco-bairro"

                                  @focus="dataLayer('endereco-bairro')"
                                />
                              </v-flex>

                              <v-flex
                                xs6
                                ml-2
                              >
                                <label
                                  class="font-weight-bold"
                                >
                                  Número
                                </label>

                                <v-spacer
                                  class="height-6"
                                />

                                <v-text-field
                                  id="ipt_gtm_modal_event_endereco_numero"
                                  ref="ipt_gtm_modal_event_endereco_numero"
                                  v-model="consumidor.endereco.numero"
                                  data-test-id="numeroEndereco"
                                  name="ipt_gtm_modal_event_endereco_numero"
                                  :rules="[ InputRules('required'), formulario.validate.endereco.numero ]"
                                  :color="GetProp('ipt_gtm_modal_event_endereco_numero', 'valid') ? 'success' : 'brand-principal'"
                                  :success="GetProp('ipt_gtm_modal_event_endereco_numero', 'valid')"
                                  :append-icon="GetProp('ipt_gtm_modal_event_endereco_numero', 'valid') ? 'done' : undefined"
                                  type="tel"
                                  placeholder="Digite o número"
                                  required

                                  class="fix-form-input gtm-element-event height-90"
                                  tabindex="0"
                                  outline

                                  data-gtm-event-category="claro:meu-plano-claro"
                                  data-gtm-event-action="preencheu"
                                  data-gtm-event-label="endereco-numero"

                                  @focus="dataLayer('endereco-numero')"
                                />
                              </v-flex>
                            </v-layout>

                            <v-flex>
                              <label
                                class="font-weight-bold"
                              >
                                Complemento
                              </label>

                              <v-spacer
                                class="height-6"
                              />

                              <v-text-field
                                id="ipt_gtm_modal_event_endereco_complemento"
                                ref="ipt_gtm_modal_event_endereco_complemento"
                                v-model="consumidor.endereco.complemento"
                                data-test-id="complementoEndereco"
                                name="ipt_gtm_modal_event_endereco_complemento"
                                :rules="[ formulario.validate.endereco.complemento ]"
                                :color="!!GetProp('ipt_gtm_modal_event_endereco_complemento', 'value') ? 'success' : 'brand-principal'"
                                :success="!!GetProp('ipt_gtm_modal_event_endereco_complemento', 'value')"
                                :append-icon="!!GetProp('ipt_gtm_modal_event_endereco_complemento', 'value') ? 'done' : undefined"
                                placeholder="Ex: Apt 3 Bl 1"

                                class="fix-form-input gtm-element-event height-90"
                                tabindex="0"
                                outline

                                data-gtm-event-category="claro:meu-plano-claro"
                                data-gtm-event-action="preencheu"
                                data-gtm-event-label="endereco-complemento"

                                @focus="dataLayer('endereco-complemento')"
                              />
                            </v-flex>

                            <v-flex>
                              <label
                                class="font-weight-bold"
                              >
                                Ponto de referência
                              </label>

                              <v-spacer
                                class="height-6"
                              />

                              <v-text-field
                                id="ipt_gtm_modal_event_endereco_referencia"
                                ref="ipt_gtm_modal_event_endereco_referencia"
                                v-model="consumidor.endereco.referencia"
                                data-test-id="pontoReferencia"
                                name="ipt_gtm_modal_event_endereco_referencia"
                                :rules="[ formulario.validate.endereco.referencia ]"
                                :color="!!GetProp('ipt_gtm_modal_event_endereco_referencia', 'value') ? 'success' : 'brand-principal'"
                                :success="!!GetProp('ipt_gtm_modal_event_endereco_referencia', 'value')"
                                :append-icon="!!GetProp('ipt_gtm_modal_event_endereco_referencia', 'value') ? 'done' : undefined"
                                placeholder="Ex: Padaria Bom Pão"
                                hint="Digite lugares e/ou estabelecimentos próximos para facilitar a sua entrega."

                                class="fix-form-input gtm-element-event height-100"
                                tabindex="0"
                                outline

                                data-gtm-event-category="claro:meu-plano-claro"
                                data-gtm-event-action="preencheu"
                                data-gtm-event-label="endereco-referencia"

                                counter
                                maxlength="55"

                                @focus="dataLayer('endereco-referencia')"
                              />
                              <span
                                v-if="prazoEntregaFrete"
                                v-html="`O prazo de entrega do seu produto é de ${prazoEntregaFrete} ${prazoEntregaFrete > 1 ? 'dias úteis' : 'dia útil'} após a postagem. </br>Mas, devido à imprevistos de natureza desconhecida, este prazo pode ser estendido.`"
                              />
                            </v-flex>
                          </v-layout>
                        </v-scroll-y-transition>
                      </v-form>
                    </v-layout>
                  </v-card>
                </v-stepper-content>

                <v-spacer
                  class="height-1"
                />

                <v-stepper-step
                  :editable="getValidatePartial()"
                  step="2"
                  class="py-2 px-3 hidden"
                >
                  <h3
                    class="font-weight-bold text-uppercase"
                  >
                    Pagamento
                  </h3>
                </v-stepper-step>

                <v-stepper-content
                  step="2"
                  class="pa-0 ma-0"
                >
                  <v-spacer
                    class="height-22"
                  />

                  <v-card
                    flat
                    tile
                  >
                    <v-layout
                      column
                      mx-3
                    >
                      <h4
                        v-show="(new RegExp(items.pedido.pagamento.formaPagamento[0].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                        style="font-size: 18px !important; font-weight: 700 !important"
                      >
                        Cartões aceitos:
                      </h4>
                      <!--
                      <h4
                        v-show="(new RegExp(items.pedido.pagamento.formaPagamento[1].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                        style="font-size: 18px !important; font-weight: 700 !important"
                      >
                        Pagamento via Boleto:
                      </h4>
                      -->
                      <h4
                        v-show="(new RegExp(items.pedido.pagamento.formaPagamento[1].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                        style="font-size: 18px !important; font-weight: 700 !important"
                      >
                        Pagamento via PIX:
                      </h4>

                      <v-spacer
                        class="height-22"
                      />

                      <v-form
                        ref="formulario_dados_pagamento"
                        v-model="formulario.validate.step.dadosPagamento"
                      >
                        <v-select
                          id="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                          ref="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                          v-model="pedido.pagamento.formaPagamento"
                          name="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                          :items="items.pedido.pagamento.formaPagamento"
                          :rules="[ InputRules('required') ]"
                          :color="GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'valid') ? 'success' : 'brand-principal'"
                          :success="GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'valid')"
                          item-value="id"
                          class="fix-form-input gtm-element-event height-90"
                          data-test-id="formaPagamentoCliente"
                          outline
                        >
                          <template
                            v-slot:selection="{ item }"
                          >
                            <div
                              class="text-xs-center width-per-100 cursor-pointer"
                              data-test-id="cartaoCredito"
                            >
                              <span
                                class="position-relative left-7"
                                v-text="item.nome"
                              />
                            </div>
                          </template>

                          <template
                            v-slot:item="{ item }"
                          >
                            <div
                              class="text-xs-center width-per-100 cursor-pointer"
                              data-test-id="pix"
                            >
                              <span
                                v-text="item.nome"
                              />
                            </div>
                          </template>
                        </v-select>

                        <v-spacer
                          class="height-22"
                        />

                        <v-layout
                          v-show="(new RegExp(items.pedido.pagamento.formaPagamento[0].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                          column
                        >
                          <v-layout
                            row
                          >
                            <v-img
                              :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-visa.png?lazy`)"
                              contain
                              height="32"
                              class="mx-2"
                            />

                            <v-img
                              :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-mastercard.png?lazy`)"
                              contain
                              height="32"
                              class="mx-2"
                            />

                            <v-img
                              :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-elo.png?lazy`)"
                              contain
                              height="32"
                              class="mx-2"
                            />

                            <v-img
                              :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-hipercard.png?lazy`)"
                              contain
                              height="32"
                              class="mx-2"
                            />
                          </v-layout>

                          <v-spacer
                            class="height-22"
                          />

                          <!-- :items="Array.from(Array(10), (e, i) => i + 1)" -->

                          <v-layout
                            row
                          >
                            <v-flex
                              xs6
                            >
                              <h5
                                style="font-size: 14px !important; font-weight: 400 !important"
                              >
                                Valor total:
                              </h5>

                              <v-spacer
                                class="height-8"
                              />

                              <v-layout
                                class="font-size-18 font-weight-medium"
                              >
                                <v-scroll-y-transition>
                                  <v-flex
                                    v-show="pedido.pagamento.cartaoDeCredito.parcelas >= 2"
                                    xs5
                                    lg3
                                  >
                                    <span
                                      v-text="`${padLeft(String(pedido.pagamento.cartaoDeCredito.parcelas), '00')}x de `"
                                    />
                                  </v-flex>
                                </v-scroll-y-transition>

                                <v-scroll-x-transition>
                                  <v-flex
                                    xs7
                                  >
                                    {{ currency({ prefix: 'BRL', valor: getTotalValue(calculatedTotal()), parcela: pedido.pagamento.cartaoDeCredito.parcelas }) }}
                                    <!-- verificaPedidoComPontoAdicional() -->
                                  </v-flex>
                                </v-scroll-x-transition>
                              </v-layout>
                            </v-flex>

                            <v-flex
                              xs6
                            >
                              <h5
                                style="font-size: 14px !important; font-weight: 400 !important"
                              >
                                Parcelas:
                              </h5>

                              <v-layout
                                class="v-card border-radius-4 py-2  max-width-100"
                                style="border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)"
                                text-xs-center
                              >
                                <v-flex>
                                  <v-btn
                                    flat
                                    small
                                    icon
                                    class="pa-0 ma-0"
                                    @click="pedido.pagamento.cartaoDeCredito.parcelas < 10 && pedido.pagamento.cartaoDeCredito.parcelas++"
                                  >
                                    <i
                                      class="mdn-Icon-mais mdn-Icon--sm blue--text cursor-pointer"
                                    />
                                  </v-btn>
                                </v-flex>

                                <v-flex>
                                  <v-btn
                                    flat
                                    small
                                    icon
                                    tabindex="-1"
                                    class="pa-0 ma-0"
                                  >
                                    {{ padLeft(String(pedido.pagamento.cartaoDeCredito.parcelas), '00') }}
                                  </v-btn>
                                </v-flex>

                                <v-flex>
                                  <v-btn
                                    flat
                                    small
                                    icon
                                    class="pa-0 ma-0"
                                    @click="pedido.pagamento.cartaoDeCredito.parcelas > 1 && pedido.pagamento.cartaoDeCredito.parcelas--"
                                  >
                                    <i
                                      class="mdn-Icon-menos mdn-Icon--sm blue--text cursor-pointer"
                                    />
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>

                          <v-flex
                            xs7
                          >
                            <small
                              v-text="`(Frete de ${currency({ prefix: 'BRL', valor: payload.pedido.postagem.valorEntrega})} ${pedido.produto.quantidade > 1 ? `para os ${pedido.produto.quantidade} itens` : ''} já incluso)`"
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout
                          v-show="(new RegExp(`(${items.pedido.pagamento.formaPagamento[1].id})`)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                          column
                        >
                          1x de {{ currency({ prefix: 'BRL', valor: getTotalValue(calculatedTotal()) }) }}
                          <!-- verificaPedidoComPontoAdicional() -->
                        </v-layout>
                        <!--
                          v-show="(new RegExp(`(${items.pedido.pagamento.formaPagamento[1].id}|${items.pedido.pagamento.formaPagamento[2].id})`)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                          column >

                          1x de {{ currency({ prefix: 'BRL', valor: getTotalValue(calculatedTotal()) }) }}

                        </v-layout>
                        -->

                        <v-spacer
                          class="height-22"
                        />

                        <v-checkbox
                          id="ipt_gtm_modal_event_contrato_servico"
                          v-model="pedido.ciente.contrato.servico"
                          :color="GetProp('ipt_gtm_modal_event_contrato_servico', 'valid') ? 'brand-principal' : 'success'"
                          :rules="[ InputRules('required') ]"
                          required

                          class="gtm-element-event pa-0 ma-0"
                          tabindex="0"

                          data-gtm-event-category="claro:meu-plano-claro"
                          data-gtm-event-action="preencheu"
                          data-gtm-event-label="contrato-servico"
                          data-test-id="checkBoxProduto3"

                          @change="dataLayer('contrato-servico')"
                        >
                          <template
                            v-slot:label
                          >
                            <v-layout
                              column
                            >
                              <h5
                                class="font-weight-regular grey-dark--text font-size-10"
                              >
                                <span>
                                  Declaro que conheço e concordo com as
                                  <a
                                    :href="getDocument('termo-adesao')"
                                    target="_blank"
                                    v-html="'Termo de Adesão da Claro TV Pré.'"
                                  />
                                  Estou ciente que para adequado funcionamento é necessária a contratação de um técnico instalador e aquisição de uma antena compatível (caso ainda não tenha uma).
                                  Instruções sobre contratação de técnico
                                  <a
                                    @click="openFaq('contrate-tecnico')"
                                    v-html="'clique aqui'"
                                  />.
                                  Para acesso a programação o produto exige que seja realizada uma recarga.
                                  Para mais informações sobre recargas
                                  <a
                                    @click="openFaq('faca-recarga')"
                                    v-html="'clique aqui'"
                                  />.
                                </span>
                              </h5>
                            </v-layout>
                          </template>
                        </v-checkbox>
                      </v-form>
                    </v-layout>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-flex>

            <v-flex xs12>
              <v-card-actions
                :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 1024} mx-auto`"
              >
                <v-layout
                  :column="$vuetify.breakpoint.mdAndDown"
                >
                  <v-flex
                    :order-xs2="$vuetify.breakpoint.mdAndDown"
                    xs4
                  >
                    <v-btn
                      class="flat gtm_button font-size-18 font-weight-bold"
                      color="grey grey--text"
                      outline
                      block
                      large
                      round
                      tabindex="-1"
                      @click="formulario.active > 1 ? formulario.active = 1 : pushStep('voltar')"
                    >
                      Voltar
                    </v-btn>
                  </v-flex>

                  <v-spacer
                    class="mx-4"
                  />

                  <v-flex
                    order-lg2
                    xs8
                  >
                    <v-btn
                      :id="getName({ source: 'gtmid' })"
                      :class="`${$vuetify.breakpoint.mdAndDown && 'mx-auto mb-3'} flat gtm_button font-size-18 font-weight-bold`"
                      :color="(formulario.active < 2 ? getValidatePartial() : getValidateForm()) ? 'warning lighten-2 black--text' : 'grey lighten-3 cursor-disabled grey--text text--lighten-1'"
                      block
                      large
                      round
                      :loading="loading.payload"
                      :disabled="!(formulario.active < 2 ? getValidatePartial() : getValidateForm()) || loading.payload"
                      data-test-id="bntAvancar"
                      tabindex="0"
                      @click="formulario.active < 2 ? pushStep() : pushStep('payload')"
                    >
                      <span
                        v-text="formulario.active < 2 ? 'Avançar' : 'Realizar pagamento'"
                      />
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          order-xs1
          order-md2
          xs12
          md6
          :pl-5="$vuetify.breakpoint.mdAndUp"
        >
          <formPanelQuantidadeContrato
            :get-product="getProduct"
            :payload="payload"
            :pedido="pedido"
            :verifica-pedido-com-ponto-adicional="verificaPedidoComPontoAdicional"
            :currency="currency"
            :calculated-total="calculatedTotal"
            :route-promo="RoutePromo"
            :verifica-quantidade-pedido="verificaQuantidadePedido"
            :pad-left="padLeft"
            :formulario="formulario"
            :get-prop="GetProp"
            :input-rules="InputRules"
            :data-layer="dataLayer"
            :open-faq="openFaq"
          />
        </v-flex>
      </v-layout>
    </v-card>

    <promo-invalid-cep
      v-if="showInvalidCepWarning"
      :dialog="showInvalidCepWarning"
      @close="showInvalidCepWarning=false"
    />

    <ComponentDialogDeliveryCepError
      :try-other-cep="tryOtherCep"
    />
  </div>
</template>

<style lang="stylus">
  .fix-stepper
    .v-stepper__step
      background-color #f6f6f6 !important
      .v-stepper__step__step
        float right
        position absolute
        right 0
        background-color transparent !important
        color transparent
        &::after
          content '⯆'
          color grey !important
          position absolute
    .v-stepper__content
      border none !important

  .fix-form-input
    input
      margin-top: 12px !important
    .v-select__selections
      padding-top: 0px !important
    .v-select__slot
      cursor: pointer !important

  .gtm_button
    div.v-btn__content
      height 0px !important
</style>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  import axios from 'axios'

  import PromoInvalidCep from '@/components/dialogs/PromoUFCEPInvalid'
  import formPanelQuantidadeContrato from '@/components/forms/panel/QuantidadeContrato'

  /** @Mixins */
  import pad from '@/plugins/mixins/pad'
  import mask from '@/plugins/mixins/mask'
  import rule from '@/plugins/mixins/rule'
  import lista from '@/plugins/mixins/lista'
  import prop from '@/plugins/mixins/prop'
  import hint from '@/plugins/mixins/hint'
  import product from '@/plugins/mixins/product'
  import MixinsTvprePedidos from '@/plugins/mixins/pedidos'
  import MixinAPIConsultaValorFrete from '@/plugins/mixins/api/frete/ConsultaValorFrete'
  import { MixinControlDialogs } from '@/mixins/dialogs/MixinControlDialogs'
  /** @Mixins */

  export default {
    components: {
      PromoInvalidCep,
      formPanelQuantidadeContrato,
      ComponentDialogDeliveryCepError: () => import(
        /* webpackChunkName: "component-dialog-delivery-cep-error" */
        /* webpackMode: "eager" */
        "@/components/dialogs/errors/ComponentDialogDeliveryCepError.vue"
      ),
    },
    mixins: [
      pad,
      mask,
      rule,
      lista,
      prop,
      hint,
      product,
      MixinsTvprePedidos,
      MixinAPIConsultaValorFrete,
      MixinControlDialogs,
    ],

    props: {
      get: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'valorfrete'),
        fakeFormValorEntrega: false,
        input: undefined,
        showInvalidCepWarning: false,

        loading: {
          active: undefined,
          payload: false
        },

        formulario: {
          active: 1,

          steps: [
            {
              id: 1,
              nome: 'Dados Pessoais'
            },
            {
              id: 2,
              nome: 'Pagamento'
            }
          ],

          validate: {
            step: {
              contrato: false,
              dadosPessoais: false,
              dadosPagamento: false
            },

            consumidor: {
              nome: 'false',
              filiacao: 'false',
              nascimento: 'false',
              identidade: {
                numero: 'false',
                emissor: 'false',
                uf: 'false'
              }
            },

            endereco: {
              cep: 'false',
              logradouro: 'false',
              cidade: 'false',
              bairro: 'false',
              uf: 'false',
              numero: 'false',
              referencia: 'false',
              complemento: 'false'
            },

            mask: {
              data: '##/##/####',
              cep: '#####-###',
              uf: 'AA',
              identidade: {
                numero: '####################'
              }
            }
          }
        },

        readonly: {
          consumidor: {
            endereco: {
              logradouro: false,
              bairro: false,
              localidade: false,
              uf: false
            }
          }
        },

        consumidor: {
          nome: undefined,
          nascimento: undefined,
          nascimentoPicker: false,
          filiacao: undefined,

          endereco: {
            cep: undefined,
            logradouro: undefined,
            bairro: undefined,
            numero: undefined,
            complemento: undefined,
            cidade: undefined,
            uf: undefined,
            referencia: undefined
          }
        },

        items: {
          pedido: {
            pagamento: {
              formaPagamento: [
                {
                  nome: 'Cartão de Crédito',
                  id: 'cartaodecredito'
                },
                /* {
                  nome: 'Boleto Bancário',
                  id: 'boletobancario'
                }, */
                {
                  nome: 'PIX',
                  id: 'pix'
                }
              ]
            }
          }
        },

        pedido: {
          produto: {
            quantidade: 1
          },
          pagamento: {
            formaPagamento: 'cartaodecredito',
            cartaoDeCredito: {
              parcelas: 10
            }
          },
          ciente: {
            instalacao: undefined,
            recarga: undefined,
            contrato: {
              servico: undefined
            }
          },
          postagem: {
            servicoEntrega: undefined,
            valorEntrega: 0
          },
          valorTotal: undefined
        }
      }
    },

    computed: {
      ...mapState('Modules', [ 'env', 'formData' ]),
      ...mapState('Payload', [ 'payload' ]),
      ...mapGetters('Modules', [ 'RoutePromo' ])
    },

    watch: {
      'loading.active' () {
        const l = this.loading.active
        this.loading[l] = !this.loading[l]

        setTimeout(() => (this.loading[l] = false), 3000)

        this.loading.active = undefined
      },

      'consumidor.nome' () {
        const pattern = /^[a-záàâãéèêíïóôõöúüçñ]{3,}(\s[a-záàâãéèêíïóôõöúüçñ]{2,})+$/i
        this.consumidor.nome = this.consumidor.nome.trim()

        if (pattern.test(this.consumidor.nome)) {
          this.formulario.validate.consumidor.nome = true
          this.SaveInput({
            input: true,
            name: 'consumidorNome',
            value: this.consumidor.nome
          })
        } else {
          this.formulario.validate.consumidor.nome = 'Nome inválido'
        }
      },

      'consumidor.nascimentoPicker' (value) {
        value && this.dataLayer('consumidor-nascimento')

        value &&
          setTimeout(
            () => (this.$refs.consumidornascimentopicker.activePicker = 'YEAR')
          )
      },

      'consumidor.nascimento' () {
        const pattern = /^(?:(?:31([-/.]?)(?:0[13578]|1[02]))\1|(?:(?:29|30)([-/.]?)(?:0[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29([-/.]?)02\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26]))))$|^(?:0[1-9]|1\d|2[0-8])([-/.]?)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/i
        this.consumidor.nascimento = this.consumidor.nascimento.trim()

        if (pattern.test(this.consumidor.nascimento)) {
          let day = Number(this.consumidor.nascimento.substring(0, 2))
          let month = Number(this.consumidor.nascimento.substring(2, 4))
          let year = Number(this.consumidor.nascimento.substring(4, 8))
          let date = new Date()
          let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) { monthLength[1] = 29 }

          let Breack
          if (year < date.getFullYear() - 110 || month === 0 || month > 12) { Breack = 'Data inválida' }
          if (year > date.getFullYear() - 18) { Breack = 'Você deve ser maior de 18 anos' }
          if (date.getFullYear() - year === 18 && date.getMonth() + 1 < month) { Breack = 'Você deve ser maior de 18 anos' }
          if (
            date.getFullYear() - year === 18 &&
            date.getMonth() + 1 === month &&
            date.getDate() < day
          ) { Breack = 'Você deve ser maior de 18 anos' }

          this.formulario.validate.consumidor.nascimento =
            Breack ||
            (day > 0 && day <= monthLength[month - 1]) ||
            'Data inválida'

          if (!Breack) {
            this.SaveInput({
              input: true,
              name: 'consumidorNascimento',
              value: this.consumidor.nascimento
            })
          }
        } else {
          this.formulario.validate.consumidor.nascimento = 'Data inválida'
        }
      },

      'consumidor.endereco.cep': async function () {
        const pattern = /^[0-9]{5}-?[\d]{3}$/i
        this.consumidor.endereco.cep = this.consumidor.endereco.cep.trim()
        if (this.consumidor.endereco.cep.length <= 7) {
          this.formulario.validate.endereco.cep = ''
          return
        }

        try {
          if (pattern.test(this.consumidor.endereco.cep)) {
            this.formulario.validate.endereco.cep = 'Buscando...'

            this.SaveInput({
              input: true,
              name: 'consumidorEnderecoCep',
              value: this.consumidor.endereco.cep
            })

            await this.getAddress().then(() => {
              if (this.formulario.validate.endereco.cep !== true) throw Error('cep-invalido')
              const orderUF = this.payload.consumidor.endereco.uf
              const prodId = this.payload.pedido.produto[0].id
              const basePrice = this.getProduct(prodId, 'price').default
              if ((this.payload.pedido.produto[0].id === 'vtxantenareceptorhd') || (this.$route.meta.promo && (['promoreceptorhdcanais', 'promoreceptorhd'].indexOf(this.RoutePromo.promo.name) !== -1))) {
                const ufFind = this.getUFIndex(this.getProduct(prodId, 'promo').ufs, orderUF)
                if (ufFind === undefined) {
                  this.showInvalidCepWarning = true
                  throw Error('cep-invalido-para-produto')
                } else {
                  this.SaveInput({
                    input: true,
                    name: 'pedidoValor',
                    value: this.calculatedDiscountedPrice(basePrice, ufFind.discount)
                  })
                }
              }
              this.SaveInput({
                input: true,
                name: 'pedidoValorTotal',
                value: this.calculatedTotal() // this.verificaPedidoComPontoAdicional()
              })
              this.LOG('vou abrir a tela de mensagem para Frete')
              this.ServicosFreteDisponiveis()

              this.teste_cobertura_cep_impedimento_entrega_chip_fisico({
                cep: this.consumidor.endereco.cep || "",
                uf: orderUF || "",
              })
                .then((response) => {
                  this.dialogDeliveryCepError = response
                })
                .catch((error) => {
                  window.log("error on - teste_cobertura_cep_impedimento_entrega_chip_fisico", error)
                })
            }).catch(() => {
              throw Error('cep-invalido')
            })
          } else throw Error('cep-invalido')
        } catch (e) {
          this.LOG('error on - consumidor.endereco.cep', e)
          this.changeAddressPayload({})
          this.loading.payload = false
          this.formulario.validate.endereco.cep = 'CEP inválido'
        }
      },

      'consumidor.endereco.logradouro' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.logradouro = this.consumidor.endereco.logradouro.trim()

        if (pattern.test(this.consumidor.endereco.logradouro)) {
          this.formulario.validate.endereco.logradouro = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoLogradouro',
            value: this.consumidor.endereco.logradouro
          })
        } else {
          this.formulario.validate.endereco.logradouro = 'Endereço inválido'
        }
      },

      'consumidor.endereco.cidade' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.cidade = this.consumidor.endereco.cidade.trim()

        if (pattern.test(this.consumidor.endereco.cidade)) {
          this.formulario.validate.endereco.cidade = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoCidade',
            value: this.consumidor.endereco.cidade
          })
          this.LOG('vou abrir a tela de mensagem para Frete')
          this.ServicosFreteDisponiveis()
        } else {
          this.formulario.validate.endereco.cidade = 'Digite a cidade'
        }
      },

      'consumidor.endereco.uf' () {
        const pattern = /^[A-Z]{2}$/i
        this.consumidor.endereco.uf = this.consumidor.endereco.uf.trim()

        if (pattern.test(this.consumidor.endereco.uf)) {
          if (!new RegExp(this.listaEstados('array').join('|')).test(this.consumidor.endereco.uf)) {
            this.formulario.validate.endereco.uf = 'Estado inválido'
            return
          }
          this.formulario.validate.endereco.uf = true
          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoUf',
            value: this.consumidor.endereco.uf
          })
        } else {
          this.formulario.validate.endereco.uf = 'Estado inválido'
        }
      },

      'consumidor.endereco.bairro' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.bairro = this.consumidor.endereco.bairro.trim()

        if (pattern.test(this.consumidor.endereco.bairro)) {
          this.formulario.validate.endereco.bairro = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoBairro',
            value: this.consumidor.endereco.bairro
          })
        } else {
          this.formulario.validate.endereco.bairro = 'Bairro inválido'
        }
      },

      'consumidor.endereco.numero' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.numero = this.consumidor.endereco.numero.trim()

        if (pattern.test(this.consumidor.endereco.numero)) {
          this.formulario.validate.endereco.numero = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoNumero',
            value: this.consumidor.endereco.numero
          })
        } else {
          this.formulario.validate.endereco.numero = 'Número inválido'
        }
      },

      'consumidor.endereco.complemento' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.complemento = this.consumidor.endereco.complemento.trim()

        if (!this.consumidor.endereco.complemento) {
          this.formulario.validate.endereco.complemento = true
          return
        }

        if (pattern.test(this.consumidor.endereco.complemento)) {
          this.formulario.validate.endereco.complemento = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoComplemento',
            value: this.consumidor.endereco.complemento
          })
        } else {
          this.formulario.validate.endereco.complemento = 'Complemento inválido'
        }
      },

      'consumidor.endereco.referencia' () {
        const pattern = /^['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{0,}(\s?['"`´ªº°()/0-9a-záàâãéèêíïóôõöúüçñ,.;:-]{1,})+$/i
        this.consumidor.endereco.referencia = this.consumidor.endereco.referencia.trim()

        if (!this.consumidor.endereco.referencia) {
          this.formulario.validate.endereco.referencia = true
          return
        }

        if (pattern.test(this.consumidor.endereco.referencia)) {
          this.formulario.validate.endereco.referencia = true

          this.SaveInput({
            input: true,
            name: 'consumidorEnderecoReferencia',
            value: this.consumidor.endereco.referencia
          })
        } else {
          this.formulario.validate.endereco.referencia = 'Ponto de referência inválido'
        }
      },

      'pedido.postagem.valorEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemValorEntrega',
          value: this.pedido.postagem.valorEntrega
        })
      },

      'pedido.valorTotal' () {
        this.SaveInput({
          input: true,
          name: 'pedidoValorTotal',
          value: this.pedido.valorTotal
        })
      },

      'pedido.pagamento.formaPagamento' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPagamentoFormaPagamento',
          value: this.pedido.pagamento.formaPagamento
        })
      },

      'pedido.pagamento.cartaoDeCredito.parcelas' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPagamentoCartaoDeCreditoParcelas',
          value: this.pedido.pagamento.cartaoDeCredito.parcelas
        })
      },

      'pedido.produto.quantidade' () {
        this.verificaQuantidadeAdicionalPedido(this.pedido.produto.quantidade)
        this.getTotalValue(this.calculatedTotal()) // this.verificaPedidoComPontoAdicional()
        this.prepareVertexAPIConsultarValorFrete()
      },

      'pedido.postagem.servicoEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemServicoEntrega',
          value: this.pedido.postagem.servicoEntrega
        })
        this.prepareVertexAPIConsultarValorFrete()
      },

      'pedido.ciente.instalacao' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteInstalacao',
          value: this.pedido.ciente.instalacao
        })
      },

      'pedido.ciente.recarga' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteRecarga',
          value: this.pedido.ciente.recarga
        })
      },

      'pedido.ciente.contrato.servico' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteContratoServico',
          value: this.pedido.ciente.contrato.servico
        })
      }
    },

    mounted () {
      if (this.payload.consumidor.nome) this.consumidor.nome = this.payload.consumidor.nome
      if (this.payload.consumidor.nascimento) this.consumidor.nascimento = this.payload.consumidor.nascimento
      /* if (this.payload.consumidor.identidade.numero) this.consumidor.identidade.numero = this.payload.consumidor.identidade.numero
      if (this.payload.consumidor.identidade.emissor) this.consumidor.identidade.emissor = this.payload.consumidor.identidade.emissor
      if (this.payload.consumidor.identidade.uf) this.consumidor.identidade.uf = this.payload.consumidor.identidade.uf */
      if (this.payload.consumidor.endereco.cep) this.consumidor.endereco.cep = this.payload.consumidor.endereco.cep
      if (this.payload.consumidor.endereco.logradouro) this.consumidor.endereco.logradouro = this.payload.consumidor.endereco.logradouro
      if (this.payload.consumidor.endereco.cidade) this.consumidor.endereco.cidade = this.payload.consumidor.endereco.cidade
      if (this.payload.consumidor.endereco.uf) this.consumidor.endereco.uf = this.payload.consumidor.endereco.uf
      if (this.payload.consumidor.endereco.bairro) this.consumidor.endereco.bairro = this.payload.consumidor.endereco.bairro
      if (this.payload.consumidor.endereco.numero) this.consumidor.endereco.numero = this.payload.consumidor.endereco.numero
      if (this.payload.consumidor.endereco.complemento) this.consumidor.endereco.complemento = this.payload.consumidor.endereco.complemento
      if (this.payload.consumidor.endereco.referencia) this.consumidor.endereco.referencia = this.payload.consumidor.endereco.referencia
      if (this.payload.pedido.postagem.servicoEntrega) this.pedido.postagem.servicoEntrega = this.payload.pedido.postagem.servicoEntrega
      if (this.payload.pedido.postagem.valorEntrega) this.pedido.postagem.valorEntrega = this.payload.pedido.postagem.valorEntrega
      if (this.payload.pedido.pagamento.formaPagamento) this.pedido.pagamento.formaPagamento = this.payload.pedido.pagamento.formaPagamento
      if (this.payload.pedido.pagamento.cartaoDeCredito.parcelas) this.pedido.pagamento.cartaoDeCredito.parcelas = this.payload.pedido.pagamento.cartaoDeCredito.parcelas
      if (this.payload.pedido.ciente.instalacao) this.pedido.ciente.instalacao = this.payload.pedido.ciente.instalacao
      if (this.payload.pedido.ciente.recarga) this.pedido.ciente.recarga = this.payload.pedido.ciente.recarga
    },

    methods: {
      ...mapActions('Payload', [ 'SaveInput', 'UpdatePayload', 'ActionClearAddressPayload' ]),
      ...mapActions('Dialogs', [ 'openFaq' ]),
      ...mapActions('Modules', [ 'CloseOferta' ]),

      async getAddress () {
        try {
          const { data } = await axios.get(`https://viacep.com.br/ws/${this.consumidor.endereco.cep}/json`)
          this.LOG('dados recebidos do Viacep', data)
          if (Object.prototype.hasOwnProperty.call(data, 'erro')) throw new Error('erro-viacep')
          this.changeAddressPayload(data)
        } catch (e) {
          this.LOG('error on - dados do Viacep', e)
          switch (true) {
            case (/erro/.test(e.message)):
              this.formulario.validate.endereco.cep = 'CEP não encontrado'
              this.changeAddressPayload({})
              return
          }
          this.formulario.validate.endereco.cep = true
        }
      },

      tryOtherCep () {
        this.consumidor.endereco.cep = ""
        this.formulario.validate.endereco.cep = ""
        this.dialogDeliveryCepError = false
      },

      async teste_cobertura_cep_impedimento_entrega_chip_fisico ({ cep, uf }) {
        return new Promise((resolve, reject) => {
          fetch("https://cdn-vertex-operacoes.s3.amazonaws.com/cobertura_cep_impedimento_entrega_chip_fisico.json")
            .then((response) => response.json())
            .then((data) => {
              cep = Number(String(cep).replace(/\D/g, ""))
              if (isNaN(cep)) return reject("cep-not-a-number")
              if (!uf) return reject("uf-required")

              const BLOCKED_CEP_LIST = data[uf]
              if (!BLOCKED_CEP_LIST) return resolve(false)

              let blocked = false

              for (const [, { start, end }] of Object.entries(BLOCKED_CEP_LIST)) {
                if (!blocked) blocked = cep >= Number(start) && cep <= Number(end)
              }

              resolve(blocked)
            })
        })
      },

      changeAddressPayload (data) {
        this.LOG('dados recebidos em changeAddressPayload', Object.keys(data).length)
        this.readonly.consumidor.endereco.logradouro = !!data.logradouro
        this.consumidor.endereco.logradouro = data.logradouro ? data.logradouro : ''
        this.readonly.consumidor.endereco.bairro = !!data.bairro
        this.consumidor.endereco.bairro = data.bairro ? data.bairro : ''
        this.readonly.consumidor.endereco.cidade = !!data.localidade
        this.consumidor.endereco.cidade = data.localidade ? data.localidade : ''
        this.readonly.consumidor.endereco.uf = !!data.uf
        this.consumidor.endereco.uf = data.uf ? data.uf : ''
        this.consumidor.endereco.complemento = data.complemento ? data.complemento : ''
        this.consumidor.endereco.referencia = data.referencia ? data.referencia : ''
        this.formulario.validate.endereco.cep = !!data.cep

        if (Object.keys(data).length) return
        this.ActionClearAddressPayload()
      },

      getTotalValue (value) {
        if (value) this.pedido.valorTotal = value
        return this.pedido.valorTotal
      },

      getDocument (param) {
        if (param === 'termo-adesao') return '/content/documents/contracts/termo-de-adesao-tvpre.pdf'
      },

      getName (param) {
        if (param.source === 'gtmid') {
          return this.getValidateForm() ? this.get.gtm : 'disabled'
        }
      },

      getValidatePartial () {
        if (!this.formulario.validate.step.contrato) return false
        if (!this.GetProp('formulario_dados_pessoais', 'value')) return false

        return true
      },

      getValidateForm (revalid) {
        if (revalid) {
          this.GetProp('formulario_contrato').validate()
          this.GetProp('formulario_dados_pessoais').validate()
          this.GetProp('formulario_dados_pagamento').validate()
          return
        }

        if (!this.formulario.validate.step.contrato) return false
        if (!this.GetProp('formulario_dados_pessoais', 'value')) return false
        if (!this.GetProp('formulario_dados_pagamento', 'value')) return false

        return true
      },

      consumidornascimentosave (date) {
        let [year, month, day] = date.split('-')
        this.$refs.consumidornascimento.save(
          (this.consumidor.nascimento = `${day}${month}${year}`)
        )
      },

      linkCorreios () {
        this.dataLayer('buscar-cep')

        window.open(
          'https://www.buscacep.correios.com.br/sistemas/buscacep/',
          '_blank'
        )
      },

      pushStep (param) {
        if (!param) {
          this.dataLayer()
          this.formulario.active = 2
        }

        if (param === 'payload') {
          this.loading.active = 'payload'

          if (this.getValidateForm()) {
            this.$emit('actionButton', 'contratar')
            this.dataLayer()
          } else {
            this.getValidateForm(true)
          }
        }

        if (param === 'voltar') {
          this.$emit('actionButton', 'voltar')
        }
      },

      dataLayer (param) {
        if (param && this.input !== param) {
          this.input = param

          window.dataLayer.push({
            'event': 'event',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'preencheu',
            'eventLabel': param
          })
        }

        if (!param) {
          window.dataLayer.push({
            'event': 'checkout',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'enhanced-ecommerce',
            'eventLabel': `checkout-${this.formulario.active}-etapa-dados-${this.formulario.active === 1 ? 'pessoais' : 'pagamento'}`,
            'ecommerce': {
              'nonInteraction': '1',
              'ec': 'setAction',
              'checkout': {
                'actionField': {
                  'step': this.formulario.active
                },
                'products': [{
                  'name': String(this.getProduct(this.payload.pedido.produto[0].id, 'title').nome).replace(/ /g, '').toLowerCase(),
                  'id': String(this.getProduct(this.payload.pedido.produto[0].id, 'plan')).replace(/ /g, ''),
                  'price': String(this.getProduct(this.payload.pedido.produto[0].id, 'price').default).replace(/,|\./g, '.'),
                  'brand': 'meu-plano-claro',
                  'category': 'tvpre',
                  'quantity': '1'
                }]
              }
            }
          })
        }
      }
    }
  }
</script>
