<template>
  <v-card
    v-height="$vuetify.breakpoint.mdAndDown && '100%'"
    v-width.max="500"
    :class="`${$vuetify.breakpoint.mdAndDown && 'pb-5 mb-5'} px-3 mx-auto`"
    flat
  >
    <div
      v-if="loading"
    >
      <v-layout
        v-width.max="$vuetify.breakpoint.lgAndUp && 500"
        v-height.min="$vuetify.breakpoint.lgAndUp && 456"
        :class="`${$vuetify.breakpoint.lgAndUp && 'align-center'} pb-${$vuetify.breakpoint.mdAndDown ? 3 : 0}`"
      >
        <v-flex
          mt-5
        >
          <v-layout
            column
          >
            <v-flex
              mb-2
            >
              <h3
                :class="`font-weight-regular grey-regular--text ${$vuetify.breakpoint.mdAndDown && 'text-xs-center'}`"
              >
                Por favor, aguarde! Estamos processando seu pagamento.
              </h3>
            </v-flex>

            <v-flex
              mt-2
            >
              <v-progress-linear
                :indeterminate="loading"
                color="brand-principal"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>

    <v-scroll-y-transition>
      <div
        v-if="negado"
      >
        <v-layout
          v-width.max="$vuetify.breakpoint.lgAndUp && 500"
          v-height.min="$vuetify.breakpoint.lgAndUp && 456"
          :class="`${$vuetify.breakpoint.lgAndUp && 'align-center'} pb-${$vuetify.breakpoint.mdAndDown ? 3 : 0}`"
          wrap
          justify-center
        >
          <v-flex
            xs12
            text-xs-center
          >
            <h2
              :class="`font-weight-regular grey-regular--text ${$vuetify.breakpoint.mdAndDown && 'text-xs-center my-5'}`"
            >
              <strong
                class="error--text"
                v-text="'Atenção'"
              />
              <div
                v-html="negado.message"
              />
            </h2>
          </v-flex>

          <v-flex
            v-if="pagamentoCartaoDeCreditoNegado()"
            xs12
          >
            <v-layout
              wrap
              justify-center
            >
              <v-flex
                xs6
                md4
              >
                <v-img
                  src="https://firebasestorage.googleapis.com/v0/b/meuplanoclaro-com-br.appspot.com/o/asset%2Fimage%2Fcontent%2Fpayment%2Fpix%2Fpagamento-com-pix-powered-by-banco-central.png?alt=media"
                />
              </v-flex>

              <v-flex
                py-3
                xs12
                text-xs-center
              >
                <strong
                  v-font-size="20"
                  v-text="'Pague com PIX! É fácil, rápido e com comprovação imediata!'"
                />
              </v-flex>

              <v-flex
                xs10
                md9
              >
                <v-btn
                  v-font-size="20"
                  class="flat gtm_button font-weight-bold"
                  :class="$vuetify.breakpoint.mdAndDown && 'mx-auto'"
                  color="brand-principal"
                  dark
                  block
                  large
                  round
                  @click="$emit('tryAgain', 'pix')"
                >
                  Pagar com PIX
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex
            xs10
            md9
          >
            <v-btn
              v-font-size="20"
              class="flat gtm_button font-weight-bold"
              :class="$vuetify.breakpoint.mdAndDown && 'mx-auto'"
              color="brand-principal"
              dark
              block
              large
              round
              @click="$emit('tryAgain')"
            >
              Outra forma de pagamento
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-scroll-y-transition>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'

  /** @Mixins */
  import find from '@/plugins/mixins/keyfind'
  /** @mixins */

  export default {
    mixins: [
      find
    ],

    props: {
      action: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'tentarpix'),
        loading: true,
        negado: false,
        pedido: {
          pagamento: {
            formaPagamento: ''
          }
        }
      }
    },

    watch: {
      action (param) {
        this.actionButton(param)
      }
    },

    methods: {
      ...mapActions('Payload', [ 'GetOrder' ]),

      actionButton (param) {
        this.LOG('parametro recebido dentro do PaymenLoading %o', param)

        if (this.keyFind(param, 'success')) {
          // if ((/boletobancario/).test(param.success)) {
          //   this.$emit('actionButton', { 'success': param })
          //   return
          // }

          this.GetOrder(param.sale).then(response => {
            this.LOG('response do getOrder', response)
            if (response.error) {
              this.$emit('closeDialog')
              switch (response.error.id) {
                case 'negado':
                  this.negado = response.error
                  this.pedido.pagamento.formaPagamento = response.error.pedido.pagamento.formaPagamento
                  break
                case 'pendente':
                  this.$emit('awaitOrder', response)
                  break
                default:
                  this.$emit('awaitOrder', response)
                  break
              }
              return
            }

            this.$emit('actionButton', { 'success': response })
          }).catch(error => {
            this.LOG('error on', __dirname, error)
            this.$emit('closeDialog')
            switch (error.error.id) {
              case 'negado':
                this.negado = error.error
                break
              case 'pendente':
                this.$emit('awaitOrder', error)
                break
              default:
                this.$emit('awaitOrder', error)
                break
            }
          }).finally(() => {
            this.loading = false
          })
        }

        if (this.keyFind(param, 'error')) {
          this.negado = {
            message: param.message
          }
          this.loading = false
        }
      },

      pagamentoCartaoDeCreditoNegado () {
        return this.negado && /credito/.test(this.pedido.pagamento.formaPagamento)
      }
    }
  }
</script>
