/** @Howto */
/*
  # send a string and exit format
  # eg: padLeft(String(quantidade), "00")
  # return 01 || 02 || 10...
*/
/** @Howto */

export default {
  methods: {
    padLeft (
      value,
      pad
    ) {
      let str = '' + value
      let ans = pad.substring(0, pad.length - str.length) + str
      if (value && pad) return ans
    },
    padRight (
      value,
      pad
    ) {
      let str = value + ''
      let ans = str + pad.substring(pad.length - str.length, 0)
      if (value && pad) return ans
    }
  }
}
// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd
