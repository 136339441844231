/** @Database */
import {
  bancos
} from '@/database/pagamento/index'
/** @Database */

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Modules', [ 'payload' ])
  },

  methods: {
    MaskTelefone (telefone) {
      telefone = telefone.replace(/\D/g, '')
      telefone = telefone.replace(/^(\d{2})(\d)/g, '($1) $2')
      telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2')

      return telefone
    },

    InputMask (mask) {
      if (mask === 'agencia') return '####'

      if (mask === 'conta') {
        if (this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco) {
          if (this.maskBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco)) {
            return this.maskBancoConta(this.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco)
          }
        }
      }
    },

    maskBancoConta (
      banco
    ) {
      banco = banco.split(' - ')[0]
      banco = bancos.map(o => o.banco.split(' - ')[0] === banco).indexOf(true)
      return bancos[banco].mask
    },

    currency ({
      prefix,
      valor,
      parcela
    }
    ) {
      if (prefix) {
        if (parcela) return (parseFloat(valor) / parcela).toLocaleString('pt-BR', { style: 'currency', currency: prefix })
        return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: prefix })
      } else {
        if (parcela) return String((parseFloat(valor) / parcela).toFixed(2).toLocaleString('pt-BR', { minimumFractionDigits: 2 })).replace('.', ',')
        return String(parseFloat(valor).toFixed(2).toLocaleString('pt-BR', { minimumFractionDigits: 2 })).replace('.', ',')
      }
    }
  }
}
