/** @Desligados */
/*
  {
    banco: '041 - Banrisul',
    mask: '##.######.#-#',
    hint: '35.123456.1-2',
    rule: /^([0-9]{2})[.]?([0-9]{6})[.]?([0-9]{1})-?([0-9]{1})$/i
  }
*/
/** @Desligados */

const codigo = {
  b104: [
    '001',
    '002',
    '003',
    '006',
    '007',
    '013',
    '022',
    '023',
    '028',
    '032',
    '034',
    '037',
    '043',
    '131'
  ]
}

const bancos = [
  {
    banco: '001 - Banco do Brasil',
    mask: '#######-N',
    hint: '1234567-X',
    rule: /^([0-9]{7})[-]?([0-9]|[X]{1})$/i
  },
  {
    banco: '033 - Santander',
    mask: '########-#',
    hint: '12345678-1',
    rule: /^([0-9]{8})[-]?([0-9]{1})$/i
  },
  {
    banco: '104 - Caixa Eco. Federal',
    mask: '###########-#',
    hint: '00112345678-1',
    rule: new RegExp(`^(${codigo.b104.join('|')})([0-9]{8})[-]?([0-9]{1})$`, 'i')
  },
  {
    banco: '237 - Bradesco',
    mask: '#######-N',
    hint: '1234567-P',
    rule: /^([0-9]{7})[-]?([0-9]|[P]{1})$/i
  },
  {
    banco: '341 - Itau',
    mask: '#####-#',
    hint: '12345-1',
    rule: /^([0-9]{5})[-]?([0-9]{1})$/i
  }
]

export {
  bancos
}
