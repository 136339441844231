<template>
  <v-layout
    fill-height
    column
  >
    <v-flex>
      <v-toolbar
        color="white"
        tabs
        flat
      >
        <v-flex
          xs11
        >
          <v-tabs
            v-model="pacote"
            slider-color="brand-principal"
            grow
            fixed-tabs
            centered
          >
            <v-tab
              v-for="head in splitHead()"
              :key="`head-tab-${head}-categoria`"
            >
              {{ head }}
            </v-tab>
          </v-tabs>
        </v-flex>

        <v-flex
          lg1
          class="text-xs-center"
        >
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-flex>

        <template
          v-slot:extension
        >
          <v-flex
            v-if="$vuetify.breakpoint.mdAndDown"
            class="text-xs-center"
          >
            <v-flex
              v-if="pacote === 0"
            >
              <h5><span class="red--text">30 Dias</span> - R$ 18,00</h5>
            </v-flex>

            <v-flex
              v-if="pacote === 1"
            >
              <h5><span class="red--text">15 Dias</span> - R$ 36,00<v-spacer /><span class="red--text">30 Dias</span> - R$ 58,00</h5>
            </v-flex>

            <v-flex
              v-if="pacote === 2"
            >
              <h5><span class="red--text">15 Dias</span> - R$ 55,00<v-spacer /><span class="red--text">30 Dias</span> - R$ 86,00</h5>
            </v-flex>

            <v-flex
              v-if="pacote === 3"
            >
              <h5><span class="red--text">15 Dias</span> - R$ 80,00<v-spacer /><span class="red--text">30 Dias</span> - R$ 109,00</h5>
            </v-flex>
          </v-flex>

          <v-flex
            v-if="$vuetify.breakpoint.lgAndUp"
            xs11
          >
            <v-tabs
              v-model="pacote"
              slider-color="transparent"
              grow
              fixed-tabs
              centered
            >
              <v-tab>
                <h5
                  class="grey--text"
                >
                  <span :class="`${pacote === 0 && 'red'}--text`">30 Dias</span> - R$ 18,00
                </h5>
              </v-tab>

              <v-tab>
                <h5
                  class="grey--text"
                >
                  <span :class="`${pacote === 1 && 'red'}--text`">15 Dias</span> - R$ 36,00<v-spacer /><span :class="`${pacote === 1 && 'red'}--text`">30 Dias</span> - R$ 58,00
                </h5>
              </v-tab>

              <v-tab>
                <h5
                  class="grey--text"
                >
                  <span :class="`${pacote === 2 && 'red'}--text`">15 Dias</span> - R$ 55,00<v-spacer /><span :class="`${pacote === 2 && 'red'}--text`">30 Dias</span> - R$ 86,00
                </h5>
              </v-tab>

              <v-tab>
                <h5
                  class="grey--text"
                >
                  <span :class="`${pacote === 3 && 'red'}--text`">15 Dias</span> - R$ 80,00<v-spacer /><span :class="`${pacote === 3 && 'red'}--text`">30 Dias</span> - R$ 109,00
                </h5>
              </v-tab>
            </v-tabs>
          </v-flex>
        </template>
      </v-toolbar>
    </v-flex>

    <v-tabs-items
      v-model="pacote"
      :style="`${$vuetify.breakpoint.lgAndUp && 'overflow-y: auto'}`"
    >
      <v-tab-item
        v-for="tabItem in splitHead()"
        :key="`tab-item-${tabItem}`"
        class="min-height-440"
        touchless
      >
        <v-layout
          v-if="tabItem === splitHead()[0]"
          wrap
        >
          <v-flex
            v-for="next in images.filter(o => o.pacote === tabItem)"
            :key="`${next.pacote}-${next.categoria}-${next.index}`"
            xs4
            :pa-4="$vuetify.breakpoint.lgAndUp"
            :py-4="$vuetify.breakpoint.mdAndDown"
          >
            <v-img
              :src="next.src"
              contain
              :width="$vuetify.breakpoint.mdAndDown ? 96 : 128"
              class="mx-auto"
            />
          </v-flex>
        </v-layout>

        <v-layout
          v-if="tabItem !== splitHead()[0]"
          column
          text-xs-center
        >
          <v-flex
            :class="`grey lighten-3 text-uppercase py-2 card-shadow mb-2 font-weight-bold`"
            xs12
            v-html="splitCategoria('all')[0]"
          />

          <v-flex
            xs2
            mb-2
          >
            <v-layout
              wrap
              class="text-xs-center align-center fill-height align-content-center"
            >
              <v-flex
                v-for="next in images.filter(o => o.pacote === splitHead()[0])"
                :key="`${next.pacote}-${next.categoria}-${next.index}`"
                :pa-2="$vuetify.breakpoint.lgAndUp"
                :py-4="$vuetify.breakpoint.mdAndDown"
              >
                <v-img
                  :src="next.src"
                  contain
                  width="32"
                  class="mx-auto"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="tabItem !== splitHead()[0] && $vuetify.breakpoint.lgAndUp"
          text-xs-center
        >
          <v-flex
            v-for="(categoria) in splitCategoria('all')"
            v-show="categoria !== splitCategoria('all')[0]"
            :key="`categoria-title-${categoria}`"
            :class="`grey lighten-3 text-uppercase py-2 card-shadow mb-2 font-weight-bold ${$vuetify.breakpoint.mdAndDown && 'mt-5'}`"
            xs2
            v-html="categoria"
          />
        </v-layout>

        <v-layout
          v-if="tabItem === splitHead()[1]"
          :class="`${$vuetify.breakpoint.lgAndUp && 'height-140'}`"
          :column="$vuetify.breakpoint.mdAndDown"
        >
          <v-flex
            v-for="categoria in splitCategoria('all')"
            v-show="categoria !== splitCategoria('all')[0]"
            :key="`categoria-all-${categoria}`"
            xs2
            mb-2
          >
            <v-flex
              v-if="$vuetify.breakpoint.mdAndDown"
              :class="`grey lighten-3 text-uppercase py-2 card-shadow mb-2 font-weight-bold text-xs-center`"
              xs12
              v-html="categoria"
            />

            <v-layout
              wrap
              class="text-xs-center align-center fill-height align-content-center elevation-1"
            >
              <v-flex
                v-for="next in images.filter(o => o.pacote === splitHead()[1])"
                v-show="next.categoria === categoria"
                :key="`${next.pacote}-${next.categoria}-${next.index}`"
                :pa-2="$vuetify.breakpoint.lgAndUp"
                :py-4="$vuetify.breakpoint.mdAndDown"
              >
                <v-img
                  :src="next.src"
                  contain
                  :width="$vuetify.breakpoint.mdAndDown ? 96 : 32"
                  class="mx-auto"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="tabItem === splitHead()[2]"
          :class="`${$vuetify.breakpoint.lgAndUp && 'height-180'}`"
          :column="$vuetify.breakpoint.mdAndDown"
        >
          <v-flex
            v-for="(categoria, i) in splitCategoria('all')"
            v-show="categoria !== splitCategoria('all')[0]"
            :key="`categoria-all-${categoria}`"
            xs2
            mb-2
          >
            <v-flex
              v-if="$vuetify.breakpoint.mdAndDown"
              :class="`grey lighten-3 text-uppercase py-2 card-shadow mb-2 font-weight-bold text-xs-center ${i >= 2 && 'mt-5'}`"
              xs12
              v-html="categoria"
            />

            <v-layout
              v-for="categoriadois in 2"
              :key="`categoria-dois-${categoriadois}`"
              wrap
              class="text-xs-center align-center fill-height align-content-center elevation-1"
            >
              <v-flex
                v-for="next in images.filter(o => o.pacote === splitHead()[categoriadois])"
                v-show="next.categoria === categoria"
                :key="`${next.pacote}-${next.categoria}-${next.index}`"
                :pa-2="$vuetify.breakpoint.lgAndUp"
                :py-4="$vuetify.breakpoint.mdAndDown"
              >
                <v-img
                  :src="next.src"
                  contain
                  :width="$vuetify.breakpoint.mdAndDown ? 96 : 32"
                  class="mx-auto"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          v-if="tabItem === splitHead()[3]"
          :class="`${$vuetify.breakpoint.lgAndUp && 'height-180'}`"
          :column="$vuetify.breakpoint.mdAndDown"
        >
          <v-flex
            v-for="(categoria, i) in splitCategoria('all')"
            v-show="categoria !== splitCategoria('all')[0]"
            :key="`categoria-all-${categoria}`"
            xs2
            mb-2
          >
            <v-flex
              v-if="$vuetify.breakpoint.mdAndDown"
              :class="`grey lighten-3 text-uppercase py-2 card-shadow mb-2 font-weight-bold text-xs-center  ${i >= 2 && 'mt-5'}`"
              xs12
              v-html="categoria"
            />

            <v-layout
              v-for="categoriatres in 3"
              :key="`categoria-tres-${categoriatres}`"
              wrap
              class="text-xs-center align-center fill-height align-content-center elevation-1"
            >
              <v-flex
                v-for="next in images.filter(o => o.pacote === splitHead()[categoriatres])"
                v-show="next.categoria === categoria"
                :key="`${next.pacote}-${next.categoria}-${next.index}`"
                :pa-2="$vuetify.breakpoint.lgAndUp"
                :py-4="$vuetify.breakpoint.mdAndDown"
              >
                <v-img
                  :src="next.src"
                  contain
                  :width="$vuetify.breakpoint.mdAndDown ? 96 : 32"
                  class="mx-auto"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs-items>
  </v-layout>
</template>

<script>
  export default {
    data: function () {
      return {
        pacote: undefined,
        images: []
      }
    },

    mounted () {
      this.listAll()
    },

    methods: {
      splitCategoria (tab) {
        if (tab === 'all') return [...new Set((this.images.filter(o => o.pacote)).map(o => o.categoria))]
        return [...new Set((this.images.filter(o => o.pacote === tab)).map(o => o.categoria))]
      },

      splitHead () {
        return [...new Set(this.images.map(o => o.pacote))]
      },

      listAll () {
        this.importAll(require.context('@/assets/images/faq/canaisalacarte/', true, /\.png$/))
      },

      importAll (r) {
        function splitKey (key, i) {
          return key.replace(/.png|.\//g, '').split('_')[i]
        }

        r.keys().forEach(key => (this.images.push({
          src: r(key),
          pacote: splitKey(key, 0),
          categoria: splitKey(key, 1),
          index: splitKey(key, 2)
        })))
      }
    }
  }
</script>
