<template>
  <v-card
    v-if="pagamento.status"
    flat
  >
    <v-spacer
      class="height-16"
    />

    <v-layout
      :class="`max-width-${$vuetify.breakpoint.mdAndDown ? '720 column' : '1024'} mx-auto`"
    >
      <v-layout
        :class="`max-width-${$vuetify.breakpoint.mdAndDown ? '720' : '1024'} flat`"
        column
        hidden-lg-and-up
      >
        <v-flex
          mx-auto
        >
          <h3
            class="text-uppercase"
            style="font-size: 16px !important; font-weight: 700 !important"
          >
            Produto Selecionado
          </h3>
        </v-flex>

        <v-divider
          class="my-3"
        />

        <v-flex
          mx-auto
        >
          <h4
            style="font-size: 16px !important; font-weight: 700 !important"
          >
            {{ getProduct(payload.pedido.produto[0].id, 'title').nome }}
          </h4>

          <h5
            style="font-size: 14px !important; font-weight: 400 !important"
          >
            Pré-pago
          </h5>
        </v-flex>

        <v-flex
          mx-auto
        >
          <v-img
            :src="getProduct(payload.pedido.produto[0].id, 'cover')[0]"
            width="164"
            contain
          />
        </v-flex>
      </v-layout>

      <v-spacer
        class="height-46"
      />

      <v-flex
        xs6
        :style="`${$vuetify.breakpoint.lgAndUp && 'overflow-y: auto; height: calc(100vh - 80px)'}`"
      >
        <v-stepper
          v-model="formulario.active"
          :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 375 : 1024} mx-auto fix-stepper flat`"
          vertical
          non-linear
        >
          <v-stepper-step
            editable
            step="1"
            class="py-2 px-3 hidden-lg-and-up"
          >
            <h3
              class="font-weight-bold text-uppercase"
            >
              Dados Pessoais
            </h3>
          </v-stepper-step>

          <v-stepper-content
            step="1"
            class="pa-0 ma-0"
          >
            <v-spacer
              class="height-22"
            />

            <v-card
              flat
              tile
            >
              <v-layout
                column
                mx-3
              >
                <v-flex>
                  <v-layout
                    column
                  >
                    <v-flex>
                      <v-text-field
                        id="ipt_gtm_modal_event_consumidor_cpf"
                        ref="ipt_gtm_modal_event_consumidor_cpf"
                        v-model="consumidor.cpf"
                        name="ipt_gtm_modal_event_consumidor_cpf"
                        label="CPF"
                        color="red"
                        readonly
                        required
                        :mask="formulario.validate.mask.cpf"

                        class="fix-form-input gtm-element-event height-80"
                        tabindex="0"
                      />
                    </v-flex>

                    <v-flex>
                      <v-text-field
                        id="ipt_gtm_modal_event_consumidor_nome"
                        ref="ipt_gtm_modal_event_consumidor_nome"
                        v-model="consumidor.nome"
                        name="ipt_gtm_modal_event_consumidor_nome"
                        label="Nome completo"
                        color="red"
                        readonly
                        required

                        class="fix-form-input gtm-element-event height-80"
                        tabindex="0"
                      />
                    </v-flex>

                    <v-flex>
                      <v-text-field
                        id="ipt_gtm_modal_event_consumidor_email"
                        ref="ipt_gtm_modal_event_consumidor_email"
                        v-model="consumidor.email"
                        name="ipt_gtm_modal_event_consumidor_email"
                        label="E-mail"
                        color="red"
                        readonly
                        required

                        class="fix-form-input gtm-element-event height-80"
                        tabindex="0"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-card-actions
                class="mx-2"
              >
                <v-layout
                  :column="$vuetify.breakpoint.mdAndDown"
                >
                  <v-flex
                    xs4
                  />

                  <v-spacer
                    class="mx-4"
                  />

                  <v-flex
                    order-lg2
                    xs8
                  >
                    <v-btn
                      :id="getName({ source: 'gtmid' })"
                      class="flat gtm_button font-size-18 font-weight-bold"
                      color="warning lighten-2 black--text"
                      block
                      large
                      round
                      tabindex="0"
                      @click="formulario.active = 2"
                    >
                      Avançar
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-spacer
            class="height-1"
          />

          <v-stepper-step
            editable
            step="2"
            class="py-2 px-3 hidden-lg-and-up"
          >
            <h3
              class="font-weight-bold text-uppercase"
            >
              Pagamento
            </h3>
          </v-stepper-step>

          <v-stepper-content
            step="2"
            class="pa-0 ma-0"
          >
            <v-spacer
              class="height-22"
            />

            <v-card
              flat
              tile
            >
              <v-layout
                column
                mx-3
              >
                <h4
                  v-show="(new RegExp(items.pedido.pagamento.formaPagamento[0].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  style="font-size: 18px !important; font-weight: 700 !important"
                >
                  Cartões aceitos:
                </h4>

                <!--
                <h4
                  v-show="(new RegExp(items.pedido.pagamento.formaPagamento[1].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  style="font-size: 18px !important; font-weight: 700 !important"
                >
                  Pagamento via Boleto:
                </h4>
                -->

                <h4
                  v-show="(new RegExp(items.pedido.pagamento.formaPagamento[1].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  style="font-size: 18px !important; font-weight: 700 !important"
                >
                  Pagamento via PIX:
                </h4>

                <v-spacer
                  class="height-22"
                />

                <v-select
                  id="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                  ref="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                  v-model="pedido.pagamento.formaPagamento"
                  name="ipt_gtm_modal_event_pedido_pagamento_formaPagamento"
                  :items="items.pedido.pagamento.formaPagamento"
                  :rules="[ InputRules('required') ]"
                  :color="GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'valid') ? 'success' : 'brand-principal'"
                  :success="GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'valid')"
                  item-value="id"
                  class="fix-form-input gtm-element-event height-90"
                  data-test-id="formaPagamentoCliente"
                  outline
                >
                  <template
                    v-slot:selection="{ item }"
                  >
                    <div
                      class="text-xs-center width-per-100 cursor-pointer"
                      data-test-id="cartaoCredito"
                    >
                      <span
                        class="position-relative left-7"
                        v-text="item.nome"
                      />
                    </div>
                  </template>

                  <template
                    v-slot:item="{ item }"
                  >
                    <div
                      class="text-xs-center width-per-100 cursor-pointer"
                      data-test-id="pix"
                    >
                      <span
                        v-text="item.nome"
                      />
                    </div>
                  </template>
                </v-select>

                <v-spacer
                  class="height-22"
                />

                <v-layout
                  v-show="(new RegExp(items.pedido.pagamento.formaPagamento[0].id)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  column
                >
                  <v-layout
                    row
                  >
                    <v-img
                      :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-visa.png?lazy`)"
                      contain
                      height="32"
                      class="mx-2"
                    />

                    <v-img
                      :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-mastercard.png?lazy`)"
                      contain
                      height="32"
                      class="mx-2"
                    />

                    <v-img
                      :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-elo.png?lazy`)"
                      contain
                      height="32"
                      class="mx-2"
                    />

                    <v-img
                      :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-hipercard.png?lazy`)"
                      contain
                      height="32"
                      class="mx-2"
                    />
                  </v-layout>

                  <v-spacer
                    class="height-22"
                  />

                  <!-- :items="Array.from(Array(10), (e, i) => i + 1)" -->

                  <v-layout
                    row
                  >
                    <v-flex
                      xs6
                    >
                      <h5
                        style="font-size: 14px !important; font-weight: 400 !important"
                      >
                        Valor total:
                      </h5>

                      <v-spacer
                        class="height-8"
                      />

                      <v-layout
                        class="font-size-18 font-weight-medium"
                      >
                        <v-scroll-y-transition>
                          <v-flex
                            v-show="pedido.pagamento.cartaoDeCredito.parcelas >= 2"
                            xs4
                            lg3
                          >
                            <span
                              v-text="`${padLeft(String(pedido.pagamento.cartaoDeCredito.parcelas), '00')}x de `"
                            />
                          </v-flex>
                        </v-scroll-y-transition>

                        <v-scroll-x-transition>
                          <v-flex
                            xs7
                          >
                            {{ currency({ prefix: 'BRL', valor: payload.pedido.valorTotal, parcela: pedido.pagamento.cartaoDeCredito.parcelas }) }}
                          </v-flex>
                        </v-scroll-x-transition>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs6
                    >
                      <h5
                        style="font-size: 14px !important; font-weight: 400 !important"
                      >
                        Parcelas:
                      </h5>

                      <v-layout
                        class="v-card border-radius-4 py-2  max-width-100"
                        style="border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)"
                        text-xs-center
                      >
                        <v-flex>
                          <v-btn
                            flat
                            small
                            icon
                            class="pa-0 ma-0"
                            @click="pedido.pagamento.cartaoDeCredito.parcelas < 10 && pedido.pagamento.cartaoDeCredito.parcelas++"
                          >
                            <i
                              class="mdn-Icon-mais mdn-Icon--sm blue--text cursor-pointer"
                            />
                          </v-btn>
                        </v-flex>

                        <v-flex>
                          <v-btn
                            flat
                            small
                            icon
                            tabindex="-1"
                            class="pa-0 ma-0"
                          >
                            {{ padLeft(String(pedido.pagamento.cartaoDeCredito.parcelas), '00') }}
                          </v-btn>
                        </v-flex>

                        <v-flex>
                          <v-btn
                            flat
                            small
                            icon
                            class="pa-0 ma-0"
                            @click="pedido.pagamento.cartaoDeCredito.parcelas > 1 && pedido.pagamento.cartaoDeCredito.parcelas--"
                          >
                            <i
                              class="mdn-Icon-menos mdn-Icon--sm blue--text cursor-pointer"
                            />
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-layout>

                <v-layout
                  v-show="(new RegExp(`(${items.pedido.pagamento.formaPagamento[1].id})`)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  column
                >
                  1x de {{ currency({ prefix: 'BRL', valor: payload.pedido.valorTotal }) }}
                </v-layout>

                <!--

                <v-layout
                  v-show="(new RegExp(`(${items.pedido.pagamento.formaPagamento[1].id}|${items.pedido.pagamento.formaPagamento[2].id})`)).test(GetProp('ipt_gtm_modal_event_pedido_pagamento_formaPagamento', 'value'))"
                  column
                >
                  1x de {{ currency({ prefix: 'BRL', valor: payload.pedido.valorTotal }) }}
                </v-layout>

                -->

                <v-spacer
                  class="height-22"
                />

                <v-checkbox
                  id="ipt_gtm_modal_event_contrato_servico"
                  ref="ipt_gtm_modal_event_contrato_servico"
                  v-model="pedido.ciente.contrato.servico"
                  color="success"
                  :rules="[ InputRules('required') ]"
                  required

                  class="gtm-element-event pa-0 ma-0"
                  tabindex="0"

                  data-gtm-event-category="claro:meu-plano-claro"
                  data-gtm-event-action="preencheu"
                  data-gtm-event-label="contrato-servico"

                  @change="dataLayer('contrato-servico')"
                >
                  <template
                    v-slot:label
                  >
                    <v-layout
                      column
                    >
                      <h5
                        class="font-weight-regular grey-dark--text font-size-10"
                      >
                        <span>
                          Declaro que conheço e concordo com as
                          <a
                            :href="getDocument('termo-adesao')"
                            target="_blank"
                            v-html="'Termo de Adesão da Claro TV Pré.'"
                          />
                          Estou ciente que para adequado funcionamento é necessária a contratação de um técnico instalador e aquisição de uma antena compatível (caso ainda não tenha uma).
                          Instruções sobre contratação de técnico
                          <a
                            @click="openFaq('contrate-tecnico')"
                            v-html="'clique aqui'"
                          />.
                          Para acesso a programação o produto exige que seja realizada uma recarga.
                          Para mais informações sobre recargas
                          <a
                            @click="openFaq('faca-recarga')"
                            v-html="'clique aqui'"
                          />.
                        </span>
                      </h5>
                    </v-layout>
                  </template>
                </v-checkbox>
              </v-layout>

              <v-card-actions
                :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 1024} mx-auto`"
              >
                <v-layout
                  :column="$vuetify.breakpoint.mdAndDown"
                >
                  <v-flex
                    :order-xs2="$vuetify.breakpoint.mdAndDown"
                    xs4
                  />

                  <v-spacer
                    class="mx-4"
                  />

                  <v-flex
                    order-lg2
                    xs8
                  >
                    <v-btn
                      :id="getName({ source: 'gtmid' })"
                      :class="`${$vuetify.breakpoint.mdAndDown && 'mx-auto mb-3'} flat gtm_button font-size-18 font-weight-bold`"
                      :color="GetProp('ipt_gtm_modal_event_contrato_servico', 'valid') ? 'warning lighten-2 black--text' : 'grey lighten-3 cursor-disabled grey--text text--lighten-1'"
                      block
                      large
                      round
                      :disabled="!GetProp('ipt_gtm_modal_event_contrato_servico', 'valid')"
                      tabindex="0"
                      @click="dataLayer('payload')"
                    >
                      Realizar pagamento
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-flex>

      <v-flex
        hidden-md-and-down
        xs6
        ml-5
      >
        <v-card
          class="fill-height border-radius-6 max-height-480"
          style="border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)"
        >
          <v-layout
            fill-height
            column
            style="border-left: 8px solid #D52B1E"
            class="pl-4"
          >
            <v-flex
              xs1
              mt-3
            >
              <h2
                class="text-uppercase"
                style="font-size: 21px !important; font-weight: 700 !important"
              >
                {{ getProduct(payload.pedido.produto[0].id, 'title').nome }}
              </h2>
            </v-flex>

            <v-flex
              xs2
            >
              <v-img
                :src="getProduct(payload.pedido.produto[0].id, 'cover')[0]"
                width="164"
                contain
              />
            </v-flex>

            <v-divider
              class="my-3 mr-5"
            />

            <v-flex
              mr-5
            >
              <v-layout
                column
              >
                <v-flex>
                  <v-text-field
                    id="ipt_gtm_modal_event_consumidor_cpf"
                    ref="ipt_gtm_modal_event_consumidor_cpf"
                    v-model="consumidor.cpf"
                    name="ipt_gtm_modal_event_consumidor_cpf"
                    label="CPF"
                    color="red"
                    readonly
                    required
                    :mask="formulario.validate.mask.cpf"

                    class="fix-form-input gtm-element-event height-80"
                    tabindex="0"
                  />
                </v-flex>

                <v-flex>
                  <v-text-field
                    id="ipt_gtm_modal_event_consumidor_nome"
                    ref="ipt_gtm_modal_event_consumidor_nome"
                    v-model="consumidor.nome"
                    name="ipt_gtm_modal_event_consumidor_nome"
                    label="Nome completo"
                    color="red"
                    readonly
                    required

                    class="fix-form-input gtm-element-event height-80"
                    tabindex="0"
                  />
                </v-flex>

                <v-flex>
                  <v-text-field
                    id="ipt_gtm_modal_event_consumidor_email"
                    ref="ipt_gtm_modal_event_consumidor_email"
                    v-model="consumidor.email"
                    name="ipt_gtm_modal_event_consumidor_email"
                    label="E-mail"
                    color="red"
                    readonly
                    required

                    class="fix-form-input gtm-element-event height-80"
                    tabindex="0"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<style lang="stylus">
  .fix-stepper
    .v-stepper__step
      background-color #f6f6f6 !important
      .v-stepper__step__step
        float right
        position absolute
        right 0
        background-color transparent !important
        color transparent
        &::after
          content '⯆'
          color grey !important
          position absolute
    .v-stepper__content
      border none !important

  .fix-form-input
    input
      margin-top: 12px !important
    .v-select__selections
      padding-top: 0px !important
    .v-select__slot
      cursor: pointer !important

  .gtm_button
    div.v-btn__content
      height 0px !important
</style>

<script>
  import { mapState, mapActions } from 'vuex'

  import axios from 'axios'

  /** @Mixins */
  import pad from '@/plugins/mixins/pad'
  import mask from '@/plugins/mixins/mask'
  import rule from '@/plugins/mixins/rule'
  import lista from '@/plugins/mixins/lista'
  import prop from '@/plugins/mixins/prop'
  import hint from '@/plugins/mixins/hint'
  import product from '@/plugins/mixins/product'
  /** @Mixins */

  export default {
    mixins: [
      pad,
      mask,
      rule,
      lista,
      prop,
      hint,
      product
    ],

    props: {
      validateForm: {
        type: Boolean,
        default: false
      },

      disableNext: {
        type: Boolean,
        default: false
      },

      get: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        input: undefined,
        pagamento: {
          status: true
        },

        formulario: {
          active: 2,

          steps: [
            {
              id: 1,
              nome: 'Dados Pessoais'
            },
            {
              id: 2,
              nome: 'Pagamento'
            }
          ],

          validate: {
            step: {
              dadosPessoais: false
            },

            consumidor: {
              nome: 'false',
              filiacao: 'false',
              nascimento: 'false',
              identidade: {
                numero: 'false',
                orgao: 'false',
                uf: 'false'
              }
            },

            endereco: {
              cep: 'false',
              logradouro: 'false',
              cidade: 'false',
              bairro: 'false',
              uf: 'false',
              numero: 'false'
            },

            mask: {
              cpf: '###.***.***-**',
              data: '##/#*/****',
              cep: '###**-***',
              uf: 'AA',
              identidade: {
                numero: '###*****************'
              }
            }
          }
        },

        readonly: {
          consumidor: {
            nome: false,
            endereco: {
              logradouro: false,
              bairro: false,
              localidade: false,
              uf: false
            }
          }
        },

        consumidor: {
          cpf: undefined,
          nome: undefined,
          email: undefined,
          nascimento: undefined,
          nascimentoPicker: false,
          filiacao: undefined,
          identidade: {
            numero: undefined,
            orgao: undefined,
            uf: undefined
          },

          endereco: {
            cep: undefined,
            logradouro: undefined,
            bairro: undefined,
            numero: undefined,
            complemento: undefined,
            cidade: undefined,
            uf: undefined,
            referencia: undefined
          }
        },

        items: {
          pedido: {
            pagamento: {
              formaPagamento: [
                {
                  nome: 'Cartão de Crédito',
                  id: 'cartaodecredito'
                },
                /* {
                  nome: 'Boleto Bancário',
                  id: 'boletobancario'
                }, */
                {
                  nome: 'PIX',
                  id: 'pix'
                }
              ]
            }
          }
        },

        pedido: {
          produto: {
            quantidade: 1
          },
          pagamento: {
            formaPagamento: 'cartaodecredito',
            cartaoDeCredito: {
              parcelas: 10
            }
          },
          ciente: {
            instalacao: undefined,
            recarga: undefined,
            contrato: {
              servico: undefined
            }
          },
          postagem: {
            servicoEntrega: 'sedex',
            valorEntrega: 15.00
          },
          valorTotal: undefined
        }
      }
    },

    computed: {
      ...mapState('Modules', [ 'env', 'formData' ]),
      ...mapState('Payload', [ 'payload' ])
    },

    watch: {
      'consumidor.nome' () {
        this.SaveInput({
          input: true,
          name: 'consumidorNome',
          value: this.consumidor.nome
        })
      },

      'consumidor.nascimento' () {
        this.SaveInput({
          input: true,
          name: 'consumidorNascimento',
          value: this.consumidor.nascimento
        })
      },

      'consumidor.endereco.cep' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoCep',
          value: this.consumidor.endereco.cep
        })
      },

      'consumidor.endereco.logradouro' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoLogradouro',
          value: this.consumidor.endereco.logradouro
        })
      },

      'consumidor.endereco.cidade' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoCidade',
          value: this.consumidor.endereco.cidade
        })
      },

      'consumidor.endereco.uf' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoUf',
          value: this.consumidor.endereco.uf
        })
      },

      'consumidor.endereco.bairro' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoBairro',
          value: this.consumidor.endereco.bairro
        })
      },

      'consumidor.endereco.numero' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoNumero',
          value: this.consumidor.endereco.numero
        })
      },

      'consumidor.endereco.complemento' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoComplemento',
          value: this.consumidor.endereco.complemento
        })
      },

      'consumidor.endereco.referencia' () {
        this.SaveInput({
          input: true,
          name: 'consumidorEnderecoReferencia',
          value: this.consumidor.endereco.referencia
        })
      },

      'pedido.postagem.servicoEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemServicoEntrega',
          value: this.pedido.postagem.servicoEntrega
        })
      },

      'pedido.postagem.valorEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemValorEntrega',
          value: this.pedido.postagem.valorEntrega
        })
      },

      'pedido.valorTotal' () {
        this.SaveInput({
          input: true,
          name: 'pedidoValorTotal',
          value: this.pedido.valorTotal
        })
      },

      'pedido.pagamento.formaPagamento' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPagamentoFormaPagamento',
          value: this.pedido.pagamento.formaPagamento
        })
      },

      'pedido.pagamento.cartaoDeCredito.parcelas' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPagamentoCartaoDeCreditoParcelas',
          value: this.pedido.pagamento.cartaoDeCredito.parcelas
        })
      },

      'pedido.produto.quantidade' () {
        this.SaveInput({
          input: true,
          name: 'pedidoProdutoQuantidade',
          value: this.pedido.produto.quantidade
        })
      },

      'pedido.ciente.instalacao' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteInstalacao',
          value: this.pedido.ciente.instalacao
        })
      },

      'pedido.ciente.recarga' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteRecarga',
          value: this.pedido.ciente.recarga
        })
      },

      'pedido.ciente.contrato.servico' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteContratoServico',
          value: this.pedido.ciente.contrato.servico
        })
      }
    },

    created () {
      if ((/^(aprovado|pendente)$/i).test(this.payload.pedido.pagamento.status)) {
        this.$emit('actionButton', {
          ...this.payload,
          resumo: this.payload.pedido.pagamento.formaPagamento
        })
        this.pagamento.status = false
      }
    },

    mounted () {
      if (this.payload.consumidor) {
        if (this.payload.consumidor.cpf) this.consumidor.cpf = this.payload.consumidor.cpf
        if (this.payload.consumidor.nome) this.consumidor.nome = this.payload.consumidor.nome
        if (this.payload.consumidor.email) this.consumidor.email = this.payload.consumidor.email
        if (this.payload.consumidor.nascimento) this.consumidor.nascimento = this.payload.consumidor.nascimento

        if (this.payload.consumidor.endereco) {
          if (this.payload.consumidor.endereco.cep) this.consumidor.endereco.cep = this.payload.consumidor.endereco.cep
          if (this.payload.consumidor.endereco.logradouro) this.consumidor.endereco.logradouro = this.payload.consumidor.endereco.logradouro
          if (this.payload.consumidor.endereco.cidade) this.consumidor.endereco.cidade = this.payload.consumidor.endereco.cidade
          if (this.payload.consumidor.endereco.uf) this.consumidor.endereco.uf = this.payload.consumidor.endereco.uf
          if (this.payload.consumidor.endereco.bairro) this.consumidor.endereco.bairro = this.payload.consumidor.endereco.bairro
          if (this.payload.consumidor.endereco.numero) this.consumidor.endereco.numero = this.payload.consumidor.endereco.numero
          if (this.payload.consumidor.endereco.complemento) this.consumidor.endereco.complemento = this.payload.consumidor.endereco.complemento
          if (this.payload.consumidor.endereco.referencia) this.consumidor.endereco.referencia = this.payload.consumidor.endereco.referencia
        }
      }

      if (this.payload.pedido) {
        if (this.payload.pedido.postagem) {
          if (this.payload.pedido.postagem.servicoEntrega) this.pedido.postagem.servicoEntrega = this.payload.pedido.postagem.servicoEntrega
          if (this.payload.pedido.postagem.valorEntrega) this.pedido.postagem.valorEntrega = this.payload.pedido.postagem.valorEntrega
        }

        if (this.payload.pedido.pagamento) {
          if (this.payload.pedido.pagamento.formaPagamento) {
            this.pedido.pagamento.formaPagamento = this.payload.pedido.pagamento.formaPagamento
          } else {
            this.SaveInput({
              input: true,
              name: 'pedidoPagamentoFormaPagamento',
              value: this.pedido.pagamento.formaPagamento
            })
          }

          if (this.payload.pedido.pagamento.cartaoDeCredito.parcelas) {
            this.pedido.pagamento.cartaoDeCredito.parcelas = this.payload.pedido.pagamento.cartaoDeCredito.parcelas
          } else {
            this.SaveInput({
              input: true,
              name: 'pedidoPagamentoCartaoDeCreditoParcelas',
              value: this.pedido.pagamento.cartaoDeCredito.parcelas
            })
          }
        }

        if (this.payload.pedido.produto[0]) {
          if (this.payload.pedido.produto[0].quantidade) this.pedido.produto.quantidade = this.payload.pedido.produto[0].quantidade
        }

        if (this.payload.pedido.ciente) {
          if (this.payload.pedido.ciente.instalacao) this.pedido.ciente.instalacao = this.payload.pedido.ciente.instalacao
          if (this.payload.pedido.ciente.recarga) this.pedido.ciente.recarga = this.payload.pedido.ciente.recarga
        }
      }

      this.dataLayer()
    },

    methods: {
      ...mapActions('Payload', [ 'SaveInput' ]),
      ...mapActions('Dialogs', [ 'openFaq' ]),

      async getAddress (pattern, value) {
        let status = false
        if (pattern.test(value)) {
          try {
            const response = await axios.get(
              `https://viacep.com.br/ws/${value}/json`
            )

            if (!Object.prototype.hasOwnProperty.call(response.data, 'erro')) {
              this.readonly.consumidor.endereco.logradouro = !!response.data.logradouro
              this.consumidor.endereco.logradouro = response.data.logradouro

              this.readonly.consumidor.endereco.bairro = !!response.data.bairro
              this.consumidor.endereco.bairro = response.data.bairro

              this.readonly.consumidor.endereco.localidade = !!response.data.localidade
              this.consumidor.endereco.cidade = response.data.localidade

              this.consumidor.endereco.uf = response.data.uf
              this.readonly.consumidor.endereco.uf = !!response.data.uf

              status = true
            }
          } catch (e) {
            status = true
            // throw new Error(e)
          }
        }
        return status
      },

      getTotalValue (value) {
        if (value) this.pedido.valorTotal = value
        return this.pedido.valorTotal
      },

      getDocument (param) {
        if (param === 'termo-adesao') return '/content/documents/contracts/termo-de-adesao-tvpre.pdf'
      },

      getName (param) {
        if (param.source === 'gtmid') {
          return this.validateForm ? this.get.gtm : 'disabled'
        }
      },

      consumidornascimentosave (date) {
        let [year, month, day] = date.split('-')
        this.$refs.consumidornascimento.save(
          (this.consumidor.nascimento = `${day}${month}${year}`)
        )
      },

      linkCorreios () {
        this.dataLayer('buscar-cep')
        window.open(
          'https://www.buscacep.correios.com.br/sistemas/buscacep/',
          '_blank'
        )
      },

      dataLayer (param) {
        if (param === 'payload') {
          this.$emit('actionButton', 'contratar')
        }

        if (param === 'voltar') {
          this.$emit('actionButton', 'voltar')
        }

        if (param && this.input !== param) {
          this.input = param

          window.dataLayer.push({
            'event': 'event',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'preencheu',
            'eventLabel': param
          })
        }

        if (!param) {
          window.dataLayer.push({
            'event': 'checkout',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'enhanced-ecommerce',
            'eventLabel': `checkout-${this.formData.openedStep.actual}-etapa-dados-pagamento`,
            'ecommerce': {
              'nonInteraction': '1',
              'ec': 'setAction',
              'checkout': {
                'actionField': {
                  'step': this.formData.openedStep.actual
                },
                'products': [{
                  'name': String(this.getProduct(this.payload.pedido.produto[0].id, 'title').nome).replace(/ /g, '').toLowerCase(),
                  'id': String(this.payload.pedido.produto[0].id).replace(/ /g, ''),
                  'price': String(this.payload.pedido.valorTotal).replace(/,|\./g, '.'),
                  'brand': 'meu-plano-claro',
                  'category': 'tvpre',
                  'quantity': '1'
                }]
              }
            }
          })
        }
      }
    }
  }
</script>
