import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('Payload', ['PayloadGetterGetState'])
  },

  methods: {
    ...mapActions('Payload', ['ActionPayloaGerenciaPontoAdicional']),

    verificaPedidoComPontoAdicional () {
      const pedido = this.PayloadGetterGetState('pedido')
      switch (true) {
        case /^vtxantenareceptorhd$/.test(pedido.produto[0].id):
          return true
        default:
          return false
      }
    },

    verificaQuantidadeAdicionalPedido (quantidade) {
      if (!this.verificaPedidoComPontoAdicional()) {
        this.adicionaQuantidadePonto('pedidoProdutoQuantidade')
        return
      }

      const pedido = this.PayloadGetterGetState('pedido')
      if (pedido.produto.length === 1) this.adicionaPontoAdicional('vtxreceptorhd')
      if (pedido.produto.length >= 2) {
        if (quantidade === 0) {
          this.removePontoAdicional()
          return
        }
        this.adicionaQuantidadePonto('pedidoProdutoAdicionalQuantidade')
      }
    },

    verificaQuantidadePedido () {
      const pedido = this.PayloadGetterGetState('pedido')
      if (!this.verificaPedidoComPontoAdicional()) {
        if (pedido.produto[0].quantidade) this.pedido.produto.quantidade = pedido.produto[0].quantidade
        return this.pedido.produto.quantidade
      }
      if (pedido.produto.length >= 2) return pedido.produto[0].quantidade + pedido.produto[pedido.produto.length - 1].quantidade
      return 1
    },

    adicionaPontoAdicional (produtoId) {
      async function listaProdutos (dispath) {
        return dispath('pedido')
      }

      listaProdutos(this.PayloadGetterGetState).then(response => {
        const decoder = require('@/database/products/tvpre/decoder')
        const pontoAdicional = decoder[produtoId]

        this.ActionPayloaGerenciaPontoAdicional([
          ...response.produto,
          {
            descricao: pontoAdicional.title.nome,
            id: pontoAdicional.plan,
            modelo: pontoAdicional.title.short,
            preco: pontoAdicional.price.default,
            quantidade: 1
          }
        ])
      }).finally(() => { this.pedido.produto.quantidade = 1 })
    },

    adicionaQuantidadePonto (name) {
      this.SaveInput({
        input: true,
        name: name,
        value: this.pedido.produto.quantidade
      })
    },

    removePontoAdicional () {
      async function listaProdutos (dispath) {
        return dispath('pedido')
      }

      listaProdutos(this.PayloadGetterGetState).then(response => {
        this.ActionPayloaGerenciaPontoAdicional(new Array(response.produto[0]))
      }).finally(() => { this.pedido.produto.quantidade = 0 })
    }
  }
}
