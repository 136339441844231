<template>
  <div>
    <function-campaign />

    <function-atendente
      v-if="enable('atendente')"
    />

    <function-popup />
  </div>
</template>

<script>
  import FunctionCampaign from '@/functions/Campaign'
  import FunctionAtendente from '@/functions/Atendente'
  import FunctionPopup from '@/views/popup/Welcome'

  import keyFind from '@/plugins/mixins/keyfind'

  export default {
    components: {
      FunctionCampaign,
      FunctionAtendente,
      FunctionPopup
    },

    mixins: [
      keyFind
    ],

    props: {
      functions: {
        type: Array,
        default: undefined
      }
    },

    methods: {
      enable (search) {
        try {
          if (this.keyFind(this.$route.meta, 'functions')) return this.$route.meta.functions.includes(search)
        } catch {
          return false
        }
      }
    }
  }
</script>
