<template>
  <v-dialog
    ref="dialogDeliveryCepError"
    v-model="dialogDeliveryCepError"
    max-width="400"
    persistent
  >
    <v-card
      color="white"
      class="pa-5"
    >
      <v-card-text>
        <div
          class="pa-0"
          style="color: #000000; font-size: 14px;"
        >
          <strong>Prezado Cliente,</strong>
          <br>
          <br>
          <span>Devido à situação atual no estado do Rio Grande do Sul, o CEP informado encontra-se temporariamente indisponível para entrega. Pedimos gentilmente que nos forneça outro CEP para que possamos prosseguir com o envio.</span>
        </div>
      </v-card-text>
      <v-card-actions
        class="d-flex flex-column mx-auto"
      >
        <v-btn
          style="font-size: 12px;"
          class="pa-0 ma-0 mt-3 font-weight-bold"
          color="red"
          round
          dark
          depressed
          max-width="100%"
          block
          @click="tryOtherCep()"
        >
          Tentar outro CEP
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Component, Prop } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'

  import { MixinControlDialogs } from '@/mixins/dialogs/MixinControlDialogs'

  @Component({})
  export default class ComponentDialogDeliveryCepError extends mixins(
    MixinControlDialogs
  ) {
    @Prop() tryOtherCep
  }
</script>
