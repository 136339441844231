<template>
  <v-dialog
    v-model="watchDialogEvent"
    :content-class="`flat ${$vuetify.breakpoint.mdAndDown && 'white'}`"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="hideOverlay"
    scrollable
    attach
  >
    <div
      v-if="$vuetify.breakpoint.lgAndUp && hideOverlay"
      class="width-per-100 height-per-100 position-fixed top-0 left-0"
      style="background-color: rgba(1,1,1,0.7)"
      @click="closeFaq()"
    />

    <v-card
      :class="`${$vuetify.breakpoint.mdAndDown && 'min-height-600'} max-width-1280 mx-auto`"
      :flat="$vuetify.breakpoint.mdAndDown"
    >
      <v-toolbar
        v-if="faq.component !== 'assista-canais'"
        flat
        :color="`${$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'}`"
        :fixed="$vuetify.breakpoint.mdAndDown"
      >
        <v-spacer />

        <v-icon
          @click="closeFaq()"
        >
          close
        </v-icon>
      </v-toolbar>

      <contrate-tecnico
        v-if="faq.component === 'contrate-tecnico'"
        :class="`${$vuetify.breakpoint.mdAndDown && 'pt-5'}`"
        @close="closeFaq()"
      />

      <faca-recarga
        v-if="faq.component === 'faca-recarga'"
        :class="`${$vuetify.breakpoint.mdAndDown && 'pt-5'}`"
        @close="closeFaq()"
      />

      <planos-precos
        v-if="faq.component === 'planos-precos'"
        :class="`${$vuetify.breakpoint.mdAndDown && 'pt-5'}`"
        @close="closeFaq()"
      />

      <assista-canais
        v-if="faq.component === 'assista-canais'"
        @close="closeFaq()"
      />

      <prazo-entrega
        v-if="faq.component === 'prazo-entrega'"
        @close="closeFaq()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  import ContrateTecnico from '@/components/contents/tvpre/dialogs/faq/ContrateTecnico'
  import FacaRecarga from '@/components/contents/tvpre/dialogs/faq/FacaRecarga'
  import PlanosPrecos from '@/components/contents/tvpre/dialogs/faq/PlanosPrecos'
  import AssistaCanais from '@/components/contents/tvpre/dialogs/faq/AssistaCanais'
  import PrazoEntrega from '@/components/contents/tvpre/dialogs/faq/PrazoEntrega'

  export default {
    components: {
      ContrateTecnico,
      FacaRecarga,
      PlanosPrecos,
      AssistaCanais,
      PrazoEntrega
    },

    props: {
      hideOverlay: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState('Dialogs', [ 'faq' ]),

      watchDialogEvent: {
        get () {
          return this.faq.active
        },
        set () {
          this.closeFaq()
        }
      }
    },

    methods: {
      ...mapActions('Dialogs', [ 'closeFaq' ])
    }
  }
</script>
