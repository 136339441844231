<template>
  <div>
    <v-layout
      v-if="$vuetify.breakpoint.lgAndUp"
      class="mx-1 flat width-400 py-4"
      column
    >
      <v-toolbar
        flat
        color="red"
        height="10"
      />

      <v-flex
        v-for="(next, i) in param[0].item"
        :key="i"
        :class="`${i === 'title' && 'grey lighten-3 font-size-18'} text-uppercase py-4 card-shadow`"
        v-html="next[param[1]]"
      />
    </v-layout>

    <div
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-stepper
        v-model="stepper"
        vertical
        non-linear
        class="flat"
      >
        <div
          v-for="(next, i) in param[0].item"
          :key="i"
        >
          <v-stepper-step
            v-if="i !== 'title'"
            :step="i"
            editable
            :class="`${i !== stepper ? 'elevation-3 brand-principal--text' : 'card-shadow brand-principal white--text'} pb-4 ${i >= 1 && 'pt-4'}`"
            v-html="`
              <h3 class='pl-3 font-weight-medium '>
                Pacote ${next[0]}
              </h3>
              <i class='position-absolute right-12 mdn-Icon-baixo mdn-Icon--lg'></i>
            `"
          />

          <v-stepper-content
            v-if="i !== 'title'"
            :step="i"
            :class="`${i === stepper && 'grey lighten-3'} pa-0 ma-0`"
          >
            <v-layout
              column
              pb-5
              text-xs-center
            >
              <v-layout
                class="white card-shadow mb-3 pa-3"
              >
                <v-flex
                  v-for="(a, b) in param[0].item.title"
                  v-show="b >= 1"
                  :key="b"
                  xs6
                  v-html="a"
                />
              </v-layout>

              <v-layout
                class="white card-shadow mb-3 pa-3"
              >
                <v-flex
                  v-for="(a, b) in param[0].item[next[0].toLowerCase()]"
                  v-show="b >= 1"
                  :key="b"
                  xs6
                  v-html="a"
                />
              </v-layout>
            </v-layout>
          </v-stepper-content>
        </div>
      </v-stepper>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      param: {
        type: Array,
        default: undefined
      }
    },

    data: function () {
      return {
        stepper: 'inicial'
      }
    }
  }
</script>
