<template>
  <v-form
    ref="validateForm"
    v-model="validateForm"
    @submit.prevent
  >
    <v-stepper
      v-model="formData.openedStep.actual"
      class="flat pa-0"
      vertical
    >
      <v-stepper-items
        class="ma-0 pa-0"
      >
        <v-scroll-y-transition>
          <div
            v-if="stepper.active"
          >
            <v-stepper-content
              class="pa-0 ma-0 border-none"
              :step="formData.openedStep.first"
            >
              <vamos-comecar
                v-if="formData.openedStep.actual === formData.openedStep.first"
                class="v-stepper__wrapper"
                :get="get"
                @actionButton="actionButton"
              />
            </v-stepper-content>

            <v-stepper-content
              class="pa-0 ma-0 border-none"
              step="2"
            >
              <dados-pessoais
                v-if="formData.openedStep.actual === 2"
                class="v-stepper__wrapper"
                :get="get"
                @actionButton="actionButton"
              />
            </v-stepper-content>

            <v-stepper-content
              class="pa-0 ma-0 border-none"
              step="3"
            >
              <payment
                v-if="formData.openedStep.actual === 3"
                class="v-stepper__wrapper"
                :payment="payment"
                @tryAgain="paymentErrorTryAgain"
                @awaitOrder="awaitOrder"
                @actionButton="actionButton"
              />
            </v-stepper-content>

            <v-stepper-content
              class="pa-0 ma-0 border-none"
              step="pagamento"
            >
              <verify-payment
                v-if="formData.openedStep.actual === 'pagamento'"
                class="v-stepper__wrapper"
                :get="get"
                @actionButton="actionButton"
              />
            </v-stepper-content>

            <v-stepper-content
              class="pa-0 ma-0 border-none"
              step="resumoboletobancario"
            >
              <resumo-pedido-boletobancario
                v-if="formData.openedStep.actual === 'resumoboletobancario'"
                class="v-stepper__wrapper"
                @actionButton="actionButton"
              />
            </v-stepper-content>

            <v-stepper-content
              class="pa-0 ma-0 border-none"
              :step="formData.openedStep.last"
            >
              <resumo-pedido
                v-if="formData.openedStep.actual === formData.openedStep.last"
                class="v-stepper__wrapper"
                :message="message"
                @actionButton="actionButton"
              />
            </v-stepper-content>
          </div>
        </v-scroll-y-transition>
      </v-stepper-items>
    </v-stepper>

    <v-dialog
      v-model="payment.message.error.status"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Desculpe!
        </v-card-title>

        <v-card-text
          v-if="payment.message.error.e.message"
        >
          <h3
            class="grey-regular--text font-weight-regular"
            v-html="payment.message.error.e.message"
          />
        </v-card-text>

        <v-card-text
          v-else
        >
          <h3
            class="grey-regular--text font-weight-regular"
          >
            Tivemos um erro na comunicação com nosso serviço, você quer tentar mais uma vez?
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="brand-principal"
            flat
            @click="paymentErrorTryAgain()"
          >
            Tentar outra vez
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="error"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Desculpe!
        </v-card-title>

        <v-card-text>
          <h3
            class="grey-regular--text font-weight-regular"
          >
            Tivemos um erro na comunicação com nosso serviço, você quer tentar mais uma vez?
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="brand-principal"
            flat
            @click="Home()"
          >
            Tentar outra vez
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="erroremail"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Email inválido
        </v-card-title>

        <v-card-text>
          <h3
            class="grey-regular--text font-weight-regular"
          >
            Insira um email válido para que possamos lhe enviar todas as informações sobre o seu pedido.
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="brand-principal"
            flat
            @click="retentarEmail()"
          >
            Digitar um email válido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPedidoConcluido.status"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Pedido em andamento
        </v-card-title>

        <v-card-text>
          <h3
            v-show="/aprovado/.test(dialogPedidoConcluido.message)"
            class="grey-regular--text font-weight-regular"
          >
            Detectamos que o pagamento do seu pedido já foi recebido, você será notificado por E-mail e SMS sobre o andamento do pedido.
          </h3>
          <h3
            v-show="/pendente/.test(dialogPedidoConcluido.message)"
            class="grey-regular--text font-weight-regular"
          >
            Detectamos que o pagamento do seu pedido já foi recebido e estamos analizando o mesmo, você será notificado por E-mail e SMS sobre o andamento do pedido.
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="brand-principal"
            flat
            @click="gotoResumoPedido()"
          >
            Ir para o resumo do pedido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPedidoError"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Informação sobre seu último pedido
        </v-card-title>

        <v-card-text>
          <h3
            class="grey-regular--text font-weight-regular"
          >
            Detectamos que o pagamento do seu último pedido foi negado, fique tranquilo, você poderá continuar com seu pedido atual.
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="brand-principal"
            flat
            @click="gotoContinuarPedido()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<style lang="stylus">
  .gtm_button
    div.v-btn__content
      height 0px !important
</style>

<script>
  import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'

  import api from '@/plugins/axios/api'
  import Moment from 'moment'

  import VamosComecar from '@/components/forms/VamosComecar'
  import DadosPessoais from '@/components/forms/concept/DadosPessoais'
  import ResumoPedido from '@/components/forms/ResumoPedido'

  import ResumoPedidoBoletobancario from '@/components/forms/boletobancario/ResumoPedido'

  /** @Payment */
  import Payment from '@/components/contents/tvpre/upnid/Payment'
  import VerifyPayment from '@/components/contents/tvpre/yapay/VerifyPayment'
  /** @Payment */

  /** @Mixins */
  import GlobalMixinClearSale from '@/plugins/mixins/clearsale'
  import encryptation from '@/plugins/mixins/encryptation'
  import find from '@/plugins/mixins/keyfind'
  import product from '@/plugins/mixins/product'
  /** @mixins */

  export default {
    components: {
      VamosComecar,
      DadosPessoais,
      ResumoPedido,
      ResumoPedidoBoletobancario,
      VerifyPayment,
      Payment
    },

    mixins: [
      GlobalMixinClearSale,
      encryptation,
      find,
      product
    ],

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'tentarpix'),
        error: false,
        errorsms: false,
        erroremail: false,
        dialogPedidoConcluido: {
          status: false,
          message: ''
        },
        dialogPedidoError: false,

        stepper: {
          active: true
        },

        message: {
          resume: {},
          order: undefined
        },

        get: {
          sms: false,
          gtm: undefined
        },

        validateForm: false,

        isCreditCardPayment: false,
        activeCreditCard: undefined,

        disableNext: false,

        payment: {
          urlCheckout: undefined,
          boletoBancario: {
            linhaDigitavel: undefined
          },
          message: {
            error: {
              status: false,
              e: {}
            }
          }
        }
      }
    },

    computed: {
      ...mapState('Modules', [ 'env', 'formData', 'cache', 'dialogs' ]),
      ...mapState('Payload', [ 'payload' ]),
      ...mapGetters('Modules', [ 'Home' ])
    },

    methods: {
      ...mapMutations('Modules', [ 'FORMDATAOPENED', 'GOTOOPENEDSTEP', 'INCREMENTDECREMENTOPENEDSTEP', 'DIALOGS', 'CACHE', 'UPDATESTEP', 'SETDOCCUSTOMER', 'SETCUSTOMER', 'SETADDRESSCUSTOMER' ]),
      ...mapActions('Payload', [ 'UpdatePayload', 'UpdateCacheBoleto', 'SaveInput', 'TvpreActionPayloadVerificaEmail', 'ActionPayloadSalvaPedidoPagamentoUUID' ]),
      ...mapActions('Modules', [ 'loading' ]),

      hideLoading () {
        this.loading({ value: false, time: 1 })
      },

      validate () {
        this.disableNext = true
        if (this.$refs.validateForm.validate()) {
          if (this.isCreditCardPayment) {
            this.activeCreditCard = true
          } else {
            this.loading({ value: true, time: 1 })
            this.apiIntegration()
          }
        } else {
          this.disableNext = false
        }
      },

      saveSms () {
        if (this.$refs.validateForm.validate()) {
          this.loading({ value: true, time: 1 })
          api
            .post('savesms', this.payload, {
              params: { code: this.cache.consumidor.verify.sms }
            })
            .then(response => {
              this.loading({ value: false, time: 480 })
              if (response.data.sms.confirmado) {
                this.sessionFinished(response)
                this.stepper.active = false
                this.get.sms = false
              } else {
                this.CACHE({ key: 'verifysms', value: 'error' })
              }
            })
            .catch(() => {
              this.loading({ value: false, time: 480 })
              this.errorsms = true
            })
        }
      },

      resendSms () {
        this.loading({ value: true, time: 1 })

        api.post('resendsms', this.payload).then(response => {
          this.loading({ value: false, time: 480 })
        })
      },

      boletoBancarioSuccess () {
        this.nextStep('resumoboletobancario')

        this.loading({ value: false, time: 480 })
      },

      creditCardSuccess (response) {
        this.message.order = response.success.oportunidade.id

        this.nextStep(this.formData.openedStep.last)

        this.loading({ value: false, time: 480 })
      },

      awaitOrder (response) {
        this.message = {
          resume: response.error,
          order: response.order
        }

        this.nextStep(this.formData.openedStep.last)

        this.loading({ value: false, time: 480 })
      },

      paymentErrorTryAgain (payment) {
        this.payment.message.error = {
          status: false,
          e: {}
        }

        if (/pix/.test(payment)) {
          this.LOG('tentar pagamento', payment)
          this.SaveInput({
            input: true,
            name: 'pedidoPagamentoFormaPagamento',
            value: payment
          })
          this.loading({ value: true, time: 1 })
          setTimeout(() => {
            this.actionButton('contratar')
          }, 300)
        }
        this.nextStep(2)

        this.loading({ value: false, time: /pix/.test(payment) ? 290 : 1 })
      },

      actionButton (param) {
        if (this.keyFind(param, 'success')) {
          this.loading({ value: true, time: 1 })
          if ((/boletobancario/).test(param.success)) {
            this.boletoBancarioSuccess()
            return
          }

          this.creditCardSuccess(param)
        }

        if (this.keyFind(param, 'resumo')) {
          setTimeout(() => (this.loading({ value: true, time: 1 })), 200)

          let oportunidadeID = {
            success: {
              oportunidade: {
                id: param.oportunidade.id
              }
            }
          }

          if ((/boletobancario/).test(param.resumo)) {
            this.UpdateCacheBoleto(param)
            setTimeout(() => (this.boletoBancarioSuccess()), 480)
            return
          }

          setTimeout(() => (this.creditCardSuccess(oportunidadeID)), 480)
          return
        }

        if (param === 'voltar') {
          this.reset()
        }

        if (param === 'avancar') {
          if (this.formData.openedStep.actual !== this.formData.openedStep.last) {
            this.validate()
          } else {
            this.reset()
          }
        }

        if (param === 'contratar' && this.$refs.validateForm.validate()) {
          this.loading({ value: true, time: 1 })
          this.salvaPedidoPagamentoUPNID()
        }
      },

      salvaPedidoPagamentoUPNID () {
        async function contactApi (payload) {
          const { data } = await api.post('save/finish', payload)
          return data
        }

        if (!this.payload.pedido.ciente.contrato.servico) return

        contactApi(
          this.payload
        ).then(response => {
          this.LOG('dados recebidos no Save Finish', response)
          if (!response) throw Error('A Resposta da API não trouxe resultado - reload')
          if (!response.pedido.pagamento.uuid) throw Error('Não foi recebido UUID de pagamento - tentarnovamente')
          if (!this.keyFind(response, '_id')) throw Error('pedido-invalido')
          this.UpdatePayload({ _id: response._id })
          // this.payment.urlCheckout = this.getURLCheckout(response.pedido)
          this.nextStep(3)
        }).catch(error => {
          this.LOG('error on', __filename, error)
        }).finally(() => {
          this.loading({ value: false, time: 480 })
        })
      },

      getURLCheckout (pedido) {
        try {
          const dataEnvCheckout = require('@/data/env/tvpre').checkout
          const urlBase = dataEnvCheckout.url[dataEnvCheckout.api].base
          const listaURL = this.getProduct(pedido.produto[0].id, 'checkout')[dataEnvCheckout.api][pedido.pagamento.formaPagamento][window.env()][pedido.produto[0].quantidade - 1]

          if (listaURL.constructor !== String) return

          this.LOG('retornei uma URL da upnid', `${urlBase}${listaURL}&src=${pedido.pagamento.uuid}`)

          return `${urlBase}${listaURL}&src=${pedido.pagamento.uuid}`
        } catch (error) {
          this.LOG('error on', __filename, error)
        }
      },

      cryptData () {
        if (this.formData.openedStep.actual <= 4) {
          this.encrypt({
            payload: this.payload,
            finishStep: this.formData.openedStep.actual
          })
        }
      },

      async getCustomer () {
        if (this.formData.openedStep.actual === 1) {
          try {
            const response = await api.get(`customers/${this.payload.consumidor.cpf}`)

            if (!response.data.data) throw new Error(response.status)

            const { data } = response.data
            const address = data.adresses[0]

            this.SETCUSTOMER({
              nome: data.name,
              filiacao: data.parentage,
              nascimento: data.birth
                ? Moment(data.birth, 'YYYY-MM-DD').format('DDMMYYYY')
                : '',
              sexo: data.gender
            })

            if (address.constructor === Object && Object.entries(address).length) {
              this.SETADDRESSCUSTOMER({
                cep:
                  isNaN(Number(address.postcode)) ||
                  Number(address.postcode) === 0
                    ? ''
                    : address.postcode.toString().padStart(8, '0')
              })
            }
          } catch (e) {
            if (e) {}
          }
        }
      },

      async apiIntegration () {
        try {
          await this.getCustomer()

          this.cryptData()

          if (this.disableNext) {
            const response = await api.post('save', this.payload)

            if (!this.keyFind(response.data, '_id')) throw Error('pedido-invalido')

            if (response.data.verificacaoEmail.api.status) {
              this.TvpreActionPayloadVerificaEmail({
                enviado: response.data.verificacaoEmail.enviado,
                confirmado: response.data.verificacaoEmail.confirmado
              })

              this.SaveInput({
                input: true,
                name: 'consumidorEmail',
                value: response.data.consumidor.email
              })

              if (!response.data.verificacaoEmail.confirmado) {
                throw Error('email-invalido')
              }
            }

            this.UpdatePayload({ step: this.formData.openedStep.actual, _id: response.data._id })

            if (this.keyFind(response.data, 'pedido')) {
              if (this.keyFind(response.data.pedido, 'pagamento')) {
                this.ActionPayloadSalvaPedidoPagamentoUUID(response.data.pedido.pagamento.uuid)
                if ((/^(aprovado|pendente)$/i).test(response.data.pedido.pagamento.status)) {
                  this.dialogPedidoConcluido.status = true
                  this.dialogPedidoConcluido.message = response.data.pedido.pagamento.status
                  return
                }
                if ((/^(negado)$/i).test(response.data.pedido.pagamento.status)) {
                  this.dialogPedidoError = true
                }
              }
            }

            this.sessionFinished(response)
          }
        } catch (error) {
          this.LOG('error on', __filename, error)

          switch (true) {
            case /^email-invalido$/.test(error.message):
              this.erroremail = true
              break
            default:
              this.error = true
          }
        } finally {
          this.loading({ value: false, time: 480 })
        }
      },

      sessionFinished (response) {
        if (response.data.hasOwnProperty('oportunidade')) {
          if (response.data.hasOwnProperty('oportunidade')) {
            if (response.data.oportunidade.id === null) {
              this.message.resume = { wait: true }
            } else {
              this.message.order = response.data.oportunidade.id
            }
          }

          if (response.data.duplicado) {
            this.message.order = response.data.duplicado
          }

          if (
            !response.data.duplicado &&
            !response.data.hasOwnProperty('oportunidade')
          ) {
            if (response.data.source === 'carrefour_galaxyday') {
              this.message.resume = { carrefourGalaxyday: true }
            } else {
              this.message.resume = { wait: true }
            }
          }
        }

        this.nextStep()
      },

      resetStep () {
        this.$refs.validateForm.resetValidation()
        this.$refs.validateForm.reset()
        this.activeCreditCard = false
        this.isCreditCardPayment = false
        this.disableNext = false
      },

      reset (value) {
        this.loading({ value: true, time: 1 })
        if (
          this.formData.openedStep.actual === this.formData.openedStep.first ||
          this.formData.openedStep.actual === this.formData.openedStep.last
        ) {
          if (
            this.$route.meta.hasOwnProperty('regulamento') &&
            this.formData.openedStep.actual === this.formData.openedStep.last
          ) {
            this.hideLoading()

            this.DIALOGS({
              key: 'regulamento',
              toggle: true
            })
          } else {
            this.$refs.validateForm.resetValidation()
            this.$refs.validateForm.reset()
            if (this.payload.source === 'prospect') this.Home()
            this.FORMDATAOPENED(false)
          }
        } else if (
          this.formData.openedStep.actual > this.formData.openedStep.first
        ) {
          this.loading({ value: false, time: 480 })
          let i = 0
          this.$watch(this.loadingDialog, value => {
            if (!value && i === 1) {
              this.INCREMENTDECREMENTOPENEDSTEP(false)
            }
            i++
          })
        }
      },

      nextStep (step) {
        if (step) return this.GOTOOPENEDSTEP(step)

        if (this.formData.openedStep.actual !== this.formData.openedStep.last) {
          let i = 0
          this.$watch(this.loadingDialog, value => {
            if (!value && i === 0) {
              this.INCREMENTDECREMENTOPENEDSTEP(true)
              this.disableNext = false
              this.$refs.validateForm.resetValidation()
            }
            i++
          })
        }
      },

      loadingDialog (value) {
        return this.dialogs.loading
      },

      retentarEmail () {
        this.erroremail = false

        this.SaveInput({
          input: true,
          name: 'consumidorEmail',
          value: ''
        })
      },

      gotoResumoPedido () {
        this.actionButton({
          ...this.payload,
          oportunidade: {
            ...this.payload.oportunidade
          },
          resumo: this.payload.pedido.pagamento.formaPagamento
        })

        this.dialogPedidoConcluido.status = false
        this.dialogPedidoConcluido.message = ''
      },

      gotoContinuarPedido () {
        this.dialogPedidoError = false
      }
    }
  }
</script>
