export default {
  methods: {
    async GetReturnTransacaoMessage (lastTransacaoRetorno) {
      const Axios = require('axios')

      function statustransacao () {
        return Axios.get(`
          ${process.env.VUE_APP_ELASTIC_SEARCH_API}yapay/payment/messages/statustransacao/${
            lastTransacaoRetorno.statusTransacao
          }
        `)
      }

      const status = await Axios.all([
        statustransacao()
      ]).then(Axios.spread((statustransacao /* , codigotransacaooperadora */) => {
        /* if (codigotransacaooperadora.data.hits.length) {
          return codigotransacaooperadora.data.hits[0]._source
        } else */
        if (statustransacao.data.hits.hits) {
          if (statustransacao.data.hits.hits.length) {
            return statustransacao.data.hits.hits[0]._source
          }
        } else if (statustransacao.data.hits.length) {
          return statustransacao.data.hits[0]._source
        }
      }))

      return status
    }
  }
}
