import Moment from 'moment'
import CryptoJS from 'crypto-js'

export default {
  methods: {
    encrypt ({
      payload,
      response,
      finishStep
    }) {
      const data = {
        payload,
        response,
        finishStep,
        expired: Moment().add(30, 'days')
      }

      const encrypt = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        'claro_secret_password'
      )

      localStorage.setItem('cache', encrypt.toString())
    },
    decrypt () {
      const crypto = localStorage.getItem('cache')

      if (crypto) {
        const bytes = CryptoJS.AES.decrypt(
          crypto,
          'claro_secret_password'
        )
        const string = bytes.toString(CryptoJS.enc.Utf8)

        return JSON.parse(string)
      }

      return false
    }
  }
}
