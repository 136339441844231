<template>
  <div />
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    mounted () {
      this.Atendente(
        this.$route.params
      )
    },

    methods: {
      ...mapActions('Payload', [
        'Atendente'
      ])
    }
  }
</script>
