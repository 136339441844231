<template>
  <v-card
    color="white"
    tile
    flat
    style="font-family:var(--font-family-highlight)"
  >
    <v-layout
      v-if="freeFeePricePromotion"
    >
      <v-card
        color="#570C0C"
        width="100%"
        :height="$vuetify.breakpoint.smAndDown ? 100 : 50"
        dark
        tile
        flat
      >
        <v-layout
          justify-center
          fill-height
          align-center
        >
          <v-flex
            shrink
            pl-2
          >
            <v-img
              src="/img/sticker/sticker-fee.png"
              width="36"
              height="36"
              contain
              class="mx-auto"
              alt="Frete grátis Receptores HD e SD"
              title="Frete grátis Receptores HD e SD"
            />
          </v-flex>

          <v-flex
            shrink
            px-2
            text-xs-center
          >
            <strong
              class="text-uppercase"
              style="font-size:16px"
              v-text="'Frete grátis para todo o Brasil na compra do receptor HD.'"
            />
          </v-flex>

          <v-flex
            shrink
            pr-2
          >
            <v-img
              src="/img/sticker/sticker-fee.png"
              width="36"
              height="36"
              contain
              class="mx-auto"
              alt="Frete grátis Receptores HD e SD"
              title="Frete grátis Receptores HD e SD"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-layout>

    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs12
        py-3
      />

      <v-flex
        text-xs-center
        xs10
      >
        <strong
          v-font-size="18"
          class="font-weight-bold text-uppercase"
          v-text="'Aproveite a TV inteligente que cabe no seu bolso.'"
        />
      </v-flex>

      <v-flex
        xs12
        py-3
      />

      <v-flex
        xs10
        md8
        lg6
      >
        <v-layout
          wrap
          justify-center
        >
          <v-flex
            v-for="(next, index) in faq"
            :key="`card-informacoes-${index}`"
            xs12
            md3
          >
            <v-layout fill-height>
              <v-flex
                xs12
                md11
              >
                <v-card
                  tile
                  flat
                >
                  <v-layout
                    justify-center
                    align-center
                    fill-height
                    wrap
                  >
                    <v-flex
                      xs3
                      md12
                    >
                      <v-img
                        :src="next.icon"
                        :height="32"
                        :width="32"
                        contain
                        class="mx-auto"
                      />
                    </v-flex>

                    <v-flex
                      pt-2
                      xs8
                      text-md-center
                    >
                      <span
                        v-text="next.text"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-divider
                  v-show="index < faq.length -1"
                  class="my-4 hidden-md-and-up"
                />
              </v-flex>

              <v-flex
                v-show="index < faq.length -1"
                xs1
                hidden-sm-and-down
              >
                <v-divider vertical />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
  import GlobalMixinServicesAPI from '@/plugins/mixins/apiServices'
  export default {
    mixins: [
      GlobalMixinServicesAPI
    ],

    data: function () {
      return {
        faq: [
          {
            icon: '/img/icons/instrucoes/claro-tvpre-servico-sem-mensalidade.png',
            text: 'Serviço sem mensalidade, sem fidelidade e sem multa.'
          },
          {
            icon: '/img/icons/instrucoes/claro-tvpre-recarregue-quando-quiser.png',
            text: 'Recarregue quando e pelo tempo que preferir.'
          },
          {
            icon: '/img/icons/instrucoes/claro-tvpre-assista-com-ponto-adicional.png',
            text: 'Tenha até três pontos com uma mesma recarga.'
          },
          {
            icon: '/img/icons/instrucoes/claro-tvpre-curta-sua-programacao.png',
            text: 'Curta a programação perfeita pra você.'
          }
        ]
      }
    },

    computed: {
      freeFeePricePromotion: {
        get () {
          let userDataRegion = this.getCacheRastreamentoUsuario()
          if (userDataRegion && userDataRegion.region_code) {
            const { REGIAO_UF_ENTREGA_GRATUITA } = require('@/data/feeRegion/feeRegionData.json')
            return REGIAO_UF_ENTREGA_GRATUITA.includes(userDataRegion.region_code)
          }
          return true
        }
      }
    }
  }
</script>
