<template>
  <div>
    <v-spacer
      class="height-56"
    >
      <v-toolbar
        color="#F44336"
        :manual-scroll="scroll"
        height="56"
        dark
        flat
        app
      >
        <v-toolbar-side-icon
          class="hidden-lg-and-up position-absolute"
          style="z-index: 1"
          @click="drawer = !drawer"
        />

        <v-container
          class="pa-0 ma-0"
          fluid
        >
          <v-card
            color="transparent"
            class="mx-auto"
            flat
            tile
          >
            <v-layout>
              <v-flex
                class="cursor-pointer max-width-160"
                shrink
              >
                <v-img
                  src="/img/logos/claro-tv-pre.png"
                  class="mt-1"
                  width="160"
                  height="40"
                  contain

                  title="Claro tv-pre"
                  alt="Claro tv-pre"
                />
              </v-flex>

              <v-flex
                shrink
                hidden-md-and-down
              >
                <componente-toolbar-buttons-navigator>
                  <template v-slot:tabs />
                </componente-toolbar-buttons-navigator>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-toolbar>
    </v-spacer>

    <v-navigation-drawer
      v-model="drawer"
      class="red"
      disable-resize-watcher
      disable-route-watcher
      dark
      app
    >
      <v-toolbar
        color="transparent"
        flat
      >
        <v-toolbar-side-icon
          class="position-absolute"
          style="z-index: 1"
          @click="drawer = !drawer"
        >
          <i class="mdn-Icon-seta-esquerda mdn-Icon--md" />
        </v-toolbar-side-icon>
      </v-toolbar>

      <v-card
        color="transparent"
        flat
      >
        <v-layout
          column
        >
          <v-flex>
            <v-layout
              column
            >
              <componente-toolbar-buttons-navigator>
                <template v-slot:list />
              </componente-toolbar-buttons-navigator>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<style lang="stylus">
  .fix-tabs-mobile
    .v-tab
      justify-content: left
</style>

<script>
  import { mapState } from 'vuex'
  import ComponenteToolbarButtonsNavigator from '@/components/toolbars/botoesnavegacao/ToolbarButtonsNavigator'

  export default {
    components: {
      ComponenteToolbarButtonsNavigator
    },

    data: function () {
      return {
        drawer: false,
        scroll: false
      }
    },
    computed: {
      ...mapState('Modules', [ 'tel' ]),
      ...mapState('Payload', [ 'payload' ])
    },

    created () {
      this.onScroll()
      window.addEventListener('scroll', this.onScroll, { passive: true })
    },

    methods: {
      onScroll () {
        this.scroll = (window.pageYOffset || document.documentElement.scrollTop) >= 265

        window.onscroll = function (e) {
          if (this.oldScroll > this.scrollY) return true
          this.oldScroll = this.scrollY
        }
      },

      openClaroProduct (param) {
        location.replace(`//meuplanoclaro.com.br${param.path}${location.search}`)
      }
    }
  }
</script>
