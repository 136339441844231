<template>
  <div
    class="gotoCard"
  >
    <v-carousel
      hide-delimiters
      hide-controls
      style="z-index:1;"
      :height="`${$vuetify.breakpoint.smAndDown ? '624px' : '440px'}`"
      cycle
      interval="8000"
    >
      <v-img
        :src="require(`@/assets/images/banner/default/tvpre-bg-red-${$vuetify.breakpoint.name}.png`)"
      >
        <v-carousel-item
          style="max-width: 1280px; margin: 0 auto;"
        >
          <v-layout
            wrap
          >
            <v-flex
              xs12
              md4
              order-md2
            >
              <v-img
                :src="require(`@/assets/images/banner/default/tvpre-circulos-oferta-${$vuetify.breakpoint.name}.png`)"
                contain
                max-height="440px"
                :min-width="`${$vuetify.breakpoint.smAndDown ? '' : '475px'}`"
                position="right"
              >
                <v-layout
                  :style="`${$vuetify.breakpoint.width < '900' ? 'position: absolute; bottom: 8%; right: 2%; max-width: 130px;' : 'position: absolute; bottom: 4%; right: 24%; max-width: 130px;'}`"
                  wrap
                >
                  <v-flex
                    xs12
                    style="transform:translateY(14px)"
                  >
                    <span
                      v-font-size="16"
                      class="font-weight-medium"
                      style="color:#520d0f"
                      v-text="'Por apenas'"
                    />
                  </v-flex>

                  <v-flex
                    class="shrink align-self-end"
                    style="transform:translateY(-10px)"
                  >
                    <span
                      v-font-size="22"
                      style="color:#520d0f"
                      class="font-weight-bold"
                      v-text="'R$'"
                    />
                  </v-flex>

                  <v-flex
                    class="shrink align-self-end"
                  >
                    <span
                      v-font-size="45"
                      style="color:#520d0f"
                      class="font-weight-bold"
                      v-text="
                        String(currency({
                          valor: parseFloat(getProductPrice)
                        })).split(/,/)[0]
                      "
                    />
                  </v-flex>

                  <v-flex
                    class="shrink align-self-center"
                    style="transform:translateY(1px)"
                  >
                    <span
                      v-font-size="22"
                      style="color:#520d0f"
                      class="font-weight-bold"
                      v-text="`,${
                        String(currency({
                          valor: parseFloat(getProductPrice)
                        })).split(/,/)[1]
                      }`"
                    />
                  </v-flex>

                  <v-flex
                    xs12
                    style="transform:translateY(-14px)"
                  >
                    <span
                      v-font-size="16"
                      style="color: #520d0f;"
                      class="font-weight-medium"
                      v-text="'à vista'"
                    />
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
            <v-flex
              xs12
              md7
              style="position: relative;"
              order-md1
            >
              <v-img
                :src="require(`@/assets/images/banner/default/tvpre-anitta-tv-antena-${$vuetify.breakpoint.name}.png`)"
                :height="`${$vuetify.breakpoint.smAndDown ? '218px' : '100%' }`"
                :position="`${$vuetify.breakpoint.smAndDown ? 'top' : 'bottom'}`"
                contain
                style="position: relative;"
              >
                <h4
                  v-font-size="22"
                  :style="`${$vuetify.breakpoint.width < '1100' ? 'position: absolute; top: 24%; right: 6%;' : 'position: absolute; top: 10%; right: 10%;'} height: auto; color: #fff; text-align: center;`"
                  class="text-uppercase hidden-sm-and-down"
                >
                  recarregue sua claro tv pré
                  <br>
                  <span
                    style="color: #FFEE00;"
                  >
                    e tenha o melhor da programação.
                  </span>
                </h4>
                <v-layout
                  :style="`${$vuetify.breakpoint.smAndDown ? 'transform:translateY(-24px);' : ''} position: absolute; bottom: 4%; left: 0; right: 2%; height: auto;`"
                >
                  <v-flex
                    md6
                    xs11
                    :style="`${$vuetify.breakpoint.smAndDown ? 'margin: 0 auto;' : 'margin-left: auto; margin-right: 5%;'}`"
                  >
                    <v-btn
                      v-font-size="$vuetify.breakpoint.xsOnly && 12"
                      color="#F3A70C"
                      class="px-0"
                      round
                      depressed
                      large
                      block
                      light
                      @click="openFormData()"
                      v-text="'Clique aqui e garanta sua oferta'"
                    />
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
          </v-layout>
        </v-carousel-item>
      </v-img>

      <v-carousel-item
        v-if="false"
      >
        <v-img
          color="#55190C"
          :src="`/img/banners/modelo/anita/Receptor HD com 2 anos de canais abertos/anitta-com-receptor-hd-e-canais-abertos-${$vuetify.breakpoint.name}.png`"
          :max-height="$vuetify.breakpoint.mdAndUp ? '450px' : 'calc(100vh - 0px)'"
          aspect-ratio="0.6"
        >
          <v-layout
            style="max-width: 1360px; margin: auto"
            align-content-start
            column
            fill-height
          >
            <v-flex xs12>
              <v-layout
                justify-center
                align-center
                fill-height
                wrap
              >
                <v-flex
                  v-margin.top="$vuetify.breakpoint.smAndDown ? 280 : 0"
                  xs12
                  md6
                  lg5
                />

                <v-flex
                  hidden-md-and-up
                  xs12
                  py-1
                />

                <v-flex
                  xs12
                  md6
                  lg4
                >
                  <v-layout
                    wrap
                    text-xs-center
                    justify-center
                    align-center
                    fill-height
                  >
                    <v-flex
                      v-margin.top="$vuetify.breakpoint.smAndDown ? 10 : 40"
                      xs11
                    >
                      <strong
                        v-font-size="$vuetify.breakpoint.xsOnly ? 21 : 20"
                        class="text-uppercase white--text"
                        v-html="'Aproveite e garanta agora'"
                      />
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-1
                    >
                      <v-card
                        style="border-radius:8px"
                        color="#F3A70C"
                        flat
                      >
                        <strong
                          v-font-size="$vuetify.breakpoint.smAndDown ? 24 : 32"
                          class="text-uppercase"
                          v-text="'R$140 de desconto'"
                        />
                      </v-card>
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-2
                      style="line-height:1.2em"
                    >
                      <strong
                        v-font-size="20"
                        class="text-uppercase white--text"
                        v-text="'Com 2 anos de canais abertos* + receptor hd'"
                      />
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-1
                    >
                      <v-divider
                        class="white"
                      />
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-1
                    >
                      <v-layout
                        wrap
                        justify-center
                      >
                        <v-flex
                          xs5
                        >
                          <v-layout
                            wrap
                            text-xs-left
                          >
                            <v-flex
                              xs12
                            >
                              <small
                                v-font-size="14"
                                class="white--text"
                                v-text="'De'"
                              />
                            </v-flex>

                            <v-flex
                              xs12
                            >
                              <v-layout
                                style="line-height:2em"
                              >
                                <v-flex
                                  class="shrink align-self-center"
                                >
                                  <span
                                    v-font-size="24"
                                    style="color: #f3a70c;text-decoration:line-through"
                                    class="font-weight-bold"
                                    v-text="'R$ 539,00'"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex
                              xs12
                            >
                              <small
                                v-font-size="14"
                                class="white--text"
                                v-text="'à vista'"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex
                          xs2
                        >
                          <v-divider
                            vertical
                            class="white"
                          />
                        </v-flex>

                        <v-flex
                          xs5
                        >
                          <v-layout
                            wrap
                            text-xs-left
                          >
                            <v-flex
                              xs12
                            >
                              <small
                                v-font-size="14"
                                class="white--text"
                                v-text="'Por até 10x sem juros'"
                              />
                            </v-flex>

                            <v-flex
                              xs12
                            >
                              <v-layout
                                style="line-height:2em"
                              >
                                <v-flex
                                  class="shrink align-self-start"
                                  style="transform:translateY(-3px)"
                                >
                                  <span
                                    v-font-size="24"
                                    style="color:#f3a70c"
                                    class="font-weight-bold"
                                    v-text="'R$'"
                                  />
                                </v-flex>

                                <v-flex
                                  class="shrink align-self-center"
                                >
                                  <span
                                    v-font-size="32"
                                    style="color: #f3a70c"
                                    class="font-weight-bold"
                                    v-text="'39'"
                                  />
                                </v-flex>

                                <v-flex
                                  class="shrink align-self-center"
                                  style="transform:translateY(1px)"
                                >
                                  <span
                                    v-font-size="24"
                                    style="color: #f3a70c"
                                    class="font-weight-bold"
                                    v-text="',90'"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex
                              xs12
                            >
                              <small
                                v-font-size="14"
                                class="white--text"
                                v-text="'no cartão de crédito'"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-1
                    >
                      <v-divider
                        class="white"
                      />
                    </v-flex>

                    <v-flex xs10>
                      <small
                        v-font-size="11"
                        style="color: #a86e6e"
                        class="d-block"
                        v-text="'*programação pacote incial.'"
                      />
                    </v-flex>

                    <v-flex
                      xs10
                      md8
                      lg9
                      py-2
                    >
                      <v-btn
                        v-font-size="$vuetify.breakpoint.xsOnly && 12"
                        color="#F3A70C"
                        class="px-0 hidden-md-and-down"
                        round
                        depressed
                        large
                        block
                        light
                        @click="openFormDataSpecialOffer('vtxreceptorhddoisanoscanaisabertos')"
                        v-text="'Clique aqui e garanta sua oferta'"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>

        <v-footer
          class="hidden-md-and-up elevation-24"
          fixed
          height="70"
          color="#821C17"
        >
          <v-layout
            align-center
            justify-center
            fill-height
          >
            <v-flex xs10>
              <v-btn
                v-font-size="$vuetify.breakpoint.xsOnly && 12"
                color="#F3A70C"
                class="px-0"
                round
                depressed
                large
                block
                light
                @click="openFormDataSpecialOffer('vtxreceptorhddoisanoscanaisabertos')"
                v-text="'Clique aqui e garanta sua oferta'"
              />
            </v-flex>
          </v-layout>
        </v-footer>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'

  /** @Mixins */
  import mask from '@/plugins/mixins/mask'
  import product from '@/plugins/mixins/product'
  /** @Mixins */

  export default {
    mixins: [
      mask,
      product
    ],

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'promoboletoperdido')
      }
    },

    computed: {
      getOfferDescription: {
        get () {
          if (this.$route.meta && this.$route.meta.promo) {
            if (this.product[this.$route.meta.promo.product]) {
              if (
                /antena/.test(this.product[this.$route.meta.promo.product].plan)
              ) {
                return '2 meses de canais grátis* + Receptor HD + Antena'
              }
            }
          }
          return '2 meses de canais abertos + receptor HD'
        }
      },

      getProductPrice: {
        get () {
          if (this.$route.meta && this.$route.meta.promo) {
            if (this.product[this.$route.meta.promo.product]) {
              if (
                /antena/.test(this.product[this.$route.meta.promo.product].plan)
              ) {
                return this.product[this.$route.meta.promo.product].price.default
              }
            }
          }
          if (this.product[this.getProductID]) {
            if (this.product[this.getProductID].price) {
              if (this.product[this.getProductID].price.default) {
                return this.product[this.getProductID].price.default
              }
            }
          }
          return 0
        }
      },

      getProductID: {
        get () {
          if (this.$route.meta && this.$route.meta.promo) {
            if (this.product[this.$route.meta.promo.product]) {
              if (
                /antena/.test(this.product[this.$route.meta.promo.product].plan)
              ) {
                return this.product[this.$route.meta.promo.product].plan
              }
            }
          }
          return 'vtxreceptorhd'
        }
      }
    },

    created () {
      if (Object.keys(this.$route.meta).indexOf('promo') !== -1) {
        this.ROUTEPROMO({ hasPromo: true, promo: this.$route.meta.promo })
      }
    },

    methods: {
      ...mapActions('Payload', ['OpenOferta']),
      ...mapMutations('Modules', ['ROUTEPROMO']),

      openFormData () {
        let productSKU = this.getProduct(this.getProductID, 'sku')
        if (this.$route.meta.promo) {
          if (this.$route.meta.promo.sku) productSKU = this.$route.meta.promo.sku
        }

        this.OpenOferta(
          new Array({
            id: this.getProduct(this.getProductID, 'plan'),
            modelo: this.getProduct(this.getProductID, 'title').short,
            preco: !this.$route.meta.promo
              ? this.getProductID !== 'vtxantenareceptorhd'
                ? String(this.getProduct(this.getProductID, 'price').default)
                : String(this.getProduct(this.getProductID, 'price').default)
              : String(this.getProduct(this.getProductID, 'price').default),
            descricao: this.getProduct(this.getProductID, 'title').nome,
            sku: productSKU,
            quantidade: 1
          })
        )

        this.dataLayer(this.getProductID)
      },

      openFormDataSpecialOffer (offer) {
        let productSKU = this.getProduct(offer, 'sku')
        if (this.$route.meta.promo) {
          if (this.$route.meta.promo.sku) productSKU = this.$route.meta.promo.sku
        }

        this.OpenOferta(
          new Array({
            id: this.getProduct(offer, 'plan'),
            modelo: this.getProduct(offer, 'title').short,
            preco: !this.$route.meta.promo
              ? offer !== 'vtxantenareceptorhd'
                ? String(this.getProduct(offer, 'price').default)
                : String(this.getProduct(offer, 'price').default)
              : String(this.getProduct(offer, 'price').default),
            descricao: this.getProduct(offer, 'title').nome,
            sku: productSKU,
            quantidade: 1
          })
        )

        this.dataLayer(offer)
      },

      dataLayer (id) {
        window.dataLayer.push({
          event: 'addToCart',
          eventCategory: 'claro:tv-pre',
          eventAction: 'enhanced-ecommerce',
          eventLabel: `card:contratar:${id}`,
          ecommerce: {
            ec: 'setAction',
            add: {
              products: [
                {
                  name: String(this.getProduct(id, 'title').nome)
                    .replace(/ /g, '')
                    .toLowerCase(),
                  id: String(this.getProduct(id, 'plan')).replace(/ /g, ''),
                  price: String(this.getProduct(id, 'price').default).replace(
                    /,|\./g,
                    '.'
                  ),
                  brand: 'meu-plano-claro',
                  category: 'tvpre',
                  quantity: '1'
                }
              ]
            }
          }
        })
      }
    }
  }
</script>
