<template>
  <div>
    <v-card
      v-if="$vuetify.breakpoint.lgAndUp"
      class="mx-1 flat width-300"
    >
      <v-toolbar
        flat
        color="red"
        height="10"
      />

      <div>
        <v-layout
          column
        >
          <v-flex
            class="grey lighten-3 font-size-18 py-3"
            v-html="param[0].title"
          />

          <v-flex
            v-for="(next, i) in param[0].itens"
            :key="i"
            :class="`py-3 card-shadow`"
          >
            <v-layout
              v-if="keyFind(next, 'icon')"
              align-center
              class="mx-5 min-height-48"
            >
              <v-flex
                class="brand-principal--text"
                xs2
                v-html="next.icon"
              />

              <v-flex
                xs10
                v-html="next.text"
              />
            </v-layout>

            <v-layout
              v-else
              align-center
              class="mx-5 min-height-48"
            >
              <v-flex
                v-html="next"
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-card>

    <div
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-stepper
        v-model="stepper"
        vertical
        non-linear
        class="flat"
      >
        <div
          v-for="(next, i) in param[0][0].itens"
          :key="i"
        >
          <v-stepper-step
            :step="i"
            editable
            :class="`${i !== parseInt(stepper) ? 'elevation-3 brand-principal--text' : 'card-shadow brand-principal white--text'} pb-4 ${i >= 1 && 'pt-4'}`"
            v-html="`
              <span>
                ${next.icon}
              </span>
              <h3 class='pl-3 font-weight-medium '>
                ${next.text}
              </h3>
              <i class='position-absolute right-12 mdn-Icon-baixo mdn-Icon--lg'></i>
            `"
          />

          <v-stepper-content
            :step="i"
            :class="`${i === parseInt(stepper) && 'grey lighten-3'} pa-0 ma-0`"
          >
            <v-layout
              column
              pb-5
            >
              <v-layout
                v-for="(item, index) in param[0].map(o => o.title)"
                v-show="index >= 1"
                :key="index"
                class="white card-shadow mb-3 pa-3"
                row
                align-center
              >
                <v-flex
                  xs10
                  class="font-weight-bold"
                  v-html="item"
                />

                <v-flex
                  xs2
                  v-html="param[0][index].itens[stepper]"
                />
              </v-layout>
            </v-layout>
          </v-stepper-content>
        </div>
      </v-stepper>
    </div>
  </div>
</template>

<script>
  /** @Mixins */
  import find from '@/plugins/mixins/keyfind'
  /** @mixins */

  export default {
    mixins: [
      find
    ],

    props: {
      param: {
        type: Array,
        default: undefined
      }
    },

    data: function () {
      return {
        stepper: '0'
      }
    }
  }
</script>
