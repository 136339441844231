import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const cacheStore = namespace('cacheStoreModule')

@Component({})
export class MixinControlDialogs extends Vue {
  @cacheStore.Getter('CacheDialogs') getCacheDialogs
  @cacheStore.Action('ActionDialogs') setCacheDialogs

  get dialogDDDError () {
    return this.getCacheDialogs('dialogs.dddError')
  }

  set dialogDDDError (value) {
    this.setCacheDialogs({ path: 'dialogs.dddError', value })
  }

  get waitingPaymentStatus () {
    return this.getCacheDialogs('dialogs.waitingPaymentStatus')
  }

  set waitingPaymentStatus (value) {
    this.setCacheDialogs({ path: 'dialogs.waitingPaymentStatus', value })
  }

  get paymentError () {
    return this.getCacheDialogs('dialogs.paymentError')
  }

  set paymentError (value) {
    this.setCacheDialogs({ path: 'dialogs.paymentError', value })
  }

  get dialogApiError () {
    return this.getCacheDialogs('dialogs.apiError')
  }

  set dialogApiError (value) {
    this.setCacheDialogs({ path: 'dialogs.apiError', value })
  }

  get dialogEsimError () {
    return this.getCacheDialogs('dialogs.dialogEsimError')
  }

  set dialogEsimError (value) {
    this.setCacheDialogs({ path: 'dialogs.dialogEsimError', value })
  }

  get dialogDeliveryCepError () {
    return this.getCacheDialogs('dialogs.dialogDeliveryCepError')
  }

  set dialogDeliveryCepError (value) {
    this.setCacheDialogs({ path: 'dialogs.dialogDeliveryCepError', value })
  }
}
