<template>
  <v-dialog
    v-model="dialogState"
    style="z-index:9999; position:relative"
    content-class="flat px-5 max-width-560  "
  >
    <v-toolbar
      color="brand-principal"
      dense
    >
      <v-toolbar-title>
        <v-img
          :src="require(`@/assets/images/logos/logo-claro-branco.png`)"
          :width="80"
          :hidden="30"
          contain
        />
      </v-toolbar-title>

      <v-spacer />
      <v-btn
        color="white"
        flat
        small
        fab
        dark
        @click="dialog=false"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            text-xs-left
          >
            <span class="black-dark--text body-1 font-weight-black">Atenção!</span>
          </v-flex>
          <v-flex
            xs12
            pt-2
            text-xs-left
            data-test-id="layoutTextProdutoIsdisponivel"
          >
            <span class="grey-dark--text caption font-weight-black">Este produto não está disponível para entrega nesta Região</span>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            color="orange"
            @click="close"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .v-toolbar__content
    padding-left 10px !important
    padding-right 5px !important
</style>

<script>
  /** @Mixins */
  import product from '@/plugins/mixins/product'
  /** @Mixins */

  export default {
    mixins: [
      product
    ],
    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      dialogState: {
        get () {
          return this.dialog
        },
        set (val) {
          this.close()
        }
      }
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
