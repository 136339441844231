<template>
  <v-layout
    column
    pb-4
    :align-center="$vuetify.breakpoint.lgAndUp"
  >
    <v-layout
      v-if="$vuetify.breakpoint.lgAndUp"
      text-xs-center
    >
      <v-flex
        v-for="(next, i) in item"
        :key="i"
      >
        <item
          :param="[ next, i ]"
        />
      </v-flex>
    </v-layout>

    <item
      v-if="$vuetify.breakpoint.mdAndDown"
      :param="[ item ]"
    />
  </v-layout>
</template>

<script>
  import Item from '@/components/contents/tvpre/dialogs/faq/slots/FacaRecarga/Item'

  export default {
    components: {
      Item
    },

    props: {
      param: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        item: [
          {
            title: `Onde recarregar`,
            itens: [
              {
                icon: `
                  <i class="mdn-Icon-loja mdn-Icon--lg"></i>
                `,
                text: `
                  Casas Lotéricas
                `
              },

              {
                icon: `
                  <i class="mdn-Icon-telefone-fixo mdn-Icon--lg"></i>
                `,
                text: `
                  Telefone 0800-208-5005 <br /> para 30 dias
                `
              },

              {
                icon: `
                  <i class="mdn-Icon-computador mdn-Icon--lg"></i>
                `,
                text: `
                  Site <br /> <a href="https://clarorecarga.claro.com.br/tv/select-login" target="_blank">www.clarorecarga.com.br</a>
                `
              },

              {
                icon: `
                  <i class="mdn-Icon-localizacao mdn-Icon--lg"></i>
                `,
                text: `
                  + de 200 mil pontos<br>
                  de venda de recarga.
                `
              },

              {
                icon: `
                  <i class="mdn-Icon-celular mdn-Icon--lg"></i>
                `,
                text: `
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.m4u.recarga.claro"
                    target="_blank"
                  >
                    APP Claro recarga
                  </a>, <br /> <a
                    href="https://picpay.com/site/download"
                    target="_blank"
                  >
                    PicPay
                  </a> e <a
                    href="https://play.google.com/store/apps/details?id=br.com.aplic.app"
                    target="_blank"
                  >
                    APLIC
                  </a>
                `
              }
            ]
          },
          {
            title: `Cartão de Crédito`,
            itens: [
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-verificado mdn-Icon--lg red--text"></i>`,
              `<i class="mdn-Icon-verificado mdn-Icon--lg red--text"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`
            ]
          },
          {
            title: `Dinheiro ou Cartão de Débito`,
            itens: [
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-verificado mdn-Icon--lg red--text"></i>`,
              `<i class="mdn-Icon-verificado mdn-Icon--lg red--text"></i>`
            ]
          },
          {
            title: `Dinheiro ou Cartão de Débito CEF`,
            itens: [
              `<i class="mdn-Icon-verificado mdn-Icon--lg red--text"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`,
              `<i class="mdn-Icon-fechar mdn-Icon--lg grey--text text--lighten-2"></i>`
            ]
          }
        ]
      }
    }
  }
</script>
