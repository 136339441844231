<template>
  <div>
    <loading-payment
      v-show="dialog.loading"
      :action="action"
      @tryAgain="v => $emit('tryAgain', v)"
      @actionButton="emit => $emit('actionButton', emit.success)"
      @awaitOrder="emit => $emit('awaitOrder', emit)"
      @closeDialog="closeDialog()"
    />

    <v-dialog
      v-model="dialog.iframe"
      scrollable
      persistent
      max-width="480"
      no-click-animation
      content-class="flat white"
    >
      <v-layout
        wrap
        justify-center
      >
        <v-flex
          xs12
        >
          <v-toolbar
            color="red"
            flat
          >
            <v-toolbar-title>
              <v-layout
                align-baseline
              >
                <v-img
                  :src="require('@/assets/images/logos/logo-claro-branco.png')"
                  :width="80"
                  :hidden="30"
                  contain
                />
                <v-icon
                  color="white"
                  class="pl-3"
                  v-text="'https'"
                />
              </v-layout>
            </v-toolbar-title>

            <v-spacer />

            <v-toolbar-items>
              <v-btn
                v-if="env('development')"
                dark
                large
                flat
                icon
                @click="dataLayer('avancar')"
              >
                <i
                  class="mdn-Icon-seta-direita mdn-Icon--md"
                />
              </v-btn>

              <v-btn
                dark
                large
                flat
                icon
                @click="dataLayer('cancelar')"
              >
                <i
                  class="mdn-Icon-fechar mdn-Icon--md"
                />
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-flex>

        <v-flex
          xs12
        >
          <v-card
            max-width="400"
            :height="$vuetify.breakpoint.smAndDown ? 'calc(100vh - 200px)' : 260"
            style="border-radius:12px;min-height:364px"
            :class="`${$vuetify.breakpoint.mdAndUp ? 'pt-3 mt-5 pt-1' : 'mt-3'} pb-2 mx-auto`"
            color="#e33c39"
            dark
            flat
          >
            <v-layout
              fill-height
              wrap
              justify-center
            >
              <v-flex
                xs12
              >
                <v-layout
                  wrap
                  :justify-center="$vuetify.breakpoint.smAndDown"
                >
                  <v-flex
                    v-show="/front/.test(stepCard)"
                    :align-self-start="$vuetify.breakpoint.mdAndUp"
                    :pl-2="$vuetify.breakpoint.mdAndUp"
                    shrink
                  >
                    <!-- Imagem do chip ainda temporaria trocar -->
                    <v-img
                      v-position.relative
                      v-top="$vuetify.breakpoint.smAndDown ? 20 : -4"
                      :src="require('@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-chip.png?lazy')"
                      contain
                      height="52"
                      width="64"
                      :style="`transform: rotate(${$vuetify.breakpoint.smAndDown ? '90deg' : 0})`"
                    />
                  </v-flex>

                  <v-flex
                    v-show="/back/.test(stepCard)"
                    xs12
                  >
                    <v-card
                      height="40"
                      xs12
                      class="brown darken-4 hidden-sm-and-down"
                      flat
                    />

                    <v-layout
                      hidden-md-and-up
                      pt-4
                      justify-center
                    >
                      <v-img
                        :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-${validate.brand || 'cartao'}.png?lazy`)"
                        contain
                        max-height="62"
                        max-width="102"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                align-self-end
                xs11
              >
                <v-form
                  v-model="validate.formCard"
                  @submit.prevent
                >
                  <v-tooltip
                    v-if="ENV('development')"
                    bottom
                    color="white"
                  >
                    <template
                      v-slot:activator="{ on }"
                    >
                      <v-icon
                        small
                        color="white"
                        v-on="on"
                      >
                        help_outline
                      </v-icon>
                    </template>

                    <v-card
                      flat
                      color="white"
                      light
                    >
                      4753-1682-4411-4480<br>
                      teste da silva<br>
                      01/2025<br>
                      123<br>
                    </v-card>
                  </v-tooltip>

                  <v-layout
                    v-if="/front/.test(stepCard)"
                    align-end
                    fill-height
                    wrap
                  >
                    <v-flex
                      xs12
                      md8
                    >
                      <span
                        class="caption text-xs-left font-weight-regular"
                        v-text="'Número do cartão'"
                      />
                      <v-text-field
                        v-model="customerPaymentCardData.number"
                        data-test-id="numeroCartao"
                        :rules="[InputRules('required'), validate.number]"
                        color="white"
                        placeholder="####-####-####-####"
                        mask="####-####-####-####"
                        type="tel"
                        single-line
                        hide-details
                        class="pa-0 ma-0 mb-1"
                        autocomplete="no"
                      />
                    </v-flex>

                    <v-flex
                      pt-2
                      xs12
                    >
                      <v-layout
                        row
                        wrap
                        :justify-end="$vuetify.breakpoint.smAndDown"
                      >
                        <v-flex
                          :pt-2="$vuetify.breakpoint.smAndDown"
                          md8
                          xs12
                        >
                          <v-layout
                            column
                            align-content-start
                          >
                            <span
                              class="caption text-xs-left font-weight-regular"
                              v-text="'Títular do cartão'"
                            />
                            <v-text-field
                              v-model="customerPaymentCardData.holderName"
                              data-test-id="titularCartao"
                              :rules="[InputRules('required'), validate.holderName]"
                              color="white"
                              placeholder="Nome impresso no cartão"
                              single-line
                              hide-details
                              class="pa-0 ma-0 mb-1"
                              autocomplete="no"
                            />
                          </v-layout>
                        </v-flex>

                        <v-spacer />

                        <v-flex
                          :pt-4="$vuetify.breakpoint.smAndDown"
                          xs4
                          md3
                        >
                          <v-layout
                            column
                            wrap
                          >
                            <v-dialog
                              ref="customerPaymentCardDataExpirationDialog"
                              v-model="customerPaymentCardData.expirationPicker"
                              :return-value.sync="customerPaymentCardData.expiration"
                              width="290"
                              full-width
                              persistent
                              lazy
                            >
                              <template
                                v-slot:activator="{ on }"
                              >
                                <span
                                  class="caption text-xs-left font-weight-regular"
                                  v-text="'Vencimento'"
                                />
                                <v-text-field
                                  v-model="customerPaymentCardData.expiration"
                                  data-test-id="dataVencimento"
                                  :rules="[InputRules('required'), validate.expiration]"
                                  color="white"
                                  placeholder="MM/AAAA"
                                  mask="##/####"
                                  type="tel"
                                  single-line
                                  hide-details
                                  class="pa-0 ma-0 mb-1"
                                  autocomplete="no"
                                  v-on="on"
                                />
                              </template>

                              <v-date-picker
                                ref="customerPaymentCardDataExpirationPicker"
                                :min="`${new Date().getFullYear()}${new Date().getMonth()}`"
                                locale="pt-br"
                                no-title
                                type="month"
                                @change="customerPaymentCardDataExpirationSave"
                              />
                            </v-dialog>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    v-if="/back/.test(stepCard)"
                    :pb-5="$vuetify.breakpoint.smAndDown"
                    align-end
                    fill-height
                    wrap
                  >
                    <v-card
                      v-top="0"
                      width="60"
                      style="position: absolute; height: calc(100vh - 200px)"
                      xs2
                      flat
                      tile
                      class="brown darken-4 hidden-md-and-up"
                    />

                    <v-flex
                      md12
                      xs11
                    >
                      <v-layout
                        align-end
                        fill-height
                        wrap
                        :justify-end="$vuetify.breakpoint.smAndDown"
                      >
                        <v-flex
                          xs12
                        >
                          <v-layout
                            justify-end
                            wrap
                          >
                            <v-flex
                              xs12
                              text-xs-right
                            >
                              <span
                                class="caption text-xs-left font-weight-regular"
                                v-text="'Código de segurança'"
                              />
                            </v-flex>

                            <v-flex
                              v-width.max="80"
                              shrink
                            >
                              <v-text-field
                                v-model="customerPaymentCardData.cvv"
                                data-test-id="codigoCVV"
                                :rules="[InputRules('required')]"
                                color="white"
                                placeholder="CVV"
                                mask="####"
                                type="tel"
                                single-line
                                hide-details
                                autocomplete="no"
                                class="pa-0 ma-0 mb-1"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex
                          md12
                          shrink
                          hidden-sm-and-down
                        >
                          <v-img
                            :src="require(`@/assets/images/bandeiras/cartao-credito/pagamento-cartao-credito-${validate.brand || 'cartao'}.png?lazy`)"
                            contain
                            height="32"
                            width="64"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>

              <v-flex
                align-self-end
                xs11
              >
                <v-layout
                  justify-end
                >
                  <v-btn
                    v-show="/back/.test(stepCard)"
                    color="transparent"
                    class="mx-0 px-2"
                    depressed
                    small
                    @click="stepCard = 'front'"
                  >
                    <i
                      class="mdn-Icon-seta-esquerda mdn-Icon--md pr-2"
                    />
                    <span
                      class="caption font-weight-light text-none"
                      v-text="'Voltar'"
                    />
                  </v-btn>

                  <v-btn
                    color="transparent"
                    class="mx-0 px-2 fix--buttom-disabled-background"
                    data-test-id="bntContinuarPagamento"
                    depressed
                    small
                    :disabled="!validate.formCard"
                    @click="/front/.test(stepCard) ? stepCard = 'back': createScriptCheckout()"
                  >
                    <span
                      class="caption font-weight-light text-none"
                      v-text="'Continuar'"
                    />
                    <i
                      class="mdn-Icon-seta-direita mdn-Icon--md pl-2"
                    />
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex
          shrink
          py-2
        >
          <v-img
            :src="require('@/assets/images/information/ssl-site-seguro.png?lazy')"
            width="96"
            contain
          />
        </v-flex>
      </v-layout>
    </v-dialog>

    <v-dialog
      v-model="dialog.iframePix"
      scrollable
      persistent
      max-width="480"
      no-click-animation
      content-class="flat white"
    >
      <v-layout
        wrap
        justify-center
      >
        <v-flex
          xs12
        >
          <v-toolbar
            color="red"
            flat
          >
            <v-toolbar-title>
              <v-layout
                align-baseline
              >
                <v-img
                  :src="require('@/assets/images/logos/logo-claro-branco.png')"
                  :width="80"
                  :hidden="30"
                  contain
                />
                <v-icon
                  color="white"
                  class="pl-3"
                  v-text="'https'"
                />
              </v-layout>
            </v-toolbar-title>

            <v-spacer />

            <v-toolbar-items>
              <v-btn
                v-if="env('development')"
                dark
                large
                flat
                icon
                @click="dataLayer('avancar')"
              >
                <i
                  class="mdn-Icon-seta-direita mdn-Icon--md"
                />
              </v-btn>

              <v-btn
                dark
                large
                flat
                icon
                @click="dataLayer('cancelar')"
              >
                <i
                  class="mdn-Icon-fechar mdn-Icon--md"
                />
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-flex>

        <v-flex
          xs12
        >
          <v-card
            flat
          >
            <v-layout
              wrap
              justify-center
              align-center
            >
              <v-flex
                xs12
                text-xs-center
              >
                <qr-code
                  v-if="qrCodePixOnline"
                  :value="paymentPixData.qrcode"
                  :size="$vuetify.breakpoint.smAndDown ? '300' : '400'"
                />

                <v-progress-circular
                  v-else
                  :size="$vuetify.breakpoint.smAndDown ? 100 : 200"
                  width="7"
                  color="red"
                  class="ma-5"
                  indeterminate
                />
              </v-flex>

              <v-flex
                xs10
              >
                <v-text-field
                  id="paymentPixCopiaeColaLinhaDigitavel"
                  v-height="68"
                  :value="paymentPixData.qrcode"
                  label="PIX Copia e Cola"
                  readonly
                  color="grey lighten-4"
                  append-icon="mdn-Icon-duplicar mdn-Icon--md"
                  messages="Copie este código e cole no seu aplicativo de pagamento"
                  @click:append="copy('paymentPixCopiaeColaLinhaDigitavel')"
                  @click="copy('paymentPixCopiaeColaLinhaDigitavel')"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex
          xs12
        >
          <v-img
            :src="require('@/assets/images/information/ssl-site-seguro.png?lazy')"
            width="96"
            class="mx-auto pt-3"
            contain
          />
        </v-flex>
      </v-layout>
    </v-dialog>
  </div>
</template>

<style lang="stylus">
  .card
    position: relative;
    perspective: 1000px;
  .card.flip
    transition: transform 0.5s;
    transform-style: preserve-3d;
  .front-card, .back-card
    position: absolute;
    backface-visibility: hidden;
  .back-card
    transform: rotateY(180deg);
  .tarja
    height: 35px;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
  .fix--buttom-disabled-background.theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline)
    background-color: transparent !important
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import api from '@/plugins/axios/api'
  import LoadingPayment from '@/components/contents/tvpre/upnid/PaymentLoading'
  import testbrand from '@/plugins/mixins/testbrand'
  import rule from '@/plugins/mixins/rule'
  import keyFind from '@/plugins/mixins/keyfind'
  import VueQRCodeComponent from 'qrcode.vue'

  Vue.component('qr-code', VueQRCodeComponent)

  // eslint-disable-next-line no-unused-vars
  const ENV = (env) => window.env(env)

  export default {
    components: {
      LoadingPayment
    },
    mixins: [
      testbrand,
      rule,
      keyFind
    ],

    props: {
      payment: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        LOG: (...v) => window.log(...v, 'pix'),
        ENV: ENV,
        stepCard: 'front',
        customerPaymentCardData: {
          holderName: '',
          number: '',
          expiration: '',
          expirationPicker: '',
          expirationMonth: '',
          expirationYear: '',
          cvv: ''
        },
        paymentPixData: {
          qrcode: '',
          dataExpiracao: ''
        },
        qrCodePixOnline: false,
        qrCodePixAddress: '',
        dialog: {
          iframe: false,
          iframePix: false,
          loading: false
        },
        validate: {
          formCard: false,
          number: 'false',
          holderName: 'false',
          expiration: 'false',
          brand: false
        },
        action: undefined
      }
    },

    computed: {
      ...mapState('Payload', [ 'payload' ]),

      checkQRCode: {
        get () {
          try {
            /**
             * Tal função não deverá ser utilizada preferencialmente pois
             * o método new XMLHttpRequest() pode não existir no navegador
             * ele está presente nos navegadores Chromium mas não é certo
             * que esteja em todos os outros
            */

            const REQ_LISTENER = (e) => this.LOG('Evento recebido em checkQRCode', e)

            this.LOG('Teste a url da imagem QRCode PIX')
            if (!this.paymentPixData.qrcode) return false

            let request = new XMLHttpRequest()
            request.addEventListener('load', REQ_LISTENER)
            request.open('GET', this.qrCodePixAddress, false)
            request.send()
            if (request.status !== 200) return false
            this.LOG(request.responseURL)

            request.removeEventListener('load', REQ_LISTENER)
            return request.responseURL
          } catch (error) {
            this.LOG('error on', __dirname, error)
            return false
          }
        }
      }
    },

    watch: {
      'customerPaymentCardData.number' () {
        this.creditCard(this.customerPaymentCardData.number).then(response => {
          this.LOG('response ao digitar o cartão %o', response)
          this.validate.number = true
          this.validate.brand = response.brand
        }).catch(e => {
          this.LOG('error ao digitar o cartão %o', e.message)
          this.validate.brand = false
        })
      },

      'customerPaymentCardData.holderName' () {
        const pattern = /^[a-záàâãéèêíïóôõöúüçñ.]{1,}(\s[a-záàâãéèêíïóôõöúüçñ.]{1,})+$/i
        this.customerPaymentCardData.holderName = this.customerPaymentCardData.holderName.trim()

        if (pattern.test(this.customerPaymentCardData.holderName)) {
          this.validate.holderName = true
        } else {
          this.validate.holderName = 'Nome inválido'
        }
      },

      'customerPaymentCardData.expirationPicker' (value) {
        value && setTimeout(() => (this.$refs.customerPaymentCardDataExpirationPicker.activePicker = 'YEAR'))
      },

      'customerPaymentCardData.expiration' () {
        const pattern = /^(?:(?:31([-/.]?)(?:0[13578]|1[02]))\1|(?:(?:29|30)([-/.]?)(?:0[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29([-/.]?)02\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26]))))$|^(?:0[1-9]|1\d|2[0-8])([-/.]?)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/i
        this.customerPaymentCardData.expiration = this.customerPaymentCardData.expiration.trim()

        if (pattern.test(`01${this.customerPaymentCardData.expiration}`)) {
          let date = new Date()
          let month = Number(this.customerPaymentCardData.expiration.substring(0, 2))
          let year = Number(this.customerPaymentCardData.expiration.substring(2, 6))
          let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
          if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29

          let timeBreak
          if (year === date.getFullYear() && month < date.getMonth()) timeBreak = 'Data inválida'
          if (year < date.getFullYear() || month === 0 || month > 12) timeBreak = 'Data inválida'

          this.validate.expiration = timeBreak || true
        } else {
          this.validate.expiration = 'Data inválida'
        }
      }
    },

    mounted () {
      if (/cartao/.test(this.payload.pedido.pagamento.formaPagamento)) {
        this.dialog.iframe = true
      }

      if (/boleto/.test(this.payload.pedido.pagamento.formaPagamento)) {
        this.dialog.loading = true
        this.createPagamentoBoleto()
      }

      if (/pix/.test(this.payload.pedido.pagamento.formaPagamento)) {
        this.LOG('preciso pegar os dados de PIX')
        this.dialog.loading = true
        this.createPagamentoPix()
      }
    },

    methods: {
      getPaymentURL () {
        const urlPagamento = this.payment.urlCheckout
        if (!urlPagamento) {
          this.receiveError({ message: 'url de pagamento não recebida' })
          return
        }

        window.addEventListener('message', this.receiveMessage, false)
        return urlPagamento
      },

      dataLayer (param) {
        if (param === 'avancar') {
          this.receiveSuccess({
            type: this.payload.pedido.pagamento.formaPagamento,
            detail: {
              sale: this.payload._id
            }
          })
        }

        if (param === 'cancelar') {
          this.removeListen()

          if (/^pagamento$/.test(this.$route.name)) return location.reload()

          this.$emit('actionButton', 'voltar')
        }
      },

      receiveSuccess (e) {
        if (e.detail) {
          if (e.detail.sale) {
            this.action = {
              success: e.type,
              sale: e.detail.sale
            }

            this.removeListen()
          }
        }
      },

      receiveError (e) {
        this.action = {
          error: true,
          message: e.message
        }

        this.removeListen()
      },

      receiveMessage (event) {
        if (event.origin !== 'https://upnid.com') return

        // function receiveMessage (event) {
        //   if (event.origin !== 'https://upnid.com') {
        //     return
        //   }

        // `event.data` poderá ter um dos seguintes valores:
        // - PAYMENT_PAID quando o pagamento for pago
        // - PAYMENT_PENDING quando o pagamento estiver aguardando pagamento

        //   if (event.data === 'PAYMENT_PENDING') {
        //     console.log('pendente')
        //   }
        // }

        if (event.data === 'PAYMENT_PAID') {
          this.removeListen()

          window.log({ delay: true, message: 'PAYMENT_PAID' })
          this.dataLayer('avancar')
        }

        if (event.data === 'PAYMENT_PENDING') {
          this.removeListen()

          window.log({ delay: true, message: 'PAYMENT_PENDING' })
          this.dataLayer('avancar')
        }
      },

      removeListen () {
        window.removeEventListener('message', this.receiveMessage, false)
      },

      createPagamentoBoleto () {
        async function contactApi (payload) {
          const { data } = await api.post('sale/payment/start', payload)
          return data
        }

        contactApi(
          {
            _id: this.payload._id
          }
        ).then(response => {
          this.LOG('dados recebidos no createPagamentoBoleto', response)
          if (!this.keyFind(response, '_id')) throw Error('pedido-invalido')
          this.dataLayer('avancar')
        }).catch(e => {
          this.LOG('erro ocorrido no contactApi', e)
          this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
        })
      },

      createPagamentoPix () {
        async function contactApi (payload) {
          const { data } = await api.post('sale/payment/start', payload)
          return data
        }

        contactApi({
          _id: this.payload._id
        }).then(response => {
          if (!response.pedido.pagamento.pix) throw Error('pix-nao-encontrado')
          if (!response.pedido.pagamento.pix.qrcode) throw Error('pix-nao-encontrado')

          this.paymentPixData.qrcode = response.pedido.pagamento.pix.qrcode
          this.paymentPixData.dataExpiracao = response.pedido.pagamento.pix.dataExpiracao

          this.qrCodePixOnline = true
          this.dialog.iframePix = true
          this.dataLayer('avancar')
        }).catch(error => {
          window.log('error on - contactApi', error)
          this.dialog.iframePix = false
          this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
        })
      },

      createScriptCheckoutUpnid () {
        async function contactApi (payload) {
          const { data } = await api.post('sale/payment/start', payload)
          return data
        }

        this.dialog.iframe = false
        this.dialog.loading = true

        if (document.getElementById('upnidcheckoutscript') === null) {
          let script = document.createElement('script')
          script.setAttribute('src', 'https://js.upnid.com/v0.js')
          script.setAttribute('id', 'upnidcheckoutscript')
          document.body.appendChild(script)
        }

        setTimeout(() => {
          if (document.getElementById('upnidcheckoutscript')) {
            // eslint-disable-next-line no-undef
            const upnid = new Upnid({
              clientKey: process.env.VUE_APP_UPNID_CK_LIVE
            })
            const token = upnid.token.createToken({
              card: {
                holderName: this.customerPaymentCardData.holderName,
                number: this.customerPaymentCardData.number,
                expirationMonth: this.customerPaymentCardData.expiration.substring(0, 2),
                expirationYear: this.customerPaymentCardData.expiration.substring(2, 6),
                cvv: this.customerPaymentCardData.cvv
              }
            })

            token.then(response => {
              this.LOG('dados retornados da Upnid %o', response)

              if (response.errors) {
                this.LOG('emite mensagem de erro')
                this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
                return
              }

              if (response.data && response.data.createToken) {
                contactApi(
                  {
                    _id: this.payload._id,
                    createToken: response.data.createToken
                  }
                ).then(() => {
                  this.dataLayer('avancar')
                }).catch(e => {
                  this.LOG('erro ocorrido no contactApi', e)
                  this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
                })
              }
            }).catch(e => {
              this.LOG('erro ocorrido no token', e)
              this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
            })
          }
        }, 960)
      },

      createScriptCheckout () {
        async function contactApi (payload) {
          const { data } = await api.post('sale/payment/start', payload)
          return data
        }

        this.dialog.iframe = false
        this.dialog.loading = true

        if (document.getElementById('pagarmecheckoutscript') === null) {
          let script = document.createElement('script')
          script.setAttribute('src', 'https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js')
          script.setAttribute('id', 'pagarmecheckoutscript')
          document.body.appendChild(script)
        }

        setTimeout(() => {
          if (document.getElementById('pagarmecheckoutscript')) {
            if (window.pagarme) {
              window.pagarme.client.connect({
                encryption_key: process.env.VUE_APP_PAGARME_EK
              }).then(client => client.security.encrypt({
                card_holder_name: this.customerPaymentCardData.holderName,
                card_number: this.customerPaymentCardData.number,
                card_expiration_date: String(this.customerPaymentCardData.expiration).replace(/\D/g, ''),
                card_cvv: this.customerPaymentCardData.cvv
              })).then(response => {
                this.LOG('dados retornados da PagarMe %o', response)

                if (response.errors) {
                  this.LOG('emite mensagem de erro')
                  this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
                  return
                }

                if (response) {
                  contactApi(
                    {
                      _id: this.payload._id,
                      createToken: response
                    }
                  ).then(() => {
                    this.dataLayer('avancar')
                  }).catch(e => {
                    this.LOG('erro ocorrido no contactApi', e)
                    this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
                  })
                }
              }).catch(e => {
                this.LOG('erro ocorrido no token', e)
                this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
              })
            } else {
              this.LOG('erro ocorrido no token')
              this.receiveError({ message: 'Pagamento não efetuado, por favor, tente novamente' })
            }
          }
        }, 980)
      },

      customerPaymentCardDataExpirationSave (date) {
        let [year, month] = date.split('-')
        this.$refs.customerPaymentCardDataExpirationDialog.save(this.customerPaymentCardData.expiration = `${month}${year}`)
      },

      closeDialog () {
        this.LOG('vou fechar os dialogos de iframe')
        this.LOG(this.dialog.iframe)
        this.LOG(this.dialog.iframePix)

        this.dialog.iframe = false
        this.dialog.iframePix = false
      },

      copy (id) {
        document.getElementById(id).select()
        document.execCommand('copy')
      },

      share (param) {
        this.LOG('Compartilhe o QRCode')
        this.LOG(navigator)
        if (navigator && !navigator.share) return
        this.LOG('compartilhe os dados', param)

        navigator.share({
          title: param && param.hasOwnProperty('title') ? param.title : 'Claro TV Pré',
          text: param && param.hasOwnProperty('text') ? param.text : 'Tenha o melhor da TV por assinatura, sem assinatura.',
          url: param && param.hasOwnProperty('url') ? param.url : location.href
        })
      }
    }
  }
</script>
