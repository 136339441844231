<template>
  <v-card
    v-height.max="$vuetify.breakpoint.mdAndUp && 540"
    :style="$vuetify.breakpoint.mdAndUp && 'border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75); border-radius: 6px'"
    :flat="$vuetify.breakpoint.smAndDown"
  >
    <v-layout
      fill-height
      column
      :style="$vuetify.breakpoint.mdAndUp && 'border-left: 8px solid #D52B1E'"
      :pl-4="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex
        xs12
        hidden-md-and-up
      >
        <strong
          class="text-uppercase"
          v-text="'Produto selecionado'"
        />

        <v-divider
          class="my-3 mr-5"
        />
      </v-flex>

      <v-flex
        mt-3
      >
        <h2
          class="text-uppercase"
          style="font-size: 18px !important; font-weight: 700 !important"
        >
          {{ getProduct(payload.pedido.produto[0].id, 'title').nome }}
        </h2>
      </v-flex>

      <v-flex>
        <v-img
          contain
          :src="getProduct(payload.pedido.produto[0].id, 'cover')[0]"
          height="114"
          width="230"
        />
      </v-flex>

      <v-flex
        :px-3="$vuetify.breakpoint.mdAndUp"
      >
        <v-layout
          align-baseline
          fill-height
          wrap
        >
          <v-flex xs12>
            <v-layout
              align-baseline
              fill-height
              wrap
            >
              <v-flex
                xs8
                class="position-relative top-10"
              >
                <h4
                  style="font-size: 18px !important; font-weight: 700 !important"
                  v-text="currency({ prefix: 'BRL', valor: parseFloat(payload.pedido.produto[0].preco) })"
                />

                <small
                  v-if="payload.consumidor.endereco.cep"
                  v-text="`Você só paga ${currency({ prefix: 'BRL', valor: payload.pedido.postagem.valorEntrega})} de frete ${pedido.produto.quantidade > 1 ? `nos ${pedido.produto.quantidade} itens` : ''}`"
                />

                <h5
                  style="font-size: 14px !important; font-weight: 400 !important"
                  v-text="'Parcele em até 10x sem juros*'"
                />
              </v-flex>

              <v-flex
                xs4
              >
                <h5
                  style="font-size: 14px !important; font-weight: 400 !important"
                  v-text="'Quantidade:'"
                />
                <!-- v-text="verificaPedidoComPontoAdicional() ? 'Receptor:' : 'Quantidade:'" -->

                <v-layout
                  class="v-card border-radius-4 py-2  max-width-100"
                  style="border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)"
                  text-xs-center
                >
                  <v-flex>
                    <v-btn
                      flat
                      small
                      icon
                      class="pa-0 ma-0"
                      :disabled="verificaPedidoComPontoAdicional()"
                      @click="pedido.produto.quantidade < (verificaPedidoComPontoAdicional() ? 2 : 3) && pedido.produto.quantidade++"
                    >
                      <i
                        class="mdn-Icon-mais mdn-Icon--sm blue--text cursor-pointer"
                      />
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <v-btn
                      flat
                      small
                      icon
                      class="pa-0 ma-0"
                    >
                      {{ padLeft(String(verificaQuantidadePedido()), "00") }}
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <v-btn
                      flat
                      small
                      icon
                      class="pa-0 ma-0"
                      :disabled="verificaPedidoComPontoAdicional()"
                      @click="pedido.produto.quantidade > (verificaPedidoComPontoAdicional() ? 0 : 1) && pedido.produto.quantidade--"
                    >
                      <i
                        class="mdn-Icon-menos mdn-Icon--sm blue--text cursor-pointer"
                      />
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex
            v-show="false"
            xs11
            style="line-height: 12px"
            pt-1
          >
            <small
              v-text="'Ponto adicional, agora tem! Assista mais de 160 canais em até 3 pontos com uma única recarga!'"
            />
          </v-flex>
        </v-layout>
      </v-flex>

      <v-divider
        class="my-3 mr-5"
      />

      <v-form
        ref="formulario_contrato"
        v-model="formulario.validate.step.contrato"
      >
        <v-flex
          px-3
          class="max-height-64"
        >
          <v-checkbox
            id="ipt_gtm_modal_event_contrato_instalacao"
            v-model="pedido.ciente.instalacao"
            :color="getProp('ipt_gtm_modal_event_contrato_instalacao', 'valid') ? 'brand-principal' : 'success'"
            :rules="[ inputRules('required') ]"
            required

            class="gtm-element-event pa-0 ma-0"
            tabindex="0"

            data-gtm-event-category="claro:meu-plano-claro"
            data-gtm-event-action="preencheu"
            data-gtm-event-label="contrato-instalacao"
            data-test-id="checkBoxProduto1"

            @change="dataLayer('contrato-instalacao')"
          >
            <template
              v-slot:label
            >
              <v-layout
                column
              >
                <h5
                  class="font-weight-regular grey-dark--text font-size-12"
                >
                  <span>
                    Estou ciente de que a instalação do produto deve ser feita por um instalador profissional através do
                    <a
                      class="underline blue--text"
                      @click="openFaq('contrate-tecnico')"
                      v-text="`Técnico virtual`"
                    />.
                  </span>
                </h5>
              </v-layout>
            </template>
          </v-checkbox>
        </v-flex>

        <v-flex
          px-3
          class="max-height-64"
        >
          <v-checkbox
            id="ipt_gtm_modal_event_contrato_recarga"
            v-model="pedido.ciente.recarga"
            :color="getProp('ipt_gtm_modal_event_contrato_recarga', 'valid') ? 'brand-principal' : 'success'"
            :rules="[ inputRules('required') ]"
            required

            class="gtm-element-event pa-0 ma-0"
            tabindex="0"

            data-gtm-event-category="claro:meu-plano-claro"
            data-gtm-event-action="preencheu"
            data-gtm-event-label="contrato-recarga"
            data-test-id="checkBoxProduto2"

            @change="dataLayer('contrato-recarga')"
          >
            <template
              v-slot:label
            >
              <v-layout
                column
              >
                <h5
                  class="font-weight-regular grey-dark--text font-size-12"
                >
                  <span>
                    Estou ciente de que será necessário realizar uma
                    <a
                      class="underline blue--text"
                      @click="openFaq('faca-recarga')"
                      v-text="`recarga`"
                    />
                    logo após a instalação para funcionamento do equipamento e uso da TV Pré.
                  </span>
                </h5>
              </v-layout>
            </template>
          </v-checkbox>
        </v-flex>
      </v-form>

      <v-flex>
        <v-layout wrap>
          <v-divider
            class="mb-3 mr-5"
          />

          <v-flex
            px-3
            xs12
          >
            <h5 v-text="'Valor total:'" />

            <h4
              style="font-size: 18px !important; font-weight: 700 !important"
              v-text="currency({ prefix: 'BRL', valor: calculatedTotal() })"
            />
            <!-- verificaPedidoComPontoAdicional() ? calculatedTotal(true) :  -->
            <h5
              style="font-size: 14px !important; font-weight: 400 !important"
              v-text="'Parcele em até 10x sem juros*'"
            />
            <small
              v-if="payload.consumidor.endereco.cep"
              class="d-block"
              v-text="`(Frete de ${currency({ prefix: 'BRL', valor: payload.pedido.postagem.valorEntrega})} ${pedido.produto.quantidade > 1 ? `para os ${pedido.produto.quantidade} itens` : ''} já incluso)`"
            />

            <span
              class="grey--text caption font-weight-black"
              v-text="'*no cartão de crédito'"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
  export default {
    props: {
      getProduct: {
        type: Function,
        default: null
      },
      verificaPedidoComPontoAdicional: {
        type: Function,
        default: null
      },
      verificaQuantidadePedido: {
        type: Function,
        default: null
      },
      currency: {
        type: Function,
        default: null
      },
      calculatedTotal: {
        type: Function,
        default: null
      },
      padLeft: {
        type: Function,
        default: null
      },
      getProp: {
        type: Function,
        default: null
      },
      inputRules: {
        type: Function,
        default: null
      },
      dataLayer: {
        type: Function,
        default: null
      },
      openFaq: {
        type: Function,
        default: null
      },
      payload: {
        type: Object,
        default: null
      },
      pedido: {
        type: Object,
        default: null
      },
      routePromo: {
        type: Object,
        default: null
      },
      formulario: {
        type: Object,
        default: null
      }
    }
  }
</script>
