const array = [
  'SP', 11, 12, 13, 14, 15, 16, 17, 18, 19,
  'RJ', 21, 22, 24,
  'ES', 27, 28,
  'MG', 31, 32, 33, 34, 35, 37, 38,
  'PR', 41, 42, 43, 44, 45, 46,
  'SC', 47, 48, 49,
  'RS', 51, 53, 54, 55,
  'DF', 61,
  'GO', 62, 64,
  'TO', 63,
  'MT', 65, 66,
  'MS', 67,
  'AC', 68,
  'RO', 69,
  'BA', 71, 73, 74, 75, 77,
  'SE', 79,
  'PE', 81, 87,
  'AL', 82,
  'PB', 83,
  'RN', 84,
  'CE', 85, 88,
  'PI', 86, 89,
  'PA', 91, 93, 94,
  'AP', 92, 96,
  'RR', 95,
  'AM', 97,
  'MA', 98, 99
]

const object = [
  { sigla: 'AC', name: [ 'Acre' ], ddd: [ 68 ] },
  { sigla: 'AL', name: [ 'Alagoas' ], ddd: [ 82 ] },
  { sigla: 'AM', name: [ 'Amazonas' ], ddd: [ 97 ] },
  { sigla: 'AP', name: [ 'Amapá', 'Amapa' ], ddd: [ 92, 96 ] },
  { sigla: 'BA', name: [ 'Bahia' ], ddd: [ 71, 73, 74, 75, 77 ] },
  { sigla: 'CE', name: [ 'Ceará', 'Ceara' ], ddd: [ 85, 88 ] },
  { sigla: 'DF', name: [ 'Distrito Federal' ], ddd: [ 61 ] },
  { sigla: 'ES', name: [ 'Espírito Santo', 'Espirito Santo' ], ddd: [ 27, 28 ] },
  { sigla: 'GO', name: [ 'Goiás', 'Goias' ], ddd: [ 62, 64 ] },
  { sigla: 'MA', name: [ 'Maranhão', 'Maranhao' ], ddd: [ 98, 99 ] },
  { sigla: 'MG', name: [ 'Minas Gerais' ], ddd: [ 31, 32, 33, 34, 35, 37, 38 ] },
  { sigla: 'MS', name: [ 'Mato Grosso do Sul' ], ddd: [67] },
  { sigla: 'MT', name: [ 'Mato Grosso' ], ddd: [ 65, 66 ] },
  { sigla: 'PA', name: [ 'Pará', 'Para' ], ddd: [ 91, 93, 94 ] },
  { sigla: 'PB', name: [ 'Paraíba', 'Paraiba' ], ddd: [ 83 ] },
  { sigla: 'PE', name: [ 'Pernambuco' ], ddd: [ 81, 87 ] },
  { sigla: 'PI', name: [ 'Piauí', 'Piaui' ], ddd: [ 86, 89 ] },
  { sigla: 'PR', name: [ 'Paraná', 'Parana' ], ddd: [ 41, 42, 43, 44, 45, 46 ] },
  { sigla: 'RJ', name: [ 'Rio de Janeiro' ], ddd: [ 21, 22, 24 ] },
  { sigla: 'RN', name: [ 'Rio Grande do Norte' ], ddd: [ 84 ] },
  { sigla: 'RO', name: [ 'Rondônia', 'Rondonia' ], ddd: [ 69 ] },
  { sigla: 'RR', name: [ 'Roraima' ], ddd: [ 95 ] },
  { sigla: 'RS', name: [ 'Rio Grande do Sul' ], ddd: [ 51, 53, 54, 55 ] },
  { sigla: 'SC', name: [ 'Santa Catarina' ], ddd: [ 47, 48, 49 ] },
  { sigla: 'SE', name: [ 'Sergipe' ], ddd: [ 79 ] },
  { sigla: 'SP', name: [ 'São Paulo', 'Sao Paulo' ], ddd: [ 11, 12, 13, 14, 15, 16, 17, 18, 19 ] },
  { sigla: 'TO', name: [ 'Tocantins' ], ddd: [ 63 ] }
]

export {
  array,
  object
}
