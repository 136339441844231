<template>
  <div>
    <v-card
      flat
    >
      <v-flex
        :class="`${$vuetify.breakpoint.lgAndUp && 'grey lighten-4'}`"
      >
        <v-card-title
          :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 1024} mx-auto px-0`"
          primary-title
        >
          <div>
            <h2
              :class="`font-size-24 font-weight-bold text-none grey-dark--text ${$vuetify.breakpoint.lgAndUp && 'text-uppercase'}`"
            >
              Bem vindo a Claro.
            </h2>

            <h4
              class="font-size-16 font-weight-medium grey-regular--text hidden-lg-and-up"
            >
              Para iniciar, poderia nos informar alguns dados?
            </h4>
          </div>
        </v-card-title>
      </v-flex>

      <v-spacer
        class="height-16"
      />

      <v-layout
        :class="`max-width-${$vuetify.breakpoint.mdAndDown ? '320 column' : '1024'} mx-auto`"
        wrap
      >
        <v-flex
          order-xs2
          order-md1
          xs12
          md6
          :style="`${$vuetify.breakpoint.lgAndUp && 'overflow-y: auto; height: calc(100vh - 160px)'}`"
        >
          <v-layout
            :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 434}`"
            column
          >
            <v-flex
              hidden-md-and-down
            >
              <h2
                class="font-size-21 font-weight-bold text-none grey-dark--text text-uppercase"
              >
                Vamos começar
              </h2>
            </v-flex>

            <v-flex
              hidden-md-and-down
            >
              <h5
                class="font-size-16 font-weight-regular text-none grey-dark--text"
                style="line-height: 19px"
              >
                Para iniciarmos a compra do seu produto, precisamos de algumas informações pessoais.
              </h5>
            </v-flex>

            <v-spacer
              class="height-28 hidden-md-and-down"
            />

            <v-form
              ref="formulario_dados_contato"
              v-model="formulario.validate.step.dadosContato"
            >
              <v-flex>
                <label
                  class="font-weight-bold"
                >
                  CPF
                </label>

                <v-spacer
                  class="height-6"
                />

                <v-text-field
                  id="ipt_gtm_modal_event_cpf"
                  ref="ipt_gtm_modal_event_cpf"
                  v-model="consumidor.cpf"
                  data-test-id="cpfCliente"
                  name="ipt_gtm_modal_event_cpf"
                  :rules="[ InputRules('required'), formulario.validate.cpf ]"
                  :mask="formulario.validate.mask.cpf"
                  :append-icon="GetProp('ipt_gtm_modal_event_cpf', 'valid') ? 'done' : undefined"
                  :success="GetProp('ipt_gtm_modal_event_cpf', 'valid')"
                  :color="GetProp('ipt_gtm_modal_event_cpf', 'valid') ? 'success' : 'brand-principal'"
                  type="tel"
                  placeholder="___.___.___-__"
                  required

                  :class="`fix-form-input gtm-element-event height-${$vuetify.breakpoint.mdAndDown ? 9 : 8}0`"
                  tabindex="0"
                  outline

                  data-gtm-event-category="claro:meu-plano-claro"
                  data-gtm-event-action="preencheu"
                  data-gtm-event-label="consumidor-cpf"

                  @focus="dataLayer('consumidor-cpf')"
                />
              </v-flex>

              <v-flex>
                <email
                  :identification="'ipt_gtm_modal_event_email'"
                  data-test-id="emailCliente"
                  :label="'E-mail'"
                  :content-class="`fix-form-input gtm-element-event height-${$vuetify.breakpoint.mdAndDown ? 9 : 8}0`"
                  @form="getInput"
                />
              </v-flex>

              <v-flex>
                <label
                  class="font-weight-bold"
                >
                  Número de contato
                </label>

                <v-spacer
                  class="height-6"
                />

                <v-text-field
                  id="ipt_gtm_modal_event_telefone_contato"
                  ref="ipt_gtm_modal_event_telefone_contato"
                  v-model="consumidor.telefone.contato"
                  data-test-id="telefoneContato"
                  name="ipt_gtm_modal_event_telefone_contato"
                  :rules="[ InputRules('required'), formulario.validate.telefone.contato ]"
                  :mask="formulario.validate.mask.tel"
                  :append-icon="GetProp('ipt_gtm_modal_event_telefone_contato', 'valid') ? 'done' : undefined"
                  :success="GetProp('ipt_gtm_modal_event_telefone_contato', 'valid')"
                  :color="GetProp('ipt_gtm_modal_event_telefone_contato', 'valid') ? 'success' : 'brand-principal'"
                  type="tel"
                  placeholder="(XX) XXXXX-XXXX"
                  required

                  :class="`fix-form-input gtm-element-event height-${$vuetify.breakpoint.mdAndDown ? 9 : 8}0`"
                  tabindex="0"
                  outline

                  data-gtm-event-category="claro:meu-plano-claro"
                  data-gtm-event-action="preencheu"
                  data-gtm-event-label="consumidor-telefone-contato"

                  @focus="dataLayer('consumidor-telefone-contato')"
                />
              </v-flex>
            </v-form>
          </v-layout>

          <v-card-actions
            :class="`max-width-${$vuetify.breakpoint.mdAndDown ? 320 : 1024} mx-auto`"
          >
            <v-layout
              :column="$vuetify.breakpoint.mdAndDown"
              :class="`max-width-${$vuetify.breakpoint.lgAndUp && 434}`"
            >
              <v-spacer />

              <v-flex
                order-lg2
                lg4
                :mr-2="$vuetify.breakpoint.lgAndUp"
              >
                <v-btn
                  :id="getName({ source: 'gtmid' })"
                  :class="`${$vuetify.breakpoint.mdAndDown && 'mx-auto'} flat gtm_button font-size-18 font-weight-bold ${getValidateForm() ? 'warning lighten-2 black--text' : 'grey lighten-3 grey--text text--lighten-1 cursor-disabled'}`"
                  block
                  large
                  round
                  :loading="loading.payload"
                  :disabled="!getValidateForm() || loading.payload"
                  data-test-id="continuar"
                  @click="dataLayer('payload')"
                >
                  Continuar
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-flex>

        <v-flex
          hidden-sm-and-down
          order-xs1
          order-md2
          xs12
          md6
          :pl-5="$vuetify.breakpoint.mdAndUp"
        >
          <formPanelQuantidadeContrato
            :get-product="getProduct"
            :payload="payload"
            :pedido="pedido"
            :verifica-pedido-com-ponto-adicional="verificaPedidoComPontoAdicional"
            :currency="currency"
            :calculated-total="calculatedTotal"
            :route-promo="RoutePromo"
            :verifica-quantidade-pedido="verificaQuantidadePedido"
            :pad-left="padLeft"
            :formulario="formulario"
            :get-prop="GetProp"
            :input-rules="InputRules"
            :data-layer="dataLayer"
            :open-faq="openFaq"
          />
        </v-flex>
      </v-layout>
    </v-card>

    <default-uf-warning
      v-if="showDefaultUFWarning"
      :dialog="showDefaultUFWarning"
      @close="showDefaultUFWarning=false"
    />

    <promo-uf-warning
      v-if="showPromoUFWarning"
      :dialog="showPromoUFWarning"
      :product-id="payload.pedido.produto[0].id"
      @close="showPromoUFWarning=false"
    />
  </div>
</template>

<style lang="stylus">
  .fix-form-input
    input
      margin-top: 12px !important

  .gtm_button
    div.v-btn__content
      height 0px !important
</style>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  import email from '@/components/forms/inputs/Email'
  import DefaultUfWarning from '@/components/dialogs/UFWarning'
  import PromoUfWarning from '@/components/dialogs/PromoUFWarning'
  import formPanelQuantidadeContrato from '@/components/forms/panel/QuantidadeContrato'

  /** @mixins */
  import pad from '@/plugins/mixins/pad'
  import mask from '@/plugins/mixins/mask'
  import rule from '@/plugins/mixins/rule'
  import prop from '@/plugins/mixins/prop'
  import product from '@/plugins/mixins/product'
  import find from '@/plugins/mixins/keyfind'
  import MixinsTvprePedidos from '@/plugins/mixins/pedidos'
  import MixinAPIConsultaValorFrete from '@/plugins/mixins/api/frete/ConsultaValorFrete'
  /** @mixins */

  export default {
    components: {
      email,
      DefaultUfWarning,
      PromoUfWarning,
      formPanelQuantidadeContrato
    },

    mixins: [
      pad,
      mask,
      rule,
      prop,
      product,
      find,
      MixinsTvprePedidos,
      MixinAPIConsultaValorFrete
    ],

    props: {
      get: {
        type: Object,
        default: undefined
      }
    },

    data: function () {
      return {
        input: undefined,
        showDefaultUFWarning: false,
        showPromoUFWarning: false,
        loading: {
          active: undefined,
          payload: false
        },

        formulario: {
          validate: {
            step: {
              contrato: false,
              dadosContato: false
            },

            cpf: 'false',
            telefone: {
              contato: 'false'
            },

            mask: {
              cpf: '###.###.###-##',
              tel: '(##) #####-####'
            }
          }
        },

        consumidor: {
          cpf: undefined,
          telefone: {
            contato: undefined
          }
        },

        pedido: {
          produto: {
            quantidade: 1
          },
          ciente: {
            instalacao: undefined,
            recarga: undefined
          },
          postagem: {
            servicoEntrega: undefined,
            valorEntrega: 0
          },
          valorTotal: undefined
        }
      }
    },

    computed: {
      ...mapState('Modules', [ 'formData' ]),
      ...mapState('Payload', [ 'payload' ]),
      ...mapGetters('Modules', [ 'RoutePromo' ])
    },

    watch: {
      'loading.active' () {
        const l = this.loading.active
        this.loading[l] = !this.loading[l]

        setTimeout(() => (this.loading[l] = false), 3000)

        this.loading.active = undefined
      },

      'consumidor.cpf' (value) {
        const pattern = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/i
        this.consumidor.cpf = this.consumidor.cpf.trim()

        if (pattern.test(value)) {
          let digitosIguais = 1
          for (let i = 0; i < value.length - 1; i++) {
            if (value.charAt(i) !== value.charAt(i + 1)) {
              digitosIguais = 0
              break
            }
          }

          if (!digitosIguais) {
            let Breack, Soma, Resto

            Soma = 0
            for (let i = 1; i <= 9; i++) { Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i) }

            Resto = (Soma * 10) % 11
            if (Resto === 10 || Resto === 11) Resto = 0
            if (Resto !== parseInt(value.substring(9, 10))) Breack = true

            Soma = 0
            for (let i = 1; i <= 10; i++) { Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i) }

            Resto = (Soma * 10) % 11
            if (Resto === 10 || Resto === 11) Resto = 0
            if (Resto !== parseInt(value.substring(10, 11))) Breack = true

            this.formulario.validate.cpf = Breack ? 'CPF inválido' : true

            if (!Breack) {
              this.SaveInput({
                input: true,
                name: 'consumidorCpf',
                value: this.consumidor.cpf
              })
            }
          } else {
            this.formulario.validate.cpf = 'CPF inválido'
          }
        } else {
          this.formulario.validate.cpf = 'CPF inválido'
        }
      },

      'consumidor.telefone.contato' (value) {
        const pattern = /^(0?\(?[0-9]{2}\)?[0-9]{5}-?[0-9]{4})$/i
        this.consumidor.telefone.contato = this.consumidor.telefone.contato.trim()

        if (pattern.test(value)) {
          let digitosIguais = 1
          for (let i = 3; i < value.length - 1; i++) {
            if (value.charAt(i) !== value.charAt(i + 1)) {
              digitosIguais = 0
              break
            }
          }

          if (!digitosIguais) {
            let Breack
            if (value.charAt(2) !== '9') Breack = true
            if (!require('@/database/validate/estados').array.map(String).includes(value.substring(0, 2))) Breack = true

            this.formulario.validate.telefone.contato = Breack
              ? 'Telefone inválido'
              : true

            if (!Breack) {
              this.SaveInput({
                input: true,
                name: 'consumidorTelefoneContato',
                value: this.consumidor.telefone.contato
              })
            }
          } else {
            this.formulario.validate.telefone.contato = 'Telefone inválido'
          }
        } else {
          this.formulario.validate.telefone.contato = 'Telefone inválido'
        }
      },

      'pedido.postagem.servicoEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemServicoEntrega',
          value: this.pedido.postagem.servicoEntrega
        })
      },

      'pedido.postagem.valorEntrega' () {
        this.SaveInput({
          input: true,
          name: 'pedidoPostagemValorEntrega',
          value: this.pedido.postagem.valorEntrega
        })
      },

      'pedido.valorTotal' () {
        this.SaveInput({
          input: true,
          name: 'pedidoValorTotal',
          value: this.pedido.valorTotal
        })
      },

      'pedido.produto.quantidade' () {
        this.verificaQuantidadeAdicionalPedido(this.pedido.produto.quantidade)
        this.getTotalValue(this.calculatedTotal()) // this.verificaPedidoComPontoAdicional()
        this.prepareVertexAPIConsultarValorFrete()
      },

      'pedido.ciente.instalacao' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteInstalacao',
          value: this.pedido.ciente.instalacao
        })
      },

      'pedido.ciente.recarga' () {
        this.SaveInput({
          input: true,
          name: 'pedidoCienteRecarga',
          value: this.pedido.ciente.recarga
        })
      }
    },
    created () {
      if (!this.$route.meta.promo && this.payload.pedido.produto[0].id === 'vtxantenareceptorhd') {
        setTimeout(() => { this.showDefaultUFWarning = true }, 500)
      } else if (this.$route.meta.promo && (['promokit', 'promoreceptorhdcanais', 'promokitcanais', 'promoreceptorhd'].indexOf(this.$route.meta.promo.name) !== -1)) {
        setTimeout(() => { this.showPromoUFWarning = true }, 500)
      }
    },
    mounted () {
      if (this.payload.consumidor.cpf) this.consumidor.cpf = this.payload.consumidor.cpf
      if (this.payload.consumidor.telefone.contato) this.consumidor.telefone.contato = this.payload.consumidor.telefone.contato
      if (this.payload.pedido.ciente.instalacao) this.pedido.ciente.instalacao = this.payload.pedido.ciente.instalacao
      if (this.payload.pedido.ciente.recarga) this.pedido.ciente.recarga = this.payload.pedido.ciente.recarga
      if (this.payload.pedido.postagem.servicoEntrega) this.pedido.postagem.servicoEntrega = this.payload.pedido.postagem.servicoEntrega
      if (this.payload.pedido.postagem.valorEntrega) this.pedido.postagem.valorEntrega = this.payload.pedido.postagem.valorEntrega
    },

    methods: {
      ...mapActions('Payload', [ 'SaveInput', 'UpdatePayload' ]),
      ...mapActions('Dialogs', [ 'openFaq' ]),

      getTotalValue (value) {
        if (value) this.pedido.valorTotal = value
        return this.pedido.valorTotal
      },

      getInput (field, value) {
        this.consumidor[field] = value
      },

      getName (param) {
        if (param.source === 'gtmid') return this.getValidateForm() ? this.get.gtm : 'disabled'
      },

      getValidateForm (revalid) {
        if (revalid) {
          this.GetProp('formulario_dados_contato').validate()
          return
        }

        if (!this.GetProp('formulario_dados_contato', 'value')) return false

        return true
      },

      dataLayer (param) {
        if (param === 'payload') {
          this.loading.active = 'payload'

          if (this.getValidateForm()) {
            this.$emit('actionButton', 'avancar')
            this.dataLayer()
          } else {
            this.getValidateForm(true)
          }
        }
        if (param && this.input !== param) {
          this.input = param

          window.dataLayer.push({
            'event': 'event',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'preencheu',
            'eventLabel': param
          })
        }

        if (!param) {
          window.dataLayer.push({
            'event': 'checkout',
            'eventCategory': 'claro:tv-pre',
            'eventAction': 'enhanced-ecommerce',
            'eventLabel': `checkout-${this.formData.openedStep.actual}-etapa-vamos-comecar`,
            'ecommerce': {
              'nonInteraction': '1',
              'ec': 'setAction',
              'checkout': {
                'actionField': {
                  'step': this.formData.openedStep.actual
                },
                'products': [{
                  'name': String(this.getProduct(this.payload.pedido.produto[0].id, 'title').nome).replace(/ /g, '').toLowerCase(),
                  'id': String(this.getProduct(this.payload.pedido.produto[0].id, 'plan')).replace(/ /g, ''),
                  'price': String(this.getProduct(this.payload.pedido.produto[0].id, 'price').default).replace(/,|\./g, '.'),
                  'brand': 'meu-plano-claro',
                  'category': 'tvpre',
                  'quantity': '1'
                }]
              }
            }
          })
        }
      }
    }
  }
</script>
