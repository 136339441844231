import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('Payload', ['ActionPayloadSalvaClearSaleSessionId']),

    startClearSalecreateScript () {
      if (document.getElementById('MeuCampoQueReceberaValor')) {
        let script = document.createElement('script')
        script.setAttribute('src', '/scripts/clearsale.js')
        document.body.appendChild(script)
      }
    },

    async startClearSalecreateInput () {
      if (document.getElementById('MeuCampoQueReceberaValor') === null) {
        let input = document.createElement('input')
        input.setAttribute('id', 'MeuCampoQueReceberaValor')
        input.setAttribute('type', 'hidden')
        document.body.appendChild(input)
      }

      this.startClearSalecreateScript()

      return new Promise((resolve) => {
        setTimeout(() => {
          this.ActionPayloadSalvaClearSaleSessionId(document.getElementById('MeuCampoQueReceberaValor').value)
          resolve(true)
        }, 1000)
      })
    },

    createScriptSessionIDClearSale () {
      return this.startClearSalecreateInput()
    }
  }
}
