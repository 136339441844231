<template>
  <div>
    <v-card
      color="white"
      tile
      flat
      style="font-family:var(--font-family-highlight)"
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          xs12
          py-4
        />

        <v-flex
          text-xs-center
          xs10
          md8
          lg4
        >
          <strong
            v-font-size="18"
            class="text-uppercase font-weight-bold"
            v-text="'Escolha seu equipamento'"
          />
        </v-flex>

        <v-flex
          xs12
          py-3
        />

        <v-flex
          xs10
          md8
        >
          <v-layout wrap>
            <v-flex
              xs12
              md6
            >
              <v-item-group
                v-model="cache.produtoAtivo"
                mandatory
              >
                <v-layout wrap>
                  <v-flex
                    v-for="ListaPlanos in todosPlanos"
                    :key="`plano-controle-facil-claro-${ListaPlanos.plan}`"
                    :class="`order-xs${ListaPlanos.index}`"
                    grow
                    md12
                  >
                    <v-layout
                      :wrap="$vuetify.breakpoint.mdAndUp"
                      class="cursor-default"
                    >
                      <v-flex
                        xs12
                        pb-3
                        :mr-2="$vuetify.breakpoint.smAndDown"
                      >
                        <v-item :value="ListaPlanos.plan">
                          <v-layout
                            v-ripple
                            slot-scope="{ active, toggle }"
                            v-height.min="130"
                            v-cursor="'pointer'"
                            align-center
                            justify-center
                            fill-height
                            :class="`py-3 ${!active && 'pl-2'}`"
                            :style="
                              `border-radius: 4px; border: 1px solid ${
                                active ? '#E3262E' : '#D5D5D5'
                              }; border-left: ${
                                active ? '8px solid #E3262E' : '1px solid #D5D5D5'
                              }; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75); background-color: white`
                            "

                            @click="toggle"
                          >
                            <v-flex
                              xs2
                              px-3
                            >
                              <v-radio-group
                                v-model="cache.produtoAtivo"
                                class="pa-0 ma-0"
                                hide-details
                                data-test-id="checkboxesprodutos"
                              >
                                <v-radio
                                  class="fix-input-selection-controls pa-0 ma-0"
                                  :value="ListaPlanos.plan"
                                  color="#E3262E"
                                  data-test-id="produto"
                                />
                              </v-radio-group>
                            </v-flex>

                            <v-flex
                              xs10
                              pr-2
                            >
                              <v-layout
                                align-center
                                wrap
                              >
                                <v-flex
                                  xs7
                                  pr-4
                                >
                                  <strong
                                    v-font-size="$vuetify.breakpoint.smAndDown ? 14 : 15"
                                    class="text-uppercase"
                                    v-text="
                                      getProduct(ListaPlanos.plan, 'title').short
                                    "
                                  />
                                </v-flex>

                                <v-flex xs5>
                                  <v-layout wrap>
                                    <v-flex xs12>
                                      <span
                                        v-font-size="12"
                                        v-text="'10x de:'"
                                      />
                                    </v-flex>

                                    <v-flex xs12>
                                      <strong
                                        v-font-size="20"
                                        v-text="
                                          `R$ ${currency({
                                            valor: getProduct(
                                              ListaPlanos.plan,
                                              'price'
                                            ).promo
                                              ? getProduct(
                                                ListaPlanos.plan,
                                                'price'
                                              ).default
                                              : getProduct(
                                                ListaPlanos.plan,
                                                'price'
                                              ).default,
                                            parcela: 10
                                          })}`
                                        "
                                      /><small v-text="'/mês'" />
                                    </v-flex>

                                    <v-flex xs12>
                                      <span
                                        v-font-size="12"
                                        v-text="
                                          `ou R$ ${currency({
                                            valor: getProduct(
                                              ListaPlanos.plan,
                                              'price'
                                            ).promo
                                              ? getProduct(
                                                ListaPlanos.plan,
                                                'price'
                                              ).default
                                              : getProduct(
                                                ListaPlanos.plan,
                                                'price'
                                              ).default
                                          })} à vista`
                                        "
                                      />
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex
                              v-if="ListaPlanos.best"
                              shrink
                            >
                              <v-img
                                :src="
                                  require('@/assets/images/icons/flag-melhor-oferta-receptor-hd-mais-antena.png')
                                "
                                width="64"
                                height="64"
                                :style="
                                  `position: absolute; right: -${
                                    $vuetify.breakpoint.smAndDown ? 24 : 32
                                  }px; transform: translateY(-${
                                    $vuetify.breakpoint.smAndDown ? 110 : 90
                                  }px)`
                                "
                              />
                            </v-flex>
                          </v-layout>
                        </v-item>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-item-group>
            </v-flex>

            <v-spacer class="hidden-sm-and-down" />

            <v-flex
              xs12
              md5
            >
              <v-layout wrap>
                <v-flex xs12>
                  <v-card
                    class="border-radius-6"
                    style="border: 1px solid #e9e9e9; box-shadow: 0px 14px 40px -34px rgba(0,0,0,0.75)"
                  >
                    <v-layout
                      justify-center
                      wrap
                      py-3
                    >
                      <v-flex
                        v-if="freeFeePricePromotion && !/antena/.test(cache.produtoAtivo)"
                        xs12
                      >
                        <v-card
                          color="#870000"
                          style="border-radius:0 86px 86px 0"
                          flat
                          width="156"
                          height="34"
                          dark
                        >
                          <v-layout
                            fill-height
                            align-center
                            justify-center
                            px-3
                          >
                            <v-flex
                              grow
                            >
                              <small
                                style="font-size:14px"
                                class="text-uppercase"
                                v-text="'Frete grátis'"
                              />
                            </v-flex>

                            <v-flex
                              shrink
                            >
                              <v-img
                                src="/img/sticker/sticker-fee.png"
                                width="30"
                                height="30"
                                contain
                                class="mx-auto"
                                alt="Frete grátis Receptores HD e SD"
                                title="Frete grátis Receptores HD e SD"
                              />
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>

                      <v-flex xs6>
                        <v-img
                          :src="getProduct(cache.produtoAtivo, 'cover')[0]"
                          :height="170"
                          :width="200"
                          contain
                          :class="`mx-auto`"
                        />
                      </v-flex>

                      <v-flex
                        xs11
                        text-xs-center
                      >
                        <strong
                          v-font-size="20"
                          v-text="getProduct(cache.produtoAtivo, 'title').short"
                        />
                      </v-flex>

                      <v-flex
                        xs6
                        py-3
                      >
                        <v-divider />
                      </v-flex>

                      <v-flex
                        text-xs-center
                        xs12
                      >
                        <span
                          v-font-size="14"
                          style="color: #777777"
                          v-text="'por apenas 10x sem juros de'"
                        />
                      </v-flex>

                      <v-flex
                        text-xs-center
                        xs12
                      >
                        <strong
                          v-font-size="30"
                          style="color: #D52B1E"
                          v-text="
                            `R$ ${currency({
                              valor: getProduct(cache.produtoAtivo, 'price').promo
                                ? getProduct(cache.produtoAtivo, 'price').default
                                : getProduct(cache.produtoAtivo, 'price').default,
                              parcela: 10
                            })}`
                          "
                        />
                      </v-flex>

                      <v-flex
                        text-xs-center
                        xs12
                      >
                        <span
                          v-font-size="14"
                          style="color: #777777"
                          v-text="
                            `ou R$ ${currency({
                              valor: getProduct(cache.produtoAtivo, 'price').promo
                                ? getProduct(cache.produtoAtivo, 'price').default
                                : getProduct(cache.produtoAtivo, 'price').default
                            })} à vista`
                          "
                        />
                      </v-flex>

                      <v-flex xs10>
                        <v-btn
                          color="#FFD21E"
                          round
                          large
                          block
                          depressed
                          data-test-id="comprar"
                          @click="openFormData(cache.produtoAtivo)"
                          v-text="'Comprar'"
                        />
                      </v-flex>
                      <v-flex xs10>
                        <v-layout
                          column
                          align-center
                        >
                          <p
                            v-font-size="12"
                            style="color: #777777"
                            class="mb-2"
                            v-text="'Forma de pagamento. Credito e Pix'"
                          />
                          <!-- v-text="'Forma de pagamento. Credito, Boleto e Pix'" -->
                          <v-img
                            :src="
                              require('@/assets/images/bandeiras/cartao-credito/pague-com-cartao-credito-boleto-e-pix.png')
                            "
                            width="250"
                            alt="pague com cartão de credito ou pix"
                            title="pague com cartão de credito ou pix"
                          />
                        <!-- alt="pague com cartão de credito, boteto ou pix"
                          title="pague com cartão de credito, boteto ou pix" -->
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>

                <v-flex xs12>
                  <span
                    v-for="text in getProduct(cache.produtoAtivo, 'subheading')"
                    :key="`promocao-claro-tv-pre-${text}`"
                    v-html="`${text}<br>`"
                  />
                  <span
                    v-if="showUfsMessage()"
                    v-text="getUFArray(getProduct(cache.produtoAtivo, 'promo'))"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-btn
        style="bottom:80px;right:24px;"
        class="hidden-md-and-down"
        absolute
        fixed
        fab
        bottom
        right
        color="#FFD21E"
        depressed
        @click="openFormData(cache.produtoAtivo)"
      >
        <v-icon>mdn-Icon-carrinho</v-icon>
      </v-btn>
    </v-card>

    <v-dialog
      ref="freeFeePricePromotionDialog"
      :value="freeFeePricePromotion"
      max-width="384"
    >
      <v-card
        max-width="384"
        height="450"
        style="border-radius:10px"
        data-test-id="cardFreteGratis"
      >
        <v-layout
          fill-height
          align-center
          justify-center
          wrap
          pb-4
        >
          <v-spacer />

          <v-flex
            shrink
          >
            <v-btn
              small
              icon
              @click="$refs.freeFeePricePromotionDialog.save()"
            >
              <v-icon
                color="#C4C4C4"
                small
                v-text="'close'"
              />
            </v-btn>
          </v-flex>

          <v-flex
            xs12
          >
            <v-img
              src="/img/sticker/sticker-fee.png"
              width="140"
              height="140"
              contain
              class="mx-auto"
              alt="Frete grátis Receptores HD e SD"
              title="Frete grátis Receptores HD e SD"
            />
          </v-flex>

          <v-flex
            xs12
          >
            <v-layout
              fill-height
              align-center
              justify-center
              wrap
            >
              <v-flex
                xs11
                text-xs-center
              >
                <strong
                  class="text-uppercase"
                  style="font-size:22px"
                  v-text="'Frete grátis Receptores HD'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs10
                text-xs-center
              >
                <small
                  style="font-size:16px"
                  v-text="'Para todo o Brasil.'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />

              <v-flex
                xs8
              >
                <v-btn
                  color="#F3A70C"
                  round
                  depressed
                  block
                  large
                  data-test-id="bntAcceptFreteGratis"
                  @click="$refs.freeFeePricePromotionDialog.save()"
                  v-text="'Aproveite agora'"
                />
              </v-flex>

              <v-flex
                xs12
                py-2
              />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import GlobalMixinServicesAPI from '@/plugins/mixins/apiServices'

  /** @Mixins */
  import mask from '@/plugins/mixins/mask'
  import product from '@/plugins/mixins/product'
  /** @Mixins */

  export default {
    mixins: [
      mask,
      product,
      GlobalMixinServicesAPI
    ],

    data: function () {
      return {
        cache: {
          produtoAtivo: 'vtxreceptorhd'
        },
        todosPlanos: {}
      }
    },

    computed: {
      freeFeePricePromotion: {
        get () {
          let userDataRegion = this.getCacheRastreamentoUsuario()
          if (userDataRegion && userDataRegion.region_code) {
            const { REGIAO_UF_ENTREGA_GRATUITA } = require('@/data/feeRegion/feeRegionData.json')
            return REGIAO_UF_ENTREGA_GRATUITA.includes(userDataRegion.region_code)
          }
          return true
        }
      }
    },

    mounted () {
      this.montaProdutosCard()
    },

    methods: {
      ...mapActions('Payload', ['OpenOferta']),

      verificaPromoAtiva () {
        if (this.$route.meta && this.$route.meta.promo) {
          if (this.$route.meta.promo.product) {
            this.cache.produtoAtivo = this.$route.meta.promo.product
            return this.$route.meta.promo.name
          }
        }
      },

      montaProdutosCard () {
        const produtos = this.getProduct()

        produtos.forEach(element => {
          if (!/antena|doisanos/.test(element)) {
            this.todosPlanos = {
              ...this.todosPlanos,
              [element]: this.getProduct(element)
            }

            if (this.getProduct(element).index) {
              if (/1/.test(this.getProduct(element).index)) { this.cache.produtoAtivo = element }
            } else {
              this.cache.produtoAtivo = element
            }
          }
        })

        if (this.$route && (this.$route.params && this.$route.params.produto)) {
          Object.keys(this.todosPlanos).forEach(element => {
            if (this.$route.params.produto === element) {
              this.cache.produtoAtivo = this.$route.params.produto
              this.openFormData(element)
            }
          })
        }
      },

      showUfsMessage () {
        if (this.$route.meta.promo) return
        return this.getProduct(this.cache.produtoAtivo, 'promo')
      },

      openFormData (id) {
        window.log('src/views/cards/index.vue')
        window.log('dados recebidos no openFormData', this.getProduct(id))
        let productSKU = this.getProduct(id, 'sku')
        if (this.$route.meta.promo) {
          if (this.$route.meta.promo.sku) productSKU = this.$route.meta.promo.sku
        }

        this.OpenOferta(
          new Array({
            id: this.getProduct(id, 'plan'),
            modelo: this.getProduct(id, 'title').short,
            preco: !this.$route.meta.promo
              ? id !== 'vtxantenareceptorhd'
                ? String(this.getProduct(id, 'price').default)
                : String(this.getProduct(id, 'price').default)
              : String(this.getProduct(id, 'price').default),
            descricao: this.getProduct(id, 'title').nome,
            sku: productSKU,
            quantidade: 1
          })
        )

        this.dataLayer(id)
      },

      dataLayer (id) {
        window.dataLayer.push({
          event: 'addToCart',
          eventCategory: 'claro:tv-pre',
          eventAction: 'enhanced-ecommerce',
          eventLabel: `card:contratar:${id}`,
          ecommerce: {
            ec: 'setAction',
            add: {
              products: [
                {
                  name: String(this.getProduct(id, 'title').nome)
                    .replace(/ /g, '')
                    .toLowerCase(),
                  id: String(this.getProduct(id, 'plan')).replace(/ /g, ''),
                  price: String(this.getProduct(id, 'price').default).replace(
                    /,|\./g,
                    '.'
                  ),
                  brand: 'meu-plano-claro',
                  category: 'tvpre',
                  quantity: '1'
                }
              ]
            }
          }
        })
      }
    }
  }
</script>
