<template>
  <main>
    <slot
      v-if="!!$scopedSlots.tabs"
      name="tabs"
    >
      <v-layout>
        <v-flex grow>
          <v-tabs
            :value="tabValuePosition"
            color="transparent"
            center-active
            slider-color="red lighten-3"
          >
            <v-tab
              class="text-none"
              @click="openClaroProduct('/')"
            >
              Planos Controle
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroProduct('/flex')"
            >
              Flex
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroProduct('/prezao')"
            >
              Prezão
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroProduct('/esim')"
            >
              E-chip
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroTVPreProduct()"
            >
              TV Pré-pago
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroResidencialProduct('tv-box')"
            >
              Claro box TV
            </v-tab>

            <v-tab
              class="text-none"
              @click="openClaroResidencialProduct()"
            >
              Claro Residencial
            </v-tab>
          </v-tabs>
        </v-flex>
      </v-layout>
    </slot>

    <slot
      v-if="!!$scopedSlots.list"
      name="list"
    >
      <v-layout>
        <v-flex grow>
          <v-list
            two-line
          >
            <v-list-tile
              ripple
              @click="openClaroProduct('/')"
            >
              <v-list-tile-content>
                Planos Controle
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroProduct('/flex')"
            >
              <v-list-tile-content>
                Flex
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroProduct('/prezao')"
            >
              <v-list-tile-content>
                Prezão
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroProduct('/esim')"
            >
              <v-list-tile-content>
                E-chip
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroTVPreProduct()"
            >
              <v-list-tile-content>
                TV Pré-pago
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroResidencialProduct('tv-box')"
            >
              <v-list-tile-content> Claro box TV </v-list-tile-content>
            </v-list-tile>

            <v-list-tile
              ripple
              @click="openClaroResidencialProduct()"
            >
              <v-list-tile-content>
                Claro Residencial
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-flex>
      </v-layout>
    </slot>
  </main>
</template>

<style lang="stylus">
  .fix-tabs-mobile
    .v-tab
      justify-content: left
</style>

<script>
  export default {
    props: {
      tabValuePosition: {
        default: 4,
        type: Number
      }
    },

    methods: {
      openClaroProduct (path) {
        switch (true) {
          case /beta/.test(location.origin):
            location.replace(`//beta.meuplanoclaro.com.br${path}${location.search}`)
            break
          case /homologacao/.test(location.origin):
            location.replace(`//homologacao.meuplanoclaro.com.br${path}${location.search}`)
            break
          case /http:/.test(location.protocol):
            location.replace(`${location.origin}${path}${location.search}`)
            break
          default:
            location.replace(`//meuplanoclaro.com.br${path}${location.search}`)
        }
      },

      openClaroTVPreProduct () {
        switch (true) {
          case /beta/.test(location.origin):
            location.replace(`//beta.tvpre.meuplanoclaro.com.br${location.search}`)
            break
          case /homologacao/.test(location.origin):
            location.replace(`//homologacao.tvpre.meuplanoclaro.com.br${location.search}`)
            break
          case /http:/.test(location.protocol):
            location.replace(`${location.origin}${location.search}`)
            break
          default:
            location.replace(`//tvpre.meuplanoclaro.com.br${location.search}`)
        }
      },

      openClaroResidencialProduct (canal) {
        let CanalResidencial = ''
        switch (true) {
          case /tv-box/.test(canal):
            CanalResidencial = '/tv-por-assinatura/claro-box-tv'
            break
        }

        switch (true) {
          case /beta/.test(location.origin):
            location.replace(`//beta.residencial.meuplanoclaro.com.br${CanalResidencial}${location.search}`)
            break
          case /homologacao/.test(location.origin):
            location.replace(`//homologacao.residencial.meuplanoclaro.com.br${CanalResidencial}${location.search}`)
            break
          case /http:/.test(location.protocol):
            location.replace(`${location.origin}${CanalResidencial}${location.search}`)
            break
          default:
            location.replace(`//residencial.meuplanoclaro.com.br${CanalResidencial}${location.search}`)
        }
      }
    }
  }
</script>
