module.exports = {
  checkout: {
    api: 'upnid',
    url: {
      upnid: {
        base: 'https://upnid.com/go/',
        query: '%U?p=%P&src=%SRC'
      }
    }
  }
}
