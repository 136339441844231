<template>
  <v-dialog
    v-model="dialogs.loading"
    content-class="round width-96 height-96"
    persistent
  >
    <v-card
      class="width-96 height-96 pa-4"
      light
    >
      <v-img
        :src="images.brand.loading"
        contain
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('Modules', [ 'dialogs', 'images' ])
    }
  }
</script>
