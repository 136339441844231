/** @Database */
import {
  bancos
} from '@/database/pagamento/index'

import * as estados from '@/database/validate/estados'
/** @Database */

export default {
  methods: {
    listaBancos () {
      return bancos.map(o => o.banco)
    },

    getBeneficio (param) {
      switch (this.$route.meta.brand) {
        case 'lebes':
        case 'pernambucanas':
        case 'novomundo':
          if (param === 'info') return '50%'
          if (param === 'value') return 'R$ 25'
          break
        default:
          if (param === 'info') return '20%'
          if (param === 'value') return 'R$ 10'
          break
      }
    },

    listaEstados (type) {
      return estados[type]
    }
  }
}
