<template>
  <v-footer
    color="#D52B1E"
    :height="$vuetify.breakpoint.mdAndDown ? 730 : 400"
    style="z-index:9;position:relative"
  >
    <v-layout
      wrap
      justify-center
      align-content-space-between
      fill-height
    >
      <v-flex
        class="px-3"
        xl6
        md8
        xs12
      >
        <v-card
          color="transparent"
          :height="$vuetify.breakpoint.mdAndDown ? 'calc(547px - 57px)' : 'calc(361px - 38px)'"
          width="100%"
          max-width="1024"
          class="mx-auto"
          dark
          flat
        >
          <v-layout
            fill-height
            justify-space-around
            wrap
            py-2
          >
            <v-flex
              xs12
            >
              <v-layout
                wrap
                fill-height
                align-content-space-around
              >
                <v-flex
                  xs6
                >
                  <v-img
                    :src="require(`@/assets/images/logos/logo-claro-branco.png?lazy`)"
                    width="87"
                    height="31"
                    contain
                  />
                </v-flex>

                <v-flex
                  xs6
                >
                  <v-layout
                    justify-end
                  >
                    <v-flex
                      shrink
                      px-1
                    >
                      <a
                        data-gtm-event-category="meu-plano-claro:home"
                        data-gtm-event-action="clique:link"
                        data-gtm-event-label="facebook-claro-brasil"
                        href="https://www.facebook.com/clarobrasil"
                        target="_blank"
                        rel="noopener"
                      >
                        <v-img
                          :src="require('@/assets/images/flex/footer/claro-icon-social-facebook.png?lazy')"
                          height="28"
                          width="28"
                          contain
                        />
                      </a>
                    </v-flex>

                    <v-flex
                      shrink
                      px-1
                    >
                      <a
                        data-gtm-event-category="meu-plano-claro:home"
                        data-gtm-event-action="clique:link"
                        data-gtm-event-label="instagram-claro-brasil"
                        href="https://www.instagram.com/clarobrasil/"
                        target="_blank"
                        rel="noopener"
                      >
                        <v-img
                          :src="require('@/assets/images/flex/footer/claro-icon-social-instagram.png?lazy')"
                          height="28"
                          width="28"
                          contain
                        />
                      </a>
                    </v-flex>

                    <v-flex
                      shrink
                      px-1
                    >
                      <a
                        data-gtm-event-category="meu-plano-claro:home"
                        data-gtm-event-action="clique:link"
                        data-gtm-event-label="linkedin-claro-brasil"
                        href="https://www.linkedin.com/company/clarobrasil/"
                        target="_blank"
                        rel="noopener"
                      >
                        <v-img
                          :src="require('@/assets/images/flex/footer/claro-icon-social-linkedin.png?lazy')"
                          height="28"
                          width="28"
                          contain
                        />
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex
              xs12
              py-3
            >
              <v-divider
                class="white"
              />
            </v-flex>

            <v-flex
              xs12
            >
              <v-layout
                wrap
                fill-height
                align-content-space-around
                justify-end
              >
                <v-flex
                  xs12
                  md4
                >
                  <v-layout
                    wrap
                    align-center
                  >
                    <v-flex
                      xs12
                    >
                      <span
                        class="d-block"
                        v-text="'Fale com nosso atendimento:'"
                      />

                      <a
                        rel="noopener"
                        type="email"
                        alt="Fale com nosso atendimento no email atendimento@meuplanoclaro.com.br"
                        title="Fale com nosso atendimento no email atendimento@meuplanoclaro.com.br"
                        class="d-block font-weight-bold white--text"
                        href="mailto:atendimento@meuplanoclaro.com.br"
                        v-text="'atendimento@meuplanoclaro.com.br'"
                      />
                    </v-flex>

                    <v-flex
                      xs12
                      py-2
                    />

                    <v-flex
                      xs12
                    >
                      <v-layout
                        v-for="({text, link}) in require('@/data/politicas-vertex/data.json')"
                        :key="`politicas-vertex-${text}`"
                        wrap
                      >
                        <v-flex
                          xs12
                        >
                          <a
                            target="_blank"
                            rel="noopener"
                            type="pdf"
                            class="d-block white--text text-decoration-underline"
                            :href="link"
                            v-text="text"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  hidden-md-and-up
                  xs12
                  py-2
                />

                <v-flex
                  xs12
                  md4
                >
                  <v-layout
                    wrap
                  >
                    <v-flex
                      xs12
                    >
                      <span
                        style="position:relative;top:6px;right:4px"
                        class="mdn-Icon-telefone-fixo mdn-Icon--md"
                      />

                      <span
                        v-text="'Fale com nosso SAC '"
                      />

                      <a
                        rel="noopener"
                        type="email"
                        alt="Fale com nosso SAC no telefone 106 99"
                        title="Fale com nosso SAC no telefone 106 99"
                        class="font-weight-bold white--text"
                        href="tel:10699"
                        v-text="'106 99'"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex
                  hidden-md-and-up
                  xs12
                  py-2
                />

                <v-flex
                  xs12
                  md4
                  :class="$vuetify.breakpoint.mdAndUp && 'd-flex'"
                >
                  <v-layout
                    wrap
                    class="py-2 mx-2"
                  >
                    <v-flex
                      v-if="epochSeloPromoRa"
                      xs12
                    >
                      <v-card
                        color="transparent"
                        style="border-radius:4px"
                        flat
                        width="150"
                        height="70"
                      >
                        <a
                          href="https://www.reclameaqui.com.br/empresa/vertex/premio/"
                          target="_blank"
                          rel="noopener"
                        >
                          <img
                            src="/img/selos/selo-ra.png"
                            alt="Selo reclame aqui"
                            width="180"
                            height="60"
                          >
                        </a>
                      </v-card>
                    </v-flex>

                    <v-flex
                      :xs="$vuetify.breakpoint.smAndDown ? '12' : '6'"
                      class="py-2"
                    >
                      <div
                        id="reputation-ra"
                      />
                    </v-flex>

                    <v-flex
                      xs6
                    >
                      <img
                        src="/img/selos/image-pagame.png"
                        alt="Selo Paga Me"
                        width="150"
                        height="90"
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout
                    wrap
                    :justify-end="$vuetify.breakpoint.mdAndUp"
                    :justify-space-between="$vuetify.breakpoint.smAndDown"
                    align-center
                  >
                    <v-flex
                      shrink
                    >
                      <v-card
                        color="white"
                        style="border-radius:4px"
                        flat
                        height="46"
                        width="65"
                      >
                        <a
                          href="https://aws.amazon.com/what-is-cloud-computing"
                          target="_blank"
                          rel="noopener"
                        >
                          <v-img
                            :src="require('@/assets/images/selos/aws-powered-by-light.png?lazy')"
                            alt="Powered by AWS Cloud Computing"
                            height="36"
                            style="transform:translateY(5px)"
                            contain
                          />
                        </a>
                      </v-card>
                    </v-flex>

                    <v-flex
                      xs12
                      hidden-sm-and-down
                      py-1
                    />

                    <v-flex
                      shrink
                    >
                      <v-card
                        color="white"
                        style="border-radius:4px"
                        flat
                        height="46"
                        width="105"
                      >
                        <a
                          href="https://transparencyreport.google.com/safe-browsing/search?url=tvpre.meuplanoclaro.com.br"
                          target="_blank"
                          rel="noopener"
                        >
                          <v-img
                            :src="require('@/assets/images/selos/google-site-seguro.png?lazy')"
                            alt="Seu pedido com Segurança"
                            title="Site Seguro"
                            height="36"
                            style="transform:translateY(5px)"
                            contain
                          />
                        </a>
                      </v-card>
                    </v-flex>

                    <v-flex
                      xs12
                      hidden-sm-and-down
                      py-1
                    />

                    <v-flex
                      shrink
                    >
                      <v-card
                        color="white"
                        style="border-radius:4px"
                        flat
                        height="46"
                        width="85"
                      >
                        <v-img
                          :src="require('@/assets/images/information/ssl-site-seguro.png?lazy')"
                          alt="Este site utiliza SSL para sua segurança"
                          height="36"
                          style="transform:translateY(5px)"
                          contain
                        />
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex
        xs12
      >
        <v-card
          color="#C51D10"
          :height="$vuetify.breakpoint.mdAndDown ? 57 : 38"
          width="100%"
          dark
          flat
        >
          <v-layout
            wrap
            justify-center
            fill-height
            align-content-center
          >
            <v-flex
              xs10
              md12
              text-xs-center
            >
              <span
                style="font-size:11px"
                v-text="`© ${new Date().getFullYear()} Vertice Brasil Consultoria Ltda. CNPJ: 26.930.573/0001-08. Todos os direitos reservados.`"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
  import { mixins } from 'vue-class-component'
  import { Component } from 'vue-property-decorator'
  import { epochSeloPromoRa } from '@/helpers/epoch'

  @Component({})
  export default class FooterComponentConcept extends mixins() {
    epochSeloPromoRa = epochSeloPromoRa()
  }
</script>
