import VertexAPI from '@/plugins/axios/api'
import debounce from 'lodash/debounce'
const LOG = (...v) => window.log(...v, 'app')
export default {
  data () {
    return {
      LOG: LOG,
      ENV: (v) => window.env(v),
      multiplosServicosDeFrete: [],
      prazoEntregaFrete: 8,
      prazoEntregaFreteObservacao: '',
      tentativasServicosDeFrete: 0,
      errorServicosDeFrete: false,
      successServicoDeFreteGratuito: false
    }
  },

  methods: {
    prepareVertexAPIConsultarValorFrete () {
      this.loading.payload = true
      this.pedido.postagem.valorEntrega = 0
      this.PreDebouncedVertexAPIConsultarValorFrete()
    },

    PreDebouncedVertexAPIConsultarValorFrete: debounce(
      function () {
        LOG('debounced função PreDebouncedVertexAPIConsultarValorFrete')
        if (!this.payload.consumidor.endereco.cidade) {
          this.loading.payload = false
          return
        }
        this.VertexAPIConsultarValorFrete()
        this.fakeFormValorEntrega = true
      }, 1000, { trailing: true }
    ),

    ServicosFreteDisponiveis: debounce(
      function () {
        const {
          REGIAO_UF_ENTREGA_PAC,
          REGIAO_METROPOLITANA
        } = require('@/data/feeRegion/feeRegionData.json')

        LOG('verificando serviços disponíveis para', this.payload.consumidor.endereco.cidade)
        if (!this.payload.consumidor.endereco.cidade) {
          this.pedido.postagem.servicoEntrega = undefined
          return
        }

        if (new RegExp(`(${REGIAO_UF_ENTREGA_PAC.join('|')})`, 'i').test(this.payload.consumidor.endereco.uf)) {
          this.multiplosServicosDeFrete = ['PAC']
        } else if (new RegExp(`(${REGIAO_METROPOLITANA.join('|')})`, 'i').test(this.payload.consumidor.endereco.cidade)) {
          this.multiplosServicosDeFrete = ['SEDEX']
        } else {
          this.multiplosServicosDeFrete = ['PAC', 'SEDEX']
        }
        if (this.multiplosServicosDeFrete.length <= 1) {
          LOG('só tem 1 servico disponível, vou verificar o Frete com ', this.pedido.postagem.servicoEntrega)
          this.pedido.postagem.servicoEntrega = this.multiplosServicosDeFrete[0]
        } else {
          LOG('temos mais de 1 servico disponível, pergunte qual deseja utilizar ', this.multiplosServicosDeFrete)
          this.pedido.postagem.servicoEntrega = undefined
        }
        this.prepareVertexAPIConsultarValorFrete()
      }, 190, { trailing: true }
    ),

    async VertexAPIConsultarValorFrete () {
      LOG('dados recebidos no payload', this.payload)
      LOG('dados para enviar a API', {
        _id: this.payload._id,
        servico: this.pedido.postagem.servicoEntrega,
        cep: this.payload.consumidor.endereco.cep,
        cidade: this.payload.consumidor.endereco.cidade,
        uf: this.payload.consumidor.endereco.uf,
        sku: this.payload.pedido.produto[0].sku,
        quantidade: this.payload.pedido.produto[0].quantidade
      })

      let filled = true;
      [
        this.payload._id,
        this.pedido.postagem.servicoEntrega,
        this.payload.consumidor.endereco.cep,
        this.payload.consumidor.endereco.cidade,
        this.payload.pedido.produto[0].sku,
        this.payload.pedido.produto[0].quantidade
      ].forEach(test => {
        LOG('teste de filled', test)
        if (!test) filled = !!test
      })
      LOG('teste final de filled', filled)
      if (!filled) {
        this.loading.payload = false
        return
      }
      LOG('irei verificar o Frete')
      return new Promise(() => {
        VertexAPI.post('product/shipping/fee', {
          _id: this.payload._id,
          servico: this.pedido.postagem.servicoEntrega,
          cep: this.payload.consumidor.endereco.cep,
          cidade: this.payload.consumidor.endereco.cidade,
          uf: this.payload.consumidor.endereco.uf,
          sku: this.payload.pedido.produto[0].sku,
          quantidade: this.payload.pedido.produto[0].quantidade
        }).then(response => {
          if (!response && !response.data) throw Error('Sem resposta da API')
          LOG('response on VertexAPIConsultarValorFrete', response.data)
          if (!('valor' in response.data)) throw Error('Sem valor na resposta da API', response.data)
          response.data.valor = String(response.data.valor || 0)
          this.pedido.postagem.valorEntrega = parseFloat(response.data.valor)
          this.prazoEntregaFrete = response.data.prazo || this.prazoEntregaFrete
          this.prazoEntregaFreteObservacao = response.data.observacao
          LOG('Valor do frete', this.pedido.postagem.valorEntrega)
          if (this.pedido.postagem.valorEntrega === 0) {
            this.successServicoDeFreteGratuito = true
          }
        }).catch(error => {
          LOG('error on - VertexAPIConsultarValorFrete', error)

          const response = {
            data: {
              'valor': 0
            }
          }

          if (!response && !response.data) throw Error('Sem resposta da API')
          LOG('response on VertexAPIConsultarValorFrete', response.data)
          if (!('valor' in response.data)) throw Error('Sem valor na resposta da API', response.data)
          response.data.valor = String(response.data.valor || 0)
          this.pedido.postagem.valorEntrega = parseFloat(response.data.valor)
          this.prazoEntregaFrete = response.data.prazo || this.prazoEntregaFrete
          this.prazoEntregaFreteObservacao = response.data.observacao
          LOG('Valor do frete', this.pedido.postagem.valorEntrega)
          if (this.pedido.postagem.valorEntrega === 0) {
            this.successServicoDeFreteGratuito = true
          }

          /*
            if (this.tentativasServicosDeFrete >= this.ENV('production') ? 3 : 0) {
              this.tentativasServicosDeFrete = 0
              this.errorServicosDeFrete = true
              return
            }
            this.tentativasServicosDeFrete++
            setTimeout(() => {
              this.PreDebouncedVertexAPIConsultarValorFrete()
            }, 960)
          */
        }).finally(() => {
          this.loading.payload = false
          VertexAPI.post('save', this.payload)
        })
      })
    }
  }
}
