<template>
  <div />
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    mounted () {
      this.Campaign({
        origem: this.$route.meta.utm_source,
        segmento: this.$route.meta.segmento,
        parametros: { ...this.$route.query, hjid: window.localStorage._hjid }
      })
    },

    methods: {
      ...mapActions('Payload', [ 'Campaign' ])
    }
  }
</script>
